import type { Location } from 'react-router-dom';


const getIsOnPageToIgnore = (
  pathRegExpsToDisableCheckOn: RegExp[],
  currentPathname: string,
): boolean => pathRegExpsToDisableCheckOn.some(r => r.test(currentPathname));

export const checkIfShouldRedirect = (
  getIsOk: () => boolean,
  currentPathname: Location['pathname'],
  pathRegExpsToDisableCheckOn: RegExp[],
): boolean => {
  const isOnPageToIgnore = getIsOnPageToIgnore(pathRegExpsToDisableCheckOn, currentPathname);
  if (isOnPageToIgnore) {
    return false;
  }

  const isOk = getIsOk();

  return !isOk;
};
