import React, { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import Button from 'app/components/Button';

import { NotificationUserMentioned } from '../../types';

import { RateSessionNotificationSessionObject } from './types';

import './style.scss';


export const getTitleBlock = (
  userMentioned: NotificationUserMentioned,
  t: TFunction,
): ReactElement | null => {
  return (
    <Trans components={[<span key="0" />]}>
      {t('notifications.title.rate_session_suggestion', {
        name: `${userMentioned.firstName} ${userMentioned.lastName}`,
      })}
    </Trans>
  );
};

export const getContentBlock = (
  session: RateSessionNotificationSessionObject,
  t: TFunction,
): ReactElement | null => {
  return (
    <div className="rate-button-container">
      <Button
        type="link"
        btnLook="filled"
        size="small"
        to={`/advisors/session/${session.sessionId}`}
        value={t('notifications.rate_session_suggestion_button')}
        className="rate_button"
      />
    </div>
  );
};
