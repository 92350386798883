export enum RoleType {
  SBO = 'SBO', // Small Business Owner
  ADVISOR = 'ADVISOR', // Advisory Organization Consultant
  ADVISORY_ORG_ADMIN = 'ADVISORY_ORG_ADMIN', // Advisory Organization Admin
  TENANT_ADMIN = 'TENANT_ADMIN', // Tenant Admin
  SUPER_ADMIN = 'SUPER_ADMIN', // NextStreet main admin
}
export interface ErrorsNewUser {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  password?: boolean;
  passwordConfirmation?: boolean;
  privacy?: boolean;
}
export interface RegistrationUserData {
  firstName: string | null;
  lastName: string | null;
  email: string;
  password: string;
  passwordConfirmation: string;
  privacy: boolean;
}
export interface PatchUserType {
  firstName?: string | null;
  lastName?: string | null;
  email?: string;
  password?: string;
  isAdvisor?: boolean;
  hasSkippedMFASetup?: boolean;
}
