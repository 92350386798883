import React, { memo } from 'react';


interface WorldIconProps {
  className?: string;
}
const WorldIcon: React.FC<WorldIconProps> = ({ className }) => {
  return (
    <svg className={className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1008 9.12648C17.2138 7.69146 16.942 6.25227 16.3133 4.95732C15.2728 5.94598 14.0428 6.71363 12.6975 7.21398C12.8057 8.54176 12.7457 9.87788 12.5192 11.1907C14.1562 10.7784 15.7074 10.0795 17.1008 9.12648ZM11.195 11.4582C11.4612 10.1857 11.5542 8.88305 11.4717 7.58565C10.6783 7.77482 9.85083 7.87482 8.99999 7.87482C8.14916 7.87482 7.32166 7.77482 6.52833 7.58565C6.4477 8.88305 6.54074 10.1854 6.80499 11.4582C8.25983 11.6811 9.74016 11.6811 11.195 11.4582ZM7.14333 12.7657C8.3768 12.9116 9.62318 12.9116 10.8567 12.7657C10.4327 14.1303 9.80691 15.4238 8.99999 16.6032C8.19306 15.4238 7.56723 14.1303 7.14333 12.7657ZM5.48083 11.1915C5.25303 9.87829 5.1931 8.54146 5.30249 7.21315C3.9569 6.71292 2.72659 5.94526 1.68583 4.95648C1.05734 6.25176 0.78578 7.69126 0.89916 9.12648C2.29261 10.0795 3.84376 10.7784 5.48083 11.1907V11.1915ZM16.7967 10.7932C16.3759 12.2189 15.5726 13.5022 14.4741 14.5037C13.3755 15.5052 12.0235 16.1866 10.565 16.474C11.2954 15.2459 11.8529 13.9228 12.2217 12.5423C13.8296 12.2053 15.3738 11.6152 16.7967 10.794V10.7932ZM1.20333 10.7932C2.60499 11.6023 4.14499 12.1998 5.77833 12.5423C6.14708 13.9228 6.70456 15.2459 7.43499 16.474C5.97657 16.1867 4.6247 15.5054 3.52611 14.5041C2.42753 13.5027 1.6242 12.2196 1.20333 10.794V10.7932ZM10.565 0.524818C12.6298 0.930658 14.4567 2.12134 15.6617 3.84648C14.7722 4.74513 13.7104 5.45488 12.54 5.93315C12.2246 4.023 11.5548 2.18868 10.565 0.524818ZM8.99999 0.396484C10.2162 2.17323 11.0152 4.20179 11.3375 6.33065C10.59 6.52232 9.80666 6.62482 8.99999 6.62482C8.19333 6.62482 7.40999 6.52315 6.66249 6.33065C6.98473 4.20178 7.78379 2.17322 8.99999 0.396484ZM7.43499 0.524818C6.44518 2.18867 5.77533 4.02299 5.45999 5.93315C4.28954 5.4549 3.22775 4.74515 2.33833 3.84648C3.54336 2.12159 5.37032 0.931202 7.43499 0.525651V0.524818Z"
        fill="#676767"
      />
    </svg>
  );
};

export default memo(WorldIcon);
