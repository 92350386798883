import { User } from '@firebase/auth';
import { Dispatch, SetStateAction } from 'react';


const defaultFirebaseUserReloadInterval = parseInt(
  process.env.REACT_APP_FIREBASE_USER_RELOAD_INTERVAL_MS || '30000',
  10,
);

export type FirebaseAuthContextDataWithoutReloadInterval = {
  currentUser: User | null;
  accessToken: string | null;
  isFirebaseInitializing: boolean;
}

export type FirebaseReloadIntervalData = {
  firebaseUserReloadInterval: number;
  setFirebaseUserReloadInterval: Dispatch<SetStateAction<number>>;
}

export type FirebaseAuthContextData = (
  FirebaseAuthContextDataWithoutReloadInterval
  & FirebaseReloadIntervalData
);

export const defaultFirebaseAuthContextDataWithoutReloadInterval
  : FirebaseAuthContextDataWithoutReloadInterval = {
    currentUser: null,
    accessToken: null,
    isFirebaseInitializing: true,
  };

export const defaultFirebaseReloadIntervalData: FirebaseReloadIntervalData = {
  firebaseUserReloadInterval: defaultFirebaseUserReloadInterval,
  setFirebaseUserReloadInterval: () => {},
};

export const defaultFirebaseAuthContextData: FirebaseAuthContextData = {
  ...defaultFirebaseAuthContextDataWithoutReloadInterval,
  ...defaultFirebaseReloadIntervalData,
};
