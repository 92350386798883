import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../Logo/Logo';
import HomePageTabs from '../../HomePageTabs/HomePageTabs';

import GeneralHeaderProfilePart from './GeneralHeaderProfilePart';


const GeneralHeader: React.FC = () => {
  const navigate = useNavigate();
  const onLogoClick = useCallback(() => navigate('/'), [navigate]);

  return (
    <div className="header_wrapper">
      <Logo className="logo" onClick={onLogoClick} />

      <HomePageTabs />

      <GeneralHeaderProfilePart />
    </div>
  );
};

export default memo(GeneralHeader);
