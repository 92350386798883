import { createAsyncThunk } from '@reduxjs/toolkit';

import advisorService from '_services/advisorService';


interface FetchAdvisorProps {
  id: number;
  timeZone: string;
  callBack?: Function;
}

export const fetchAdvisorDetails = createAsyncThunk('advisor/fetchAdvisorDetails', async (
  {
    id,
    timeZone,
    callBack,
  }: FetchAdvisorProps,
  { rejectWithValue }
) => {
  try {
    const response = await advisorService.getAdvisor(id, timeZone);

    if (callBack) {
      callBack(response);
    }

    return response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const fetchGetAdvisoryOrganizationsList = createAsyncThunk(
  'user/fetchGetAdvisoryOrganizationsList',
  async (_, { rejectWithValue }) => {
    try {
      return await advisorService.getOrgNameList();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
export const fetchAdvisorFilterSettings = createAsyncThunk('advisor/fetchAdvisorFilterDetails', async (_,
  { rejectWithValue }
) => {
  try {
    const response = await advisorService.getAdvisorFilterSettings();

    return response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
