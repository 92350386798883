import { useEffect } from 'react';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'redux/selectors/profile';
import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';


export const useGATagAddUserId = (
  currentAccessToken: string | null,
  isProfileInfoPresent: boolean,
) => {
  const profileId = useAppSelector(profileIdSelector);
  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);

  useEffect(() => {
    if (
      !tenantFeatureFlags?.users
      || !isProfileInfoPresent
      || !currentAccessToken
    ) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: profileId,
      event: 'userIdSet',
    });
  }, [
    isProfileInfoPresent, currentAccessToken,
    tenantFeatureFlags?.users, profileId,
  ]);
};
