import { RoleType } from '_types/user.interface';

import type { NotificationUserMentioned } from '../../types';


export const getNotificationLogoHref = (
  logoUserInfo: NotificationUserMentioned,
): string => {
  switch (logoUserInfo?.userRole) {
    case RoleType.SBO:
      return `/clients/${logoUserInfo.id}`;
    case RoleType.ADVISORY_ORG_ADMIN:
    case RoleType.ADVISOR:
      return `/advisors/${logoUserInfo.id}`;
    default:
      return '';
  }
};
