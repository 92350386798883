import { Pagination } from '_types';
import { ReadBackendNotificationsType } from '_types/notifications.interface';

import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const NotificationService = {
  getReadNotificationsPage: ({ offset, limit }: Pagination) => axiosApiInstance
    .get<ReadBackendNotificationsType>(`${baseUrl}/api/v1/notifications/read?offset=${offset}&limit=${limit}`)
    .then(({ data }) => data),
};
export default NotificationService;
