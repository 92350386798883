import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsSelector } from 'redux/selectors/tenant-settings';


const useTenantTranslation = () => {
  const tenantSettings = useAppSelector(tenantSettingsSelector);
  const tenantTranslationPrefix = tenantSettings?.tenantTranslationPrefix;

  const defaultUseTranslationReturnValue = useTranslation();
  const { t: defaultTFunc, i18n } = defaultUseTranslationReturnValue;

  const t = useCallback(
    (key, options) => {
      if (!tenantTranslationPrefix) {
        return defaultTFunc(key, options);
      }

      if (i18n.exists(key, { ns: tenantTranslationPrefix })) {
        return defaultTFunc(key, { ns: tenantTranslationPrefix, ...options });
      }
      return defaultTFunc(key, options);
    }, [tenantTranslationPrefix, defaultTFunc, i18n]
  );

  return useMemo(
    () => ({ ...defaultUseTranslationReturnValue, t }),
    [t, defaultUseTranslationReturnValue],
  );
};

export default useTenantTranslation as typeof useTranslation;
