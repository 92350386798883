import React, { memo } from 'react';

import useTenantColors from 'utils/hooks/useTenantColors';


interface NotificationsIconReadProps {
  className?: string;
}

const NotificationsIconRead: React.FC<NotificationsIconReadProps> = ({ className }) => {
  const { colorVariables } = useTenantColors();

  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H15C15.5304 17 16.0391 16.7893 16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15V5C17 4.46957 16.7893 3.96086 16.4142 3.58579C16.0391 3.21071 15.5304 3 15 3H5ZM5 5H15V12H13L12 14H8L7 12H5V5Z"
        fill={colorVariables?.icons.icon_notification_unselected || '#D0E7F4'}
      />
    </svg>
  );
};

export default memo(NotificationsIconRead);
