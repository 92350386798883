import React, { ReactNode, memo } from 'react';
import { getValue } from 'firebase/remote-config';


import { remoteConfig } from '../../utils/firebase/firebase';
import ShutdownPage from '../../app/pages/ShutdownPage/ShutdownPage';


interface ShutdownWrapperInterface {
  children: ReactNode;
}

const ShutdownWrapper: React.FC<ShutdownWrapperInterface> = ({ children }) => {
  const isAppShutdown = getValue(remoteConfig, 'isAppShutdown').asBoolean();

  if (isAppShutdown) {
    return <ShutdownPage />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default memo(ShutdownWrapper);
