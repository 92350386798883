import React from 'react';

import Button from 'app/components/Button';
import ArrowRightCircleIcon from 'app/components/Icons/ArrowRightCircleIcon';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


interface ModalFooterProps {
  onNextClick: Function;
  onCancelClick: Function;
  active: boolean;
  step: number;
  totalSteps: number;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  active,
  step,
  totalSteps,
  onNextClick,
  onCancelClick,
}) => {
  const { t } = useTenantTranslation();

  return (
    <div className="modal_footer">
      <div className="buttons_row">
        {step === 1 && <Button
          type="btn"
          btnLook="text"
          value={t('misc.not_now')}
          onClick={onCancelClick}
          size="large"
          className="cancel_button"
        />}
      </div>
      <span className="step_counter">{step} {t('misc.of')} {totalSteps}</span>
      <Button
        disabled={!active}
        className="button_next"
        type="btn"
        btnLook="filled"
        value={totalSteps === step ? t('misc.send_it') : t('misc.continue')}
        onClick={onNextClick}
        size="large"
        rightIcon={totalSteps !== step ? <ArrowRightCircleIcon /> : undefined}
      />
    </div>
  );
};

export default ModalFooter;
