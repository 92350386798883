import React, {
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

import { specifyAnotherField } from '_constants/onboardingConstants';

import { ProfileFieldErrorType } from '_types/profile.interface';
import { TranslateValueType } from '_types';

import ErrorCloud from 'app/components/ErrorCloud';
import SelectButtonEditable from 'app/components/SelectButtonNew/SelectButtonEditable';
import SelectButtonSimple from 'app/components/SelectButtonNew/SelectButtonSimple';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import {
  checkListGetDefaultIdToData as getDefaultIdToData,
} from './utils';

import './style.scss';


type CheckListMultipleValue = string[] | undefined;
type CheckListSingleValue = string | null;
type CheckListOnChangeMultiple = (values: CheckListMultipleValue) => void;
type CheckListOnChangeSingle = (value: CheckListSingleValue) => void;

interface CheckListProps {
  multiple: boolean;
  initiallyChosen: CheckListSingleValue | CheckListMultipleValue;
  onChange: CheckListOnChangeSingle | CheckListOnChangeMultiple;
  allowEmptyList?: boolean;
  availableCheckValues: TranslateValueType[];
  errors?: ProfileFieldErrorType | Record<string, ProfileFieldErrorType>;
  cleanAllValuesRef?: MutableRefObject<Function | null>;
  type?: 'checkbox' | 'select';
  fieldAligment?: 'left' | 'right';
}

const CheckList: React.FC<CheckListProps> = ({
  availableCheckValues,
  onChange,
  multiple,
  allowEmptyList = true,
  initiallyChosen,
  errors,
  cleanAllValuesRef,
  type = 'checkbox',
  fieldAligment = 'left',
}) => {
  const { t } = useTenantTranslation();

  const { initialEditableValue, initialValueToSelected } = useMemo(
    () => getDefaultIdToData(availableCheckValues, initiallyChosen, !!multiple), []
  );

  const [editableValue, setEditableValue] = useState<string>(initialEditableValue);
  const [valueToSelected, setValueToSelected] = useState<Record<string, boolean>>(initialValueToSelected);

  const cleanAllValues = useCallback(() => {
    const newValueToSelected = Object.keys(valueToSelected).reduce(
      (obj, currentKey) => {
        obj[currentKey] = false;
        return obj;
      },
      {}
    );

    setValueToSelected(newValueToSelected);
  }, [valueToSelected, setValueToSelected]);

  useEffect(
    () => {
      if (cleanAllValuesRef) {
        cleanAllValuesRef.current = cleanAllValues;
      }
    }, [cleanAllValues]
  );

  const nonEditableFields = useMemo(
    () => availableCheckValues.filter(
      ({ value }) => value !== specifyAnotherField
    ), [availableCheckValues]
  );

  const editableField = useMemo(
    () => availableCheckValues.find(
      ({ value }) => value === specifyAnotherField
    ), [availableCheckValues]
  );

  const selectedCount = useMemo(
    () => Object
      .values(valueToSelected)
      .filter(isSelected => isSelected)
      .length
    , [valueToSelected]
  );

  const callOnChange = useCallback(
    (valueToSelectedObj: Record<string, boolean>) => {
      const chosenValues = Object.keys(valueToSelectedObj).filter((value) => valueToSelectedObj[value]);

      if (multiple) {
        (onChange as CheckListOnChangeMultiple)(chosenValues);
      } else {
        (onChange as CheckListOnChangeSingle)(chosenValues[0] || null);
      }
    }, [multiple, onChange]
  );

  const shouldClickBeDisabled = useCallback(
    (value) => !allowEmptyList && valueToSelected[value] && selectedCount === 1,
    [allowEmptyList, valueToSelected, selectedCount]
  );

  const onSimpleButtonClick = useCallback(
    (isSelected: boolean, value: string) => {
      const newValueToSelected = multiple ? {
        ...valueToSelected,
        [value]: isSelected,
      } : Object.keys(valueToSelected).reduce(
        (obj, currentValue) => {
          if (currentValue === value) {
            obj[currentValue] = isSelected;
          } else {
            obj[currentValue] = false;
          }
          return obj;
        }, {}
      );

      callOnChange(newValueToSelected);
      setValueToSelected(newValueToSelected);
    }, [multiple, valueToSelected, callOnChange, setValueToSelected]
  );

  const onEditableButtonChange = useCallback(
    (selected: boolean, value: string) => {
      let isSelected = selected;
      const isSelectButtonDisabled = shouldClickBeDisabled(editableValue);
      if (!value && isSelected && isSelectButtonDisabled) {
        return;
      }

      const isValueChanged = value !== editableValue;
      if (!value) {
        isSelected = false;
      } else if (isValueChanged && value && !isSelected) {
        isSelected = true;
      } else if (!isValueChanged && value && !isSelected) {
        value = '';
      }

      const newValueToSelected = multiple ? {
        ...valueToSelected,
        [value]: isSelected,
      } : Object.keys(valueToSelected).reduce(
        (obj, currentValue) => {
          if (currentValue === value) {
            obj[currentValue] = isSelected;
          } else {
            obj[currentValue] = false;
          }
          return obj;
        }, {}
      );

      const valueChanged = editableValue !== value;
      if (valueChanged) {
        delete newValueToSelected[editableValue];
      }

      callOnChange(newValueToSelected);
      setValueToSelected(newValueToSelected);

      if (valueChanged) {
        setEditableValue(value);
      }
    }, [
      multiple,
      valueToSelected,
      editableValue,
      callOnChange,
      shouldClickBeDisabled,
      setEditableValue,
      setValueToSelected,
    ]);

  return (
    <div className="check_list">
      {
        errors && (isString(errors) || isArray(errors)) && errors.length
        && <ErrorCloud errorsListOrErrorText={errors} />
      }

      {nonEditableFields.map(
        (checkValue, index) => (
          <SelectButtonSimple
            key={index}
            title={checkValue.name}
            value={checkValue.value}
            disabled={shouldClickBeDisabled(checkValue.value)}
            onClick={onSimpleButtonClick}
            selected={valueToSelected[checkValue.value]}
            type={type}
            errors={errors && errors[checkValue.value]}
            fieldAligment={fieldAligment}
          />
        )
      )}

      {editableField && (
        <SelectButtonEditable
          key="editable"
          value={editableValue}
          placeholder={t('misc.other_specify')}
          disabled={shouldClickBeDisabled(editableValue)}
          onChange={onEditableButtonChange}
          selected={valueToSelected[editableValue]}
          type={type}
          errors={errors && errors[editableValue]}
        />
      )}
    </div>
  );
};

export default memo(CheckList);
