import React, { memo } from 'react';


interface LoanAvatarIconProps {
  className?: string;
  fillColor?: string;
  width?: string;
  height?: string;
}
const LoanAvatarIcon: React.FC<LoanAvatarIconProps> = ({
  className, fillColor, width, height,
}) => (
  <svg
    width={width || '54'}
    height={height || '54'}
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="27"
      cy="27"
      r="27"
      fill="#F5F6F7"
    />
    <path
      d="M22 26H20V33H22V26ZM28 26H26V33H28V26ZM36.5 35H17.5V37H36.5V35ZM34 26H32V33H34V26ZM27 19.26L32.21 22H21.79L27 19.26ZM27 17L17.5 22V24H36.5V22L27 17Z"
      fill={fillColor || '#F5F6F7'}
    />
  </svg>
);

export default memo(LoanAvatarIcon);
