import React, { memo, useCallback } from 'react';

import { ResetPasswordModalTitleType } from '_types/modals.interface';

import ResetPasswordForm from 'app/components/ResetPasswordForm';

import { useAppDispatch } from 'store/configureStore';

import { actionHandleShowResetPasswordModal } from 'redux/reducers/modal';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import './style.scss';


interface ResetPasswordModalProps {
  classNames?: string;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ classNames }) => {
  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowResetPasswordModal(null));
  }, [dispatch]);

  const { ref } = useOnClickOutside(true, closeModal);

  return (
    <div className="reset_password__modal">
      <div className="modal_block" ref={ref}>
        <ResetPasswordForm type={ResetPasswordModalTitleType.profile} />
      </div>
    </div>
  );
};

export default memo(ResetPasswordModal);
