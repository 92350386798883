import React, { memo, useCallback } from 'react';
import moment from 'moment';


import { CalendlyInterface } from '_types/calendly.interface';

import PartyPopperIcon from 'app/components/Icons/PartyPopperIcon';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


const summaryItemNames = [
  'method',
  'duration',
  'day',
  'time',
  'profile_sharing',
];

const FinishStepComponent: React.FC<CalendlyInterface> = ({
  zoomUrl,
  phoneNumber,
  ...props
}) => {
  const { t } = useTenantTranslation();

  const createContent = useCallback(
    (itemName) => {
      switch (itemName) {
        case 'day':
          const dateStart = moment.unix(props.startTime).format('dddd, MMM D');
          return dateStart || props.profileSharing;
        case 'time':
          const timeStart = moment.unix(props.startTime).format('h:mm A');
          const timeEnd = moment.unix(props.endTime).format('h:mm A');
          return `${timeStart} ${t('misc.to')} ${timeEnd}`;

        default:
          return props[itemName.toLowerCase()] || props.profileSharing;
      }
    }, [props]
  );

  return (
    <>
      <PartyPopperIcon className="popper_icon" />
      <h2 className="modal_body_title">{t('misc.your_session_booked')}</h2>
      {zoomUrl && <p className="modal_body_subtitle">{t('misc.here_your_meeting_room')}</p>}
      {zoomUrl && (
        <a
          href={zoomUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="modal_body_meeting_link"
        >
          <span className="modal_body_meeting_link_text">{zoomUrl}</span>
        </a>
      )}
      <div className="modal_body_meeting_summary">
        {summaryItemNames.map((value) => (
          <div
            key={value}
            className="modal_body_meeting_summary_item"
          >
            <span className="name">{t(`misc.${value}`)}</span>
            <span className="content">{createContent(value)}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(FinishStepComponent);
