import React, { memo } from 'react';

import useTenantColors from 'utils/hooks/useTenantColors';


interface NotificationsIconUnreadProps {
  className?: string;
}

const NotificationsIconUnread: React.FC<NotificationsIconUnreadProps> = ({ className }) => {
  const { colorVariables } = useTenantColors();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.126 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V16C3 16.5304 3.21071 17.0391 3.58579 17.4142C3.96086 17.7893 4.46957 18 5 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V8.87398C16.6804 8.95625 16.3453 9 16 9C15.6547 9 15.3196 8.95625 15 8.87398V13H13L12 15H8L7 13H5V6H12.126C12.0438 5.68038 12 5.3453 12 5C12 4.6547 12.0438 4.31962 12.126 4Z"
        fill={colorVariables?.icons.icon_notification_unselected || '#D0E7F4'}
      />
      <circle cx="17" cy="4" r="4" fill={colorVariables?.error_alert.error_alert || '#BA2C2B'} />
    </svg>
  );
};

export default memo(NotificationsIconUnread);
