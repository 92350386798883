import React, { memo } from 'react';


interface HomeIconProps {
  className?: string;
  color?: string;
}
const HomeIcon: React.FC<HomeIconProps> = ({ className, color }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill={color || 'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.20643 0.292786C9.0189 0.105315 8.7646 0 8.49943 0C8.23427 0 7.97996 0.105315 7.79243 0.292786L0.792431 7.29279C0.610272 7.48139 0.509478 7.73399 0.511757 7.99619C0.514035 8.25838 0.619204 8.5092 0.804612 8.6946C0.99002 8.88001 1.24083 8.98518 1.50303 8.98746C1.76523 8.98974 2.01783 8.88894 2.20643 8.70679L2.49943 8.41379V14.9998C2.49943 15.265 2.60479 15.5194 2.79232 15.7069C2.97986 15.8944 3.23421 15.9998 3.49943 15.9998H5.49943C5.76465 15.9998 6.019 15.8944 6.20654 15.7069C6.39407 15.5194 6.49943 15.265 6.49943 14.9998V12.9998C6.49943 12.7346 6.60479 12.4802 6.79232 12.2927C6.97986 12.1051 7.23421 11.9998 7.49943 11.9998H9.49943C9.76465 11.9998 10.019 12.1051 10.2065 12.2927C10.3941 12.4802 10.4994 12.7346 10.4994 12.9998V14.9998C10.4994 15.265 10.6048 15.5194 10.7923 15.7069C10.9799 15.8944 11.2342 15.9998 11.4994 15.9998H13.4994C13.7646 15.9998 14.019 15.8944 14.2065 15.7069C14.3941 15.5194 14.4994 15.265 14.4994 14.9998V8.41379L14.7924 8.70679C14.981 8.88894 15.2336 8.98974 15.4958 8.98746C15.758 8.98518 16.0088 8.88001 16.1942 8.6946C16.3797 8.5092 16.4848 8.25838 16.4871 7.99619C16.4894 7.73399 16.3886 7.48139 16.2064 7.29279L9.20643 0.292786Z"
      fill={color || '#D2EBFF'}
    />
  </svg>
);

export default memo(HomeIcon);
