import React, { memo } from 'react';


interface AdvisorsIconProps {
  className?: string;
  color?: string;
}
const AdvisorsIcon: React.FC<AdvisorsIconProps> = ({ className, color }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill={color || '#780008'} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3V4H2.5C1.96957 4 1.46086 4.21071 1.08579 4.58579C0.710714 4.96086 0.5 5.46957 0.5 6V9.57C3.06037 10.5188 5.7695 11.003 8.5 11C11.2305 11.003 13.9396 10.5187 16.5 9.57V6C16.5 5.46957 16.2893 4.96086 15.9142 4.58579C15.5391 4.21071 15.0304 4 14.5 4H12.5V3C12.5 2.20435 12.1839 1.44129 11.6213 0.87868C11.0587 0.316071 10.2956 0 9.5 0H7.5C6.70435 0 5.94129 0.316071 5.37868 0.87868C4.81607 1.44129 4.5 2.20435 4.5 3ZM6.79289 2.29289C6.60536 2.48043 6.5 2.73478 6.5 3V4H10.5V3C10.5 2.73478 10.3946 2.48043 10.2071 2.29289C10.0196 2.10536 9.76522 2 9.5 2H7.5C7.23478 2 6.98043 2.10536 6.79289 2.29289ZM7.79289 7.29289C7.60536 7.48043 7.5 7.73478 7.5 8C7.5 8.26522 7.60536 8.51957 7.79289 8.70711C7.98043 8.89464 8.23478 9 8.5 9H8.51C8.77522 9 9.02957 8.89464 9.21711 8.70711C9.40464 8.51957 9.51 8.26522 9.51 8C9.51 7.73478 9.40464 7.48043 9.21711 7.29289C9.02957 7.10536 8.77522 7 8.51 7H8.5C8.23478 7 7.98043 7.10536 7.79289 7.29289ZM0.5 13.9999V11.6919C3.013 12.5399 5.704 12.9999 8.5 12.9999C11.2201 13.0025 13.9224 12.5607 16.5 11.6919V13.9999C16.5 14.5303 16.2893 15.039 15.9142 15.4141C15.5391 15.7892 15.0304 15.9999 14.5 15.9999H2.5C1.96957 15.9999 1.46086 15.7892 1.08579 15.4141C0.710714 15.039 0.5 14.5303 0.5 13.9999Z"
      fill={color || '#D2EBFF'}
    />
  </svg>
);

export default memo(AdvisorsIcon);
