import React, { memo } from 'react';
import ReactCodeInput from 'react-code-input';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import './style.scss';


const getCodeInputInputStyle = (isPhone: boolean): React.CSSProperties => ({
  borderRadius: '6px',
  border: '1px solid var(--neutral-7)',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
  textAlign: 'center',
  marginLeft: isPhone ? '4px' : '16px',
  width: isPhone ? '35px' : '50px',
  height: isPhone ? '43px' : '62px',
  boxSizing: 'border-box',
  color: 'black',
  backgroundColor: 'var(--neutral-8)',
});

const getCodeInputInputStyleError = (isPhone: boolean): React.CSSProperties => ({
  ...getCodeInputInputStyle(isPhone),
  border: '2px solid var(--error-alert)',
});

interface CodeInputProps {
  name: string;
  cellsNumber: number;
  onChange: (code: string) => void;
  hasError: boolean;
  errorText: string | null | undefined;
}

const CodeInput: React.FC<CodeInputProps> = ({
  name,
  cellsNumber,
  onChange,
  hasError,
  errorText,
}) => {
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const codeInputInputStyle = getCodeInputInputStyle(isPhone);
  const codeInputInputStyleError = getCodeInputInputStyleError(isPhone);

  return (
    <>
      <ReactCodeInput
        type="number"
        name={name}
        fields={cellsNumber}
        inputMode="tel"
        inputStyle={codeInputInputStyle}
        inputStyleInvalid={codeInputInputStyleError}
        onChange={onChange}
        isValid={!hasError}
      />
      {hasError && <p className="code-input-error">{errorText}</p>}
    </>
  );
};

export default memo(CodeInput);
