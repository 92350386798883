import type { BackendNotification } from '_types/notifications.interface';

import type { NotificationUserMentioned } from '../../types';


export enum MentionNotificationObjectMentionedInTypeEnum {
  SESSION_COMMENT = 'session_comment',
}

export interface MentionNotificationObjectMentionedInInterface {
  text: string;
  session: {
    id: number;
    name: string;
  }
}

export type MentionNotificationNotificationObject = BackendNotification & {
  data: {
    objectMentionedInType: MentionNotificationObjectMentionedInTypeEnum,
    objectMentionedIn: any,
    userMentioned: NotificationUserMentioned,
  }
};
