import React, { memo, useMemo } from 'react';


import { useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'redux/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import NotificationBlock from '../NotificationBlock';

import {
  getContentBlock,
  getLinkToObject,
  getTitleBlock,
} from './utils';

import type { MentionNotificationNotificationObject } from './types';


interface MentionNotificationProps {
  notification: MentionNotificationNotificationObject;
}

const MentionNotification: React.FC<MentionNotificationProps> = ({ notification }) => {
  const { t } = useTenantTranslation();

  const profileRole = useAppSelector(profileRoleSelector);

  const titleBlock = useMemo(
    () => getTitleBlock(
      notification.data.objectMentionedInType,
      notification.data.userMentioned,
      t,
    ), [notification.data.objectMentionedInType, t]
  );

  const contentBlock = useMemo(
    () => getContentBlock(notification.data.objectMentionedInType, notification.data.objectMentionedIn),
    [notification.data.objectMentionedInType, notification.data.objectMentionedIn]
  );

  const linkToObject = useMemo(
    () => getLinkToObject(
      notification.data.objectMentionedInType,
      notification.data.objectMentionedIn,
      profileRole!,
      t,
    ),
    [notification.data.objectMentionedInType, notification.data.objectMentionedIn, profileRole, t]
  );

  return <NotificationBlock
    notificationId={notification.id}
    isRead={notification.isRead}
    createdAtUnix={notification.createdAtUnix}
    logoUser={notification.data.userMentioned}
    titleBlock={titleBlock}
    contentBlock={contentBlock}
    linkToObject={linkToObject}
  />;
};

export default memo(MentionNotification);
