import React, { memo } from 'react';

import Logo from 'app/components/Logo/Logo';


const ShutdownPageHeader: React.FC = () => (
  <div className="header_wrapper first-page">
    <Logo className="logo" />
  </div>
);

export default memo(ShutdownPageHeader);
