import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { resourcesEn } from './en/index.js';
import { resourcesEs } from './es/index.js';
import { resourcesAr } from './ar/index.js';
import { resourcesZh } from './zh/index.js';


// Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(en);
export const i18n: any = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    returnObjects: true,
    defaultNS: 'default',
    detection: {
      lookupQuerystring: 'lang',
    },
    supportedLngs: ['en', 'es', 'ar', 'zh'],
    resources: {
      en: resourcesEn,
      es: resourcesEs,
      ar: resourcesAr,
      zh: resourcesZh,
    },
  });
