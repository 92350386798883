import React, { memo } from 'react';


interface HelpCenterIconProps {
  className?: string;
}
const HelpCenterIcon: React.FC<HelpCenterIconProps> = ({ className }) => (
  <svg className={className} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 0.25H1.6875C0.824166 0.25 0.125 0.95 0.125 1.8125V16.1875C0.125 17.05 0.825 17.75 1.6875 17.75H12.3125C13.175 17.75 13.875 17.05 13.875 16.1875V9.625C13.875 8.7962 13.5458 8.00134 12.9597 7.41529C12.3737 6.82924 11.5788 6.5 10.75 6.5H9.1875C8.7731 6.5 8.37567 6.33538 8.08264 6.04235C7.78962 5.74933 7.625 5.3519 7.625 4.9375V3.375C7.625 2.5462 7.29576 1.75134 6.70971 1.16529C6.12366 0.57924 5.3288 0.25 4.5 0.25ZM8.92858 11.904C8.6157 12.3958 8.13658 12.7589 7.57847 12.9271V13.375H6.18958V12.3333C6.18958 12.1492 6.26275 11.9725 6.39298 11.8423C6.52321 11.7121 6.69985 11.6389 6.88403 11.6389C7.0813 11.6389 7.27452 11.5828 7.4412 11.4773C7.60787 11.3718 7.74116 11.2211 7.82554 11.0428C7.90991 10.8644 7.94192 10.6658 7.91783 10.47C7.89375 10.2742 7.81455 10.0893 7.68947 9.93674C7.56438 9.78419 7.39855 9.6703 7.21126 9.60831C7.02398 9.54633 6.82294 9.5388 6.63154 9.5866C6.44015 9.6344 6.26626 9.73558 6.13012 9.87834C5.99397 10.0211 5.90117 10.1996 5.8625 10.3931L4.5 10.1201C4.61042 9.56919 4.90828 9.07349 5.34291 8.71736C5.77754 8.36122 6.32212 8.16662 6.88403 8.16667C7.46693 8.16843 8.0298 8.3795 8.47015 8.76143C8.91049 9.14336 9.19901 9.67075 9.28315 10.2475C9.36729 10.8243 9.24146 11.4122 8.92858 11.904ZM6.18958 15.4583V14.0694H7.57847V15.4583H6.18958Z" fill="#676767" />
    <path d="M8.87496 3.37493C8.87642 2.32372 8.49788 1.3074 7.80912 0.513265C9.20353 0.879902 10.4755 1.61033 11.4951 2.62984C12.5146 3.64935 13.245 4.92136 13.6116 6.31576C12.8175 5.627 11.8012 5.24847 10.75 5.24993H9.18746C9.01496 5.24993 8.87496 5.10993 8.87496 4.93743V3.37493Z" fill="#676767" />
  </svg>

);

export default memo(HelpCenterIcon);
