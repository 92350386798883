import type { RoleType } from './user.interface';


export interface SboSmallData {
  id: number;
  userId: number;
  userRole: RoleType;
  firstName: string;
  lastName: string;
  email: string;
  photo: string;
}

export enum SboType {
  PRE_STARTUP = 'PRE_STARTUP',
  STARTUP = 'STARTUP',
  OPERATING = 'OPERATING',
}
