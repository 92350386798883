import { useCookies } from 'react-cookie';

import {
  cookieMaxAgeForRedirectsFromVerizon,
  cookieNameForRedirectsFromVerizon,
  qpCodeExpected,
  qpNameForCodeCheck,
  qpNameForOriginCheck,
  qpOriginExpectedForRedirectFromVerizon,
} from './constants';


type TypedUseCookiesReturnType = ReturnType<
  typeof useCookies<
    typeof cookieNameForRedirectsFromVerizon
  >
>

export const doStartupCheckRedirectedFromVerizon = (
  cookies: TypedUseCookiesReturnType[0],
  queryParams: URLSearchParams,
  setCookie: TypedUseCookiesReturnType[1],
  onQueryParamsChange: (params: URLSearchParams) => void,
): boolean => {
  const qpOriginValue = queryParams.get(qpNameForOriginCheck);
  const qpCodeValue = queryParams.get(qpNameForCodeCheck);

  const isOriginVerizon = qpOriginValue === qpOriginExpectedForRedirectFromVerizon;
  const isCodeValid = qpCodeValue === qpCodeExpected;

  if (isOriginVerizon && isCodeValid) {
    // Save in cookies the fact that the user was redirected from Verizon
    setCookie(cookieNameForRedirectsFromVerizon, 'true', {
      maxAge: cookieMaxAgeForRedirectsFromVerizon,
    });

    // Remove the query param
    queryParams.delete(qpNameForOriginCheck);
    queryParams.delete(qpNameForCodeCheck);
    onQueryParamsChange(queryParams);

    // Return successful result
    return true;
  }

  const isRedirectFromVerizonConfirmed = cookies[cookieNameForRedirectsFromVerizon];
  if (isRedirectFromVerizonConfirmed) {
    // Redirect is already confirmed
    return true;
  }

  // No confirmation in cookies and no query param set
  return false;
};
