/* eslint-disable @typescript-eslint/no-restricted-imports */
import {
  Auth,
  EmailAuthProvider,
  UserCredential,
  getMultiFactorResolver as getMultiFactorResolverFromFirebase,
  signInWithCredential,
  MultiFactorError,
} from '@firebase/auth';
/* eslint-enable @typescript-eslint/no-restricted-imports */
import { getModularInstance } from '@firebase/util';

import UserService from '_services/userService';


const doBeforeAuthStateChange = async (userCredential: UserCredential) => {
  const idToken = await userCredential.user.getIdToken();
  return UserService.onLoginActions(idToken);
};

export const signInWithEmailAndPassword = async (
  auth: Auth,
  email: string,
  password: string,
): Promise<UserCredential> => {
  const doAfterEmailPasswordRequest = () => {
    return UserService.canLoginOnTenant(email, password);
  };
  try {
    return await signInWithCredential(
      getModularInstance(auth),
      EmailAuthProvider.credential(email, password),
      doAfterEmailPasswordRequest,
      doBeforeAuthStateChange,
    );
  } catch (error: any) {
    if (
      error.code === 'auth/invalid-login-credentials'
    ) {
      await UserService.loginWithInvalidCredentials(email);
    }

    throw error;
  }
};

export const getMultiFactorResolver = (
  auth: Auth,
  error: MultiFactorError,
) => {
  return getMultiFactorResolverFromFirebase(auth, error, doBeforeAuthStateChange);
};
