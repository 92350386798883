import { useCallback, useContext } from 'react';
import { RecaptchaVerifier } from '@firebase/auth';

import { auth } from 'utils/firebase/firebase';

import RecaptchaContainerContext from '.';


const useGetRecaptchaVerifier = () => {
  const {
    recaptchaContainerRef,
    recaptchaVerifierRef,
  } = useContext(RecaptchaContainerContext);

  const getRecaptchaVerifier = useCallback(
    () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }

      if (recaptchaContainerRef!.current) {
        recaptchaContainerRef.current.innerHTML = '<div id="recaptcha-container-id"></div>';
      }

      const recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container-id',
        { size: 'invisible' },
        auth,
      );

      recaptchaVerifierRef!.current = recaptchaVerifier;

      return recaptchaVerifier;
    }, []
  );

  return getRecaptchaVerifier;
};

export default useGetRecaptchaVerifier;
