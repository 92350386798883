import React, { memo, useMemo } from 'react';
import {
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';

import { useAppSelector } from 'store/configureStore';

import {
  isProfileAdvisorOrAdvisoryOrgAdminSelector,
  isProfileInfoPresentSelector,
  isProfileSuperAdminSelector,
  profileHasSkippedMFASetupSelector,
  profileIsEmailConfirmedSelector,
  profileIsMFASetUpSelector,
} from 'redux/selectors/profile';
import { isOnboardingCompletedSelector, isOnboardingProgressPresentSelector } from 'redux/selectors/onboarding';
import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';

import { auth } from 'utils/firebase/firebase';

import { checkIfShouldRedirect } from './utils';


const mfaSetupPagePath = '/authenticated/mfa/setup';
const emailVerificationPagePath = '/authenticated/email-verification';
const onboardingValidationPagePath = '/onboarding';

const pagesToDisableEmailVerificationCheckOn: RegExp[] = [
  new RegExp(emailVerificationPagePath),
];
const pagesToDisableMFASetupCheckOn: RegExp[] = [
  new RegExp(mfaSetupPagePath),
  new RegExp(emailVerificationPagePath),
];
const pagesToDisableOnboardingCheckOn: RegExp[] = [
  new RegExp(mfaSetupPagePath),
  new RegExp(emailVerificationPagePath),
  new RegExp(onboardingValidationPagePath),
];

const doNotForceEmailVerification = process.env.REACT_APP_EMAIL_VERIFICATION_REQUIRED === 'false';
const doNotForceMFASetup = process.env.REACT_APP_MFA_SETUP_REQUIRED === 'false';

const AuthenticatedUserStartupRedirectsWrapper: React.FC = () => {
  const location = useLocation();

  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);
  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
  const hasSkippedMFASetup = useAppSelector(profileHasSkippedMFASetupSelector);
  const isProfileAdvisorOrAdvisoryOrgAdmin = useAppSelector(
    isProfileAdvisorOrAdvisoryOrgAdminSelector,
  );
  const isProfileMFASetUp = useAppSelector(profileIsMFASetUpSelector);
  const isProfileEmailConfirmed = useAppSelector(profileIsEmailConfirmedSelector);
  const isProfileSuperAdmin = useAppSelector(isProfileSuperAdminSelector);

  const isOnboardingProgressPresent = useAppSelector(isOnboardingProgressPresentSelector);
  const isOnboardingCompleted = useAppSelector(isOnboardingCompletedSelector);

  const checksToRunOrdered = useMemo(
    // getIsOk code is executed only when there's profile data and when check not disabled
    () => [
      {
        getIsOk: () => (
          doNotForceEmailVerification
          || isProfileSuperAdmin
          || isProfileEmailConfirmed
        ),
        redirectTo: emailVerificationPagePath,
        pathRegExpsToDisableCheckOn: pagesToDisableEmailVerificationCheckOn,
      },
      {
        getIsOk: () => (
          doNotForceMFASetup
          || !isProfileAdvisorOrAdvisoryOrgAdmin && hasSkippedMFASetup
          || isProfileMFASetUp
        ),
        redirectTo: mfaSetupPagePath,
        pathRegExpsToDisableCheckOn: pagesToDisableMFASetupCheckOn,
      },
      {
        getIsOk: () => isOnboardingCompleted!,
        redirectTo: onboardingValidationPagePath,
        pathRegExpsToDisableCheckOn: pagesToDisableOnboardingCheckOn,
      },
    ], [
      isProfileSuperAdmin, isProfileEmailConfirmed, isProfileAdvisorOrAdvisoryOrgAdmin,
      hasSkippedMFASetup, isProfileMFASetUp, isOnboardingCompleted,
    ],
  );

  const shouldBeRedirectedTo = useMemo(
    (): string | null => {
      const isRequiredUserDataLoaded = (
        auth.currentUser
        && isProfileInfoPresent
        && isOnboardingProgressPresent
      );

      if (!tenantFeatureFlags?.users || !isRequiredUserDataLoaded) {
        return null;
      }

      // Run checks
      const currentPathname = location.pathname;

      // eslint-disable-next-line no-restricted-syntax
      for (const checkObj of checksToRunOrdered) {
        const shouldRedirect = checkIfShouldRedirect(
          checkObj.getIsOk,
          currentPathname,
          checkObj.pathRegExpsToDisableCheckOn,
        );

        if (shouldRedirect) {
          return checkObj.redirectTo;
        }
      }

      return null;
    }, [location.pathname, checksToRunOrdered]
  );

  if (shouldBeRedirectedTo) {
    return <Navigate to={shouldBeRedirectedTo} />;
  }

  return <Outlet />;
};

export default memo(AuthenticatedUserStartupRedirectsWrapper);
