import clsx from 'clsx';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  HTMLInputTypeAttribute,
  useMemo,
} from 'react';
import { DefaultTFuncReturn } from 'i18next';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ErrorCloud from 'app/components/ErrorCloud';
import BulletsRequiredList from 'app/components/BulletsRequiredList';
import CloseEyeIcon from 'app/components/Icons/CloseEyeIcon';
import EyeIcon from 'app/components/Icons/EyeIcon';

import '../style.scss';
import './style.scss';


type BulletsRequiredListProps = {
  withBulletsRequiredList: true;
  bulletsRequiredTitle?: string;
  bulletsRequiredChecksList: string[] | DefaultTFuncReturn[];
  bulletsRequiredClassName?: string;
} | {
  withBulletsRequiredList?: false;
};

export type InputProps = {
  type?: HTMLInputTypeAttribute;
  value: string;
  onChange: Function;
  placeholder?: string;
  name?: string;
  autocomplete?: boolean | string;
  disabled?: boolean;
  maxLength?: number;
  readonly?: boolean;
  errors?: ProfileFieldErrorType | ProfileFieldErrorType[];
  errorWithFocus?: boolean;
  handleFocus?: { inputFocus: boolean | null; setInputFocus: Function };
  isDisplayErrorOutline?: boolean;
} & BulletsRequiredListProps;

const Input: React.FC<InputProps> = (props) => {
  const {
    type,
    name,
    autocomplete,
    disabled,
    maxLength,
    placeholder,
    readonly,
    value,
    onChange,
    errors,
    errorWithFocus,
    handleFocus,
    withBulletsRequiredList,
    isDisplayErrorOutline,
  } = props;

  const [inputType, setInputType] = useState<HTMLInputTypeAttribute | undefined>(type);
  const [focus, setFocus] = useState<boolean>(false);
  const inputRef = useRef<any | null>(null);

  const setPasswordVisible = useCallback(
    () => {
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }, [inputType]
  );

  const onChangeValue = useCallback(
    (event) => {
      const newValue = event.target.value;

      if (!maxLength || newValue.length <= maxLength) {
        onChange(newValue, event);
      }
    }, [maxLength, onChange]
  );

  const handleStateEvent = useCallback(
    (isFocus: boolean) => () => {
      handleFocus?.setInputFocus(!isFocus);
      setFocus(isFocus);
    }, [handleFocus]
  );
  const onFocus = useMemo(() => handleStateEvent(true), [handleStateEvent]);
  const onBlur = useMemo(() => handleStateEvent(false), [handleStateEvent]);

  useEffect(() => {
    if (!focus && value !== value?.trim()) {
      onChange(value?.trim());
    }
  }, [focus]);

  const onWheelPreventChange = useCallback(
    (event) => {
      event.target.blur();
      event.stopPropagation();
    }, []
  );

  const autocompleteValue = (
    typeof autocomplete === 'string' ? autocomplete
      : autocomplete ? 'on' : 'off'
  );

  return (
    <div className={clsx(
      'entry_field', {
        error: (errors?.length && !focus || isDisplayErrorOutline),
        focus,
      })}
    >
      <input
        type={inputType}
        name={name}
        autoComplete={autocompleteValue}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readonly}
        value={value}
        onChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onWheel={onWheelPreventChange}
        ref={inputRef}
      />

      {!!errors?.length && (errorWithFocus || !focus) && <ErrorCloud errorsListOrErrorText={errors} />}

      {withBulletsRequiredList && (
        <BulletsRequiredList
          errorsContainerTitle={props.bulletsRequiredTitle}
          checksList={props.bulletsRequiredChecksList}
          errorsList={errors}
          className={props.bulletsRequiredClassName}
        />
      )}

      {type === 'password' && (
        <div onClick={setPasswordVisible}>
          {inputType === 'password' ? <CloseEyeIcon className="eye" /> : <EyeIcon className="eye" />}
        </div>
      )}
    </div>
  );
};

export default memo(Input);
