import React, {
  ButtonHTMLAttributes,
  MouseEvent,
  ReactNode,
  memo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Loader from 'app/components/Loader';

import { useAppDispatch } from 'store/configureStore';

import { actionHandleShowExternalUrlConsentModal } from 'redux/reducers/modal';

import './style.scss';


export type ButtonSizeChoices = 'small' | 'medium' | 'large';
export type ButtonLookChoices = 'filled' | 'filled-gray' | 'text' | 'text-underline';

export type ButtonProps = {
  btnLook: ButtonLookChoices;
  btnType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  onClick?: Function;
  isLoading?: boolean;
  error?: boolean;
  to?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  size: ButtonSizeChoices;
  accept?: string;
  id?: string;
  drop?: any;
  className?: string;
  children?: ReactNode;
  target?: string;
  style?: React.CSSProperties;
  rel?: string;
  href?: string;
} & (
  {
    type: 'btn';
    value?: string;
  } | {
    type: 'link' | 'file' | 'file-dropzone' | 'external-link';
    value?: ReactNode;
  }
)

const Button: React.FC<ButtonProps> = ({
  type,
  btnLook,
  btnType,
  to,
  disabled,
  value,
  children,
  onClick,
  isLoading,
  error,
  leftIcon,
  rightIcon,
  size,
  accept,
  id,
  drop,
  className,
  target,
  style,
  rel,
  href,
}) => {
  const dispatch = useAppDispatch();

  const openExternalResource = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      // eslint-disable-next-line no-unused-expressions
      if (href) {
        dispatch(actionHandleShowExternalUrlConsentModal({
          externalUrl: href,
          target,
          rel,
        }));
      }
    }, [href, target, rel]
  );

  const selectType = (): JSX.Element => {
    switch (type) {
      case 'btn':
        return (
          <button
            className={clsx(`btn ${btnLook}`, { loading: isLoading })}
            disabled={disabled}
            value={value}
            /* eslint-disable-next-line react/button-has-type */
            type={btnType}
            onClick={onClick as React.MouseEventHandler}
            style={style}
          >
            {leftIcon && <div className="icon_left">{leftIcon}</div>}
            {children || value && <div className="children">{children || value}</div>}
            {rightIcon && <div className="icon_right">{rightIcon}</div>}
            {isLoading && (
              <Loader
                withContainer={false}
                withText={false}
                size="small"
                color={
                  btnLook === 'filled'
                    ? 'white' : btnLook === 'filled-gray'
                      ? 'main-gray' : 'black'
                }
              />
            )}
          </button>
        );
      case 'link':
        if (to) {
          return (
            <Link
              className={clsx(`btn ${btnLook}`, 'link')}
              style={style}
              to={to}
              onClick={onClick as React.MouseEventHandler}
              target={target}
              rel={rel}
            >
              {children || value}
            </Link>
          );
        } if (href) {
          return (
            <a
              className={clsx(`btn ${btnLook}`, 'link')}
              style={style}
              href={href}
              onClick={onClick as React.MouseEventHandler}
              target={target}
              rel={rel}
            >
              {children || value}
              {rightIcon && <div className="icon_right">{rightIcon}</div>}
            </a>
          );
        }
        return (
          <span
            className={clsx(`btn ${btnLook}`, 'link')}
            onClick={onClick as React.MouseEventHandler}
            style={style}
          >
            {children || value}
          </span>
        );

      case 'file':
        return (
          <button
            disabled={disabled}
            /* eslint-disable-next-line react/button-has-type */
            type={btnType}
            className={clsx('btn button_wrapper__file', btnLook)}
          >
            <label htmlFor={id}>{children || value}</label>
            <input {...drop} id={id} type="file" onChange={onClick} accept={accept} />
          </button>
        );
      case 'file-dropzone':
        return (
          <div className={clsx('btn button_wrapper__file', btnLook)}>
            <label htmlFor={id}>{children || value}</label>
            <input {...drop} />
          </div>
        );
      case 'external-link':
        return (
          <div
            className={clsx('btn', btnLook, 'external_link')}
            style={style}
            onClick={(event) => { openExternalResource(event); if (onClick) { onClick(event); } }}
          >
            {leftIcon && <div className="icon_left">{leftIcon}</div>}
            {children || value}
            {rightIcon && <div className="icon_right">{rightIcon}</div>}

          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={clsx('button_wrapper', className, size, { error, button_wrapper__icon: rightIcon || leftIcon })}
    >
      {selectType()}
    </div>
  );
};

export default memo(Button);
