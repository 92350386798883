import React, { memo } from 'react';


interface ExitIconProps {
  className?: string;
}
const ExitIcon: React.FC<ExitIconProps> = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.44444 5C5.32657 5 5.21352 5.04683 5.13017 5.13017C5.04683 5.21352 5 5.32657 5 5.44444V15.5556C5 15.6734 5.04683 15.7865 5.13017 15.8698C5.21352 15.9532 5.32657 16 5.44444 16H8.33333C8.88562 16 9.33333 16.4477 9.33333 17C9.33333 17.5523 8.88562 18 8.33333 18H5.44444C4.79614 18 4.17438 17.7425 3.71596 17.284C3.25754 16.8256 3 16.2039 3 15.5556V5.44444C3 4.79614 3.25754 4.17438 3.71596 3.71596C4.17438 3.25754 4.79614 3 5.44444 3H8.33333C8.88562 3 9.33333 3.44772 9.33333 4C9.33333 4.55228 8.88562 5 8.33333 5H5.44444Z"
      fill="#D2EBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6819 6.18176C13.0724 5.79123 13.7056 5.79123 14.0961 6.18176L17.7072 9.79287C18.0977 10.1834 18.0977 10.8166 17.7072 11.2071L14.0961 14.8182C13.7056 15.2087 13.0724 15.2087 12.6819 14.8182C12.2913 14.4277 12.2913 13.7945 12.6819 13.404L15.5859 10.5L12.6819 7.59597C12.2913 7.20545 12.2913 6.57228 12.6819 6.18176Z"
      fill="#D2EBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33328 10.5C7.33328 9.94772 7.781 9.5 8.33328 9.5H16.9999C17.5522 9.5 17.9999 9.94772 17.9999 10.5C17.9999 11.0523 17.5522 11.5 16.9999 11.5H8.33328C7.781 11.5 7.33328 11.0523 7.33328 10.5Z"
      fill="#D2EBFF"
    />
  </svg>
);

export default memo(ExitIcon);
