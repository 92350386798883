/* eslint-disable react/jsx-closing-tag-location */
import React, {
  memo,
  useCallback,
} from 'react';

import PaymentCalculator from 'app/components/PaymentCalculator';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showCalculatorModalSelector } from 'redux/selectors/modal';
import { actionHandleShowCalculatorModal } from 'redux/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';


const className = {
  modalContainer: 'calculator_modal_container',
  modal: 'calculator_modal',
  modalInnerContainer: 'calculator_modal_inner_container',
  modalTitle: 'calculator_modal_title',
};

interface CalculatorModalProps {
  classNames?: string;
}

const CalculatorModal: React.FC<CalculatorModalProps> = ({
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();

  const showCalculatorModal = useAppSelector(showCalculatorModalSelector)!;

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowCalculatorModal(false));
  }, [dispatch]);

  return (
    <>
      {
        showCalculatorModal && (
          <BaseModal
            modalTitle={t('funding.loan_repayment_calculator.title')}
            closeOnBackgroundClick
            onModalClose={closeModal}
            classNames={className}
            isCloseIconVisible
          >
            <PaymentCalculator />
          </BaseModal>
        )
      }
    </>
  );
};

export default memo(CalculatorModal);
