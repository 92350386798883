import React, { memo, useMemo, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import clsx from 'clsx';

import './style.scss';


interface EllipsisProps {
  text: string;
  maxLine?: number;
  customStyle?: {
    container: string;
  }
  readMoreText?: string;
  readLessText?: string;
}
const Ellipsis: React.FC<EllipsisProps> = (props) => {
  const {
    text, maxLine = 2, customStyle, readMoreText, readLessText,
  } = props;
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
  const [isEllipsis, setEllipsis] = useState<boolean>(true);

  const readMoreBtn = useMemo(() => (
    <button
      type="button"
      onClick={() => setEllipsis(false)}
      className="read_more"
    >
      {readMoreText || '... Read more'}
    </button>
  ), []);
  const readLessBtn = useMemo(() => (
    <button
      type="button"
      onClick={() => setEllipsis(true)}
      className="read_less"
    >
      {readLessText || 'Read less'}
    </button>
  ), []);

  return (
    <div className={clsx('ellipsis', customStyle?.container)}>
      {isEllipsis
        ? <ResponsiveEllipsis
            text={text}
            maxLine={maxLine}
            ellipsis={readMoreBtn}
        />
        : (
          <>
            <p>{text}</p>
            {readLessBtn}
          </>
        )}
    </div>
  );
};

export default memo(Ellipsis);
