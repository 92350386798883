import React, { memo } from 'react';

import LanguageSwitch from 'app/components/LanguageSwitch';

import { useAppSelector } from 'store/configureStore';

import { profileIdSelector } from 'redux/selectors/profile';
import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';

import GeneralHeaderAuthProfilePart from './GeneralHeaderAuthProfilePart';
import GeneralHeaderUnauthProfilePart from './GeneralHeaderUnauthProfilePart';


const HeaderProfile: React.FC = () => {
  const profileId = useAppSelector(profileIdSelector);
  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);

  return (
    <div className="profile">
      {tenantFeatureFlags?.languageSwitch && <LanguageSwitch />}

      {profileId && tenantFeatureFlags?.users ? (
        <GeneralHeaderAuthProfilePart />
      ) : (
        <GeneralHeaderUnauthProfilePart />
      )}
    </div>
  );
};

export default memo(HeaderProfile);
