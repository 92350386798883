import React, {
  memo, useEffect, useState
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { PlatformLanguages } from '_types';

import ProjectRoutes from 'routes';
import SocketProvider from 'utils/contexts/SocketContext/SocketProvider';
import RecaptchaContainerProvider from 'utils/contexts/RecaptchaContainerContext/RecaptchaContainerProvider';
import { applyPlatformLanguage, getCurrentPlatformLanguage } from 'utils/translations';
import FirebaseRemoteConfigInitialization from 'utils/firebase/FirebaseRemoteConfigInitialization';

import ShutdownWrapper from '../routes/utils/ShutdownWrapper';

import ModalWindowsContainer from './components/Modals';
import HelpButton from './components/HelpButton';
import ExternalScriptsImports from './components/ExternalScriptsImports';
import Loader from './components/Loader';

import '../styles/App.scss';


const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLanguageSet, setIsLanguageSet] = useState<boolean>(false);

  useEffect(() => {
    const isLanguageInQS = searchParams.has('lang');

    if (isLanguageInQS) {
      searchParams.delete('lang');
      setSearchParams(searchParams);
    }

    const langToSet: PlatformLanguages = getCurrentPlatformLanguage();

    applyPlatformLanguage(langToSet, i18n, false)
      .finally(() => setIsLanguageSet(true));
  }, []);

  if (!isLanguageSet) {
    return (
      <Loader withText={false} />
    );
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} />

      <div className="app">
        <FirebaseRemoteConfigInitialization>
          <ShutdownWrapper>
            <SocketProvider>
              <RecaptchaContainerProvider>
                <ExternalScriptsImports />
                <ProjectRoutes />
                <ModalWindowsContainer />
                <HelpButton />
              </RecaptchaContainerProvider>
            </SocketProvider>
          </ShutdownWrapper>
        </FirebaseRemoteConfigInitialization>
      </div>
    </>
  );
};

export default memo(App);
