import React, {
  useCallback, memo, useMemo, ReactNode
} from 'react';
import { NavLink } from 'react-router-dom';

import { LoginFormType } from '_types/modals.interface';
import { TenantTypeEnum } from '_types/tenant-settings.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowLoginModal } from 'redux/reducers/modal';
import { tenantSettingsTenantFeatureFlagsSelector, tenantSettingsTenantTypeSelector } from 'redux/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


const GeneralHeaderUnauthProfilePart: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);
  const tenantType = useAppSelector(tenantSettingsTenantTypeSelector);

  const onLoginClick = useCallback(
    () => {
      dispatch(actionHandleShowLoginModal({ formType: LoginFormType.login }));
    }, [dispatch]
  );

  const headerButtons = useMemo(
    () => {
      const buttons: ReactNode[] = [];

      if (tenantType === TenantTypeEnum.VERIZON) {
        buttons.push(
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            {t('misc.funding')}
          </NavLink>
        );
      }

      if (tenantType === TenantTypeEnum.VERIZON && tenantFeatureFlags?.glossary) {
        buttons.push(
          <NavLink to="/glossary">
            {t('glossary.title')}
          </NavLink>
        );
      }

      if (tenantFeatureFlags?.aboutUs) {
        buttons.push(
          <NavLink to="/about-us" className={({ isActive }) => (isActive ? 'active' : '')}>
            {t('misc.about_us')}
          </NavLink>
        );
      }

      if (tenantFeatureFlags?.users) {
        buttons.push(
          <div onClick={onLoginClick}>
            {t('misc.log_in')}
          </div>
        );
      }

      return buttons;
    }, [
      tenantFeatureFlags?.users, tenantFeatureFlags?.aboutUs,
      tenantType, onLoginClick, t,
    ]
  );

  return (
    <div className="profile__right-menu">
      {headerButtons.map(
        (buttonElement, index) => (
          <React.Fragment key={index}>
            {buttonElement}
            {index !== headerButtons.length - 1 && <div className="horizontal-divider" />}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default memo(GeneralHeaderUnauthProfilePart);
