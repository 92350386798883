import React, { memo, useCallback, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';


import { phoneConstant } from '_constants/otherConstants';

import LoanDollarIcon from 'app/components/Icons/LoanDollarIcon';
import OpenInNewTabIcon from 'app/components/Icons/OpenInNewTabIcon';
import Button from 'app/components/Button';
import Ellipsis from 'app/components/Ellipsis';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showFundingModalSelector } from 'redux/selectors/modal';
import { actionHandleShowNonDigitalFundingInfoModal } from 'redux/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';


const classNames = {
  modal: 'funding_modal',
  modalInnerContainer: 'funding_modal_container',
  modalHeader: 'funding_modal_header',
  modalContent: 'funding_modal_content',
  modalFooter: 'funding_modal_footer',
};

const NonDigitalFundingInfoModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const showFundingModal = useAppSelector(showFundingModalSelector);

  const closeModal = useCallback(
    () => dispatch(actionHandleShowNonDigitalFundingInfoModal(null)),
    [dispatch],
  );

  const headerIcon = useMemo(
    () => <LoanDollarIcon color={showFundingModal?.mainColor} />,
    [showFundingModal?.mainColor],
  );

  return (
    <BaseModal
      closeOnBackgroundClick
      onModalClose={closeModal}
      withCloseButton
      closeButtonSize={isPhone ? 'medium' : 'large'}
      closeButtonText={t('misc.ok_got_it')}
      headerIcon={showFundingModal?.headerIcon || headerIcon}
      modalTitle={showFundingModal?.loanName || ''}
      classNames={classNames}
    >
      <div>
        {showFundingModal?.description
          && <Ellipsis text={showFundingModal?.description} maxLine={3} />}

        {(showFundingModal?.phone || showFundingModal?.email) && (
          <div className="info">
            <p>{t('misc.interested_in_applying_to_this_product')}</p>

            <div className="contact">
              {showFundingModal?.phone && <div className="contact_mobile">{showFundingModal?.phone}</div>}
              {showFundingModal?.email && <div className="contact_email">{showFundingModal?.email}</div>}
            </div>
          </div>
        )}

        {showFundingModal?.fundingProviderUrl && (
          <div className="more_info">
            <p>{t('misc.want_to_learn_more_about_this_product')}</p>

            <div className="website">
              <Button
                type="link"
                btnLook="text"
                size="medium"
                target="_blank"
                rel="noopener noreferrer"
                href={showFundingModal?.fundingProviderUrl}
              >
                {t('misc.visit_website')}
                <OpenInNewTabIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default memo(NonDigitalFundingInfoModal);
