import {
  GroupSessionType,
  SessionInfoType,
  SessionsCountType,
} from '_types/sessions.interface';
import { CalendlyInterface } from '_types/calendly.interface';

import { getCurrentTimeZone } from 'utils/moment';

import axiosApiInstance from './axiosInstance';


interface SessionsListFetchResponseType {
  sessionInfos: SessionInfoType[];
  count: number;
}

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const sessionsService = {
  createNewEvent: (data, timeZone) => axiosApiInstance
    .post<CalendlyInterface>(`${baseUrl}/api/v1/sessions?timezone=${timeZone}`, data)
    .then(({ data }) => data),

  getUpcomingSessions: (offset = 0, limit = 20, sboInfoId = '') => {
    const currentDate = new Date().toISOString();
    const timezone = getCurrentTimeZone();

    const queryParams = new URLSearchParams({
      timezone,
      offset: String(offset),
      limit: String(limit),
      date: currentDate,
      isPast: 'false',
      sboInfoId,
    }).toString();

    const url = `${baseUrl}/api/v1/sessions?${queryParams}`;

    return axiosApiInstance
      .get<SessionsListFetchResponseType>(url)
      .then(({ data }) => data);
  },

  getPastSessions: (offset = 0, limit = 20, sboInfoId = '') => {
    const currentDate = new Date().toISOString();
    const timezone = getCurrentTimeZone();

    const queryParams = new URLSearchParams({
      timezone,
      offset: String(offset),
      limit: String(limit),
      date: currentDate,
      isPast: 'true',
      sboInfoId,
    }).toString();

    const url = `${baseUrl}/api/v1/sessions?${queryParams}`;

    return axiosApiInstance
      .get<SessionsListFetchResponseType>(url)
      .then(({ data }) => data);
  },

  getSessionById: id => axiosApiInstance
    .get<SessionInfoType>(`${baseUrl}/api/v1/sessions/${id}`).then(({ data }) => data),

  getGroupAllSessions: data => {
    const queryParams = new URLSearchParams(data).toString();

    return axiosApiInstance
      .get<GroupSessionType[]>(`${baseUrl}/api/v1/sessions/group-all?${queryParams}`)
      .then(({ data }) => data);
  },

  getGroupOpenSessions: data => {
    const queryParams = new URLSearchParams(data).toString();

    return axiosApiInstance
      .get<GroupSessionType[]>(`${baseUrl}/api/v1/sessions/group-open?${queryParams}`)
      .then(({ data }) => data);
  },

  getClientsCounts: () => axiosApiInstance
    .get<SessionsCountType>(`${baseUrl}/api/v1/sessions/clients-counts`)
    .then(({ data }) => data),

  postSessionsRate: (id, data) => axiosApiInstance
    .post<void>(`${baseUrl}/api/v1/sessions/rate/${id}`, data)
    .then(({ data }) => data),
};

export default sessionsService;
