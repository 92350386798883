import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BackendNotification, ReadBackendNotificationsType } from '_types/notifications.interface';

import { fetchReadNotifications } from 'redux/actions/notification';

import { filterAlreadyExistingNotifications, findPlaceWhereToPutUnreadNotification } from 'utils/notifications';


interface NotificationState {
  unread: BackendNotification[];
  read: null | ReadBackendNotificationsType;
  isLoadingReadNotifications: boolean;
}

const initialNotificationState: NotificationState = {
  unread: [],
  read: null,
  isLoadingReadNotifications: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialNotificationState,
  reducers: {
    resetState: () => initialNotificationState,
    actionSetUnreadNotifications(state, action: PayloadAction<BackendNotification[]>) {
      state.unread = action.payload;
    },
    actionAddUnreadNotification(state, action: PayloadAction<BackendNotification>) {
      const newNotification = action.payload;

      const indexWhereToPutNotification = findPlaceWhereToPutUnreadNotification(
        newNotification,
        state.unread,
      );

      state.unread.splice(indexWhereToPutNotification, 0, newNotification);
    },
    actionUnreadNotificationsSeen(state, action: PayloadAction<number[]>) {
      if (!action.payload.length) {
        return;
      }

      const idsToMarkAsRead = new Set(action.payload);

      state.unread = state.unread.map(
        unreadN => (idsToMarkAsRead.has(unreadN.id) ? ({ ...unreadN, isRead: true }) : unreadN),
      );
    },
    actionRemoveReadNotificationsFromUnread(state) {
      state.unread = state.unread.filter(({ isRead }) => !isRead);
    },
    actionSetReadNotifications(state, action: PayloadAction<null | ReadBackendNotificationsType>) {
      state.read = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchReadNotifications.pending, state => {
      state.isLoadingReadNotifications = true;
    });
    builder.addCase(fetchReadNotifications.rejected, state => {
      state.isLoadingReadNotifications = false;
    });
    builder.addCase(fetchReadNotifications.fulfilled, (state, action) => {
      state.isLoadingReadNotifications = false;

      const readNotificationsData = action.payload;

      const notificationsToAddList = filterAlreadyExistingNotifications(
        readNotificationsData.notifications,
        state.unread || [],
      );

      if (!state.read || !readNotificationsData) {
        state.read = {
          ...readNotificationsData,
          notifications: notificationsToAddList,
        };
      } else {
        state.read = {
          limit: readNotificationsData.limit,
          offset: readNotificationsData.offset,
          count: readNotificationsData.count,
          notifications: [
            ...state.read.notifications,
            ...notificationsToAddList,
          ],
        };
      }
    });
  },
});

export const {
  actionSetUnreadNotifications,
  actionAddUnreadNotification,
  actionUnreadNotificationsSeen,
  actionSetReadNotifications,
  actionRemoveReadNotificationsFromUnread,
} = notificationSlice.actions;

export default notificationSlice;
