import React, { memo } from 'react';


interface ChatSupportIconProps {
  className?: string;
}
const ChatSupportIcon: React.FC<ChatSupportIconProps> = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17815_12131)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.90909C0 2.13755 0.287337 1.39761 0.7988 0.852053C1.31026 0.306493 2.00396 0 2.72727 0H12.2727C12.996 0 13.6897 0.306493 14.2012 0.852053C14.7127 1.39761 15 2.13755 15 2.90909V8.72727C15 9.49881 14.7127 10.2388 14.2012 10.7843C13.6897 11.3299 12.996 11.6364 12.2727 11.6364H9.54545L5.45455 16V11.6364H2.72727C2.00396 11.6364 1.31026 11.3299 0.7988 10.7843C0.287337 10.2388 0 9.49881 0 8.72727V2.90909ZM4.5 4.0317L6.19823 4.3854C6.24643 4.13469 6.3621 3.90336 6.53179 3.71833C6.70148 3.53331 6.91822 3.40219 7.15678 3.34023C7.39533 3.27828 7.64591 3.28804 7.87934 3.36837C8.11278 3.44871 8.31947 3.59631 8.47538 3.79402C8.63129 3.99173 8.72999 4.2314 8.76002 4.48516C8.79004 4.73892 8.75015 4.99632 8.64498 5.22743C8.5398 5.45853 8.37368 5.65383 8.16593 5.7906C7.95819 5.92737 7.71736 5.99998 7.47147 6H6.60591V7.8H7.47147C8.04514 7.79981 8.60697 7.63027 9.09159 7.31109C9.57622 6.99191 9.96373 6.53621 10.209 5.997C10.4544 5.4578 10.5474 4.85725 10.4773 4.26523C10.4072 3.6732 10.1769 3.11403 9.81321 2.65277C9.44948 2.1915 8.96727 1.8471 8.42268 1.65963C7.87808 1.47215 7.29348 1.44931 6.73689 1.59376C6.1803 1.73821 5.67459 2.04402 5.2786 2.4756C4.88261 2.90719 4.6126 3.44681 4.5 4.0317ZM6.60591 8.7V10.5H8.33703V8.7H6.60591Z" fill="#676767" />
      <path d="M16.2389 7V9.375C16.2389 10.6348 15.7384 11.843 14.8476 12.7338C13.9568 13.6245 12.7486 14.125 11.4889 14.125H10.0971L8 16.2233C8.3325 16.4002 8.71131 16.5 9.11387 16.5H11.4889L15.0514 20.0625V16.5H17.4264C18.0563 16.5 18.6603 16.2498 19.1057 15.8044C19.5511 15.359 19.8014 14.7549 19.8014 14.125V9.375C19.8014 8.74511 19.5511 8.14102 19.1057 7.69562C18.6603 7.25022 18.0563 7 17.4264 7H16.2389Z" fill="#676767" />
    </g>
    <defs>
      <clipPath id="clip0_17815_12131">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default memo(ChatSupportIcon);
