import React, { memo } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


interface ErrorCloudErrorTextLineProps {
  error: ProfileFieldErrorType;
}

const ErrorCloudErrorTextLine: React.FC<ErrorCloudErrorTextLineProps> = ({
  error,
}) => {
  const { t } = useTenantTranslation();

  return (
    <p className="error_cloud__bullet_list__text">
      {typeof error === 'string' ? t(error) : error}
    </p>
  );
};

export default memo(ErrorCloudErrorTextLine);
