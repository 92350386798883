import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import type { TenantLegalDocumentTypeEnum } from '_types/tenant-settings.interface';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import {
  getTenantLegalDocumentNameByType,
  getTenantLegalDocumentUrlByType,
} from 'utils/tenantLegalDocument';


interface TenantLegalDocumentLinkProps {
  documentType: TenantLegalDocumentTypeEnum;
  openInNewTab?: boolean;
}

const TenantLegalDocumentLink: React.FC<TenantLegalDocumentLinkProps> = ({
  documentType,
  openInNewTab = false,
}) => {
  const { t } = useTenantTranslation();

  const documentLink = getTenantLegalDocumentUrlByType(documentType);
  const documentName = getTenantLegalDocumentNameByType(documentType, t);

  return openInNewTab
    ? (
      <a
        href={documentLink}
        target="_blank"
        rel="noopener noreferrer"
        className="tenant-legal-document-link"
      >
        {documentName}
      </a>
    ) : (
      <Link to={documentLink} className="tenant-legal-document-link">
        {documentName}
      </Link>
    );
};

export default memo(TenantLegalDocumentLink);
