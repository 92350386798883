import React, { memo } from 'react';

import { IconType } from '_types';


const ClientsIcon: React.FC<IconType> = ({ className, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" width="19" height="14" viewBox="0 0 19 14">
    <path fill="CurrentColor" d="M8.5 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm8 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-4 11A7.2 7.2 0 0 0 11 8.7a5 5 0 0 1 7.5 4.3v1h-6Zm-7-6a5 5 0 0 1 5 5v1H.5v-1a5 5 0 0 1 5-5Z" />
  </svg>
);

export default memo(ClientsIcon);
