import axios from 'axios';

import { getAccessAuthToken } from 'utils/authTokens';
import { auth } from 'utils/firebase/firebase';
import { newRelic } from 'utils/newRelic';


const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers ||= {};
    config.headers.Authorization ||= await getAccessAuthToken(true);
    config.headers.Accept ||= 'application/json';
    config.headers['Content-Type'] ||= 'application/json';

    return config;
  },
  error => Promise.reject(error),
);
axiosApiInstance.interceptors.response.use(
  response => {
    newRelic.addPageAction('API-Event-Succeed', { ...response });
    return response;
  },
  async error => {
    newRelic.addPageAction('API-Event-Failed', { ...error });
    if (error?.response?.status === 401) {
      await auth?.currentUser?.reload();
    }

    return Promise.reject(error);
  },
);

export default axiosApiInstance;
