export enum NotificationTypeEnum {
  MENTION = 'mention',
  PROFILE_ACCESS_REQUESTED = 'profile_access_requested',
  PROFILE_ACCESS_GRANTED = 'profile_access_granted',
  RATE_SUGGESTION = 'rate_suggestion',
  NOTE_ADDED = 'note_added',
  SESSION_COMPLETED = 'session_completed',
  SESSION_BOOKED = 'session_booked',
  SESSION_SCHEDULED = 'session_scheduled',
  SESSION_RESCHEDULED = 'session_rescheduled',
  SESSION_CANCELED_TO_SBO = 'session_canceled_to_sbo',
  SESSION_CANCELED_TO_ADVISOR = 'session_canceled_to_advisor',
}

export interface BackendNotification {
  id: number;
  type: NotificationTypeEnum;
  userId: number;
  data: Record<string, any>;
  isRead: boolean;
  createdAtUnix: number;
}

export interface ReadBackendNotificationsType {
  limit: number;
  offset: number;
  count: number;
  notifications: BackendNotification[];
}
