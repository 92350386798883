

import React, { memo } from 'react';


interface PeopleIconProps {
  className?: string;
  color?: string;
}
const PeopleIcon: React.FC<PeopleIconProps> = ({ className, color = '#F3F8EC' }) => (
  <svg className={className} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon">
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M7.375 5.625C7.375 4.7962 7.70424 4.00134 8.29029 3.41529C8.87634 2.82924 9.6712 2.5 10.5 2.5C11.3288 2.5 12.1237 2.82924 12.7097 3.41529C13.2958 4.00134 13.625 4.7962 13.625 5.625C13.625 6.4538 13.2958 7.24866 12.7097 7.83471C12.1237 8.42076 11.3288 8.75 10.5 8.75C9.6712 8.75 8.87634 8.42076 8.29029 7.83471C7.70424 7.24866 7.375 6.4538 7.375 5.625ZM13.625 8.125C13.625 7.46196 13.8884 6.82607 14.3572 6.35723C14.8261 5.88839 15.462 5.625 16.125 5.625C16.788 5.625 17.4239 5.88839 17.8928 6.35723C18.3616 6.82607 18.625 7.46196 18.625 8.125C18.625 8.78804 18.3616 9.42393 17.8928 9.89277C17.4239 10.3616 16.788 10.625 16.125 10.625C15.462 10.625 14.8261 10.3616 14.3572 9.89277C13.8884 9.42393 13.625 8.78804 13.625 8.125ZM2.375 8.125C2.375 7.46196 2.63839 6.82607 3.10723 6.35723C3.57607 5.88839 4.21196 5.625 4.875 5.625C5.53804 5.625 6.17393 5.88839 6.64277 6.35723C7.11161 6.82607 7.375 7.46196 7.375 8.125C7.375 8.78804 7.11161 9.42393 6.64277 9.89277C6.17393 10.3616 5.53804 10.625 4.875 10.625C4.21196 10.625 3.57607 10.3616 3.10723 9.89277C2.63839 9.42393 2.375 8.78804 2.375 8.125ZM5.75833 12.5975C6.26662 11.8009 6.96757 11.1452 7.79635 10.6912C8.62513 10.2372 9.55501 9.99947 10.5 10C11.2915 9.99928 12.0743 10.1657 12.797 10.4885C13.5197 10.8112 14.1661 11.2829 14.6939 11.8728C15.2217 12.4627 15.619 13.1574 15.8597 13.9114C16.1004 14.6654 16.1792 15.4618 16.0908 16.2483C16.08 16.3461 16.0463 16.4398 15.9925 16.5221C15.9386 16.6043 15.8661 16.6727 15.7808 16.7217C14.1738 17.6438 12.3528 18.1277 10.5 18.125C8.57917 18.125 6.775 17.615 5.21917 16.7217C5.13391 16.6727 5.0614 16.6043 5.00754 16.5221C4.95367 16.4398 4.91997 16.3461 4.90917 16.2483C4.76921 14.9705 5.06872 13.6831 5.75833 12.5983V12.5975Z" fill={color} />
      <path id="Vector_2" d="M4.73507 11.8783C3.9132 13.147 3.53028 14.6503 3.64507 16.1575C3.14466 16.0816 2.65249 15.9589 2.17507 15.7908L2.07923 15.7575C1.99373 15.7271 1.91886 15.6727 1.86369 15.6006C1.80852 15.5286 1.77542 15.4421 1.7684 15.3517L1.76007 15.2508C1.7264 14.8325 1.77736 14.4116 1.9099 14.0134C2.04244 13.6151 2.25385 13.2477 2.5315 12.9329C2.80916 12.6182 3.14737 12.3626 3.52597 12.1814C3.90456 12.0002 4.31577 11.8971 4.73507 11.8783ZM17.3551 16.1575C17.4699 14.6503 17.0869 13.147 16.2651 11.8783C16.6844 11.8971 17.0956 12.0002 17.4742 12.1814C17.8528 12.3626 18.191 12.6182 18.4686 12.9329C18.7463 13.2477 18.9577 13.6151 19.0902 14.0134C19.2228 14.4116 19.2737 14.8325 19.2401 15.2508L19.2317 15.3517C19.2246 15.442 19.1914 15.5283 19.1362 15.6001C19.0811 15.672 19.0063 15.7264 18.9209 15.7567L18.8251 15.79C18.3526 15.9567 17.8617 16.0808 17.3551 16.1575Z" fill={color} />
    </g>
  </svg>
);

export default memo(PeopleIcon);
