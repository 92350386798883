import { TenantSettings } from '_types/tenant-settings.interface';

import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const tenantSettingsService = {
  getTenantSettings: () => axiosApiInstance
    .get<TenantSettings>(`${baseUrl}/api/v1/tenant-settings`)
    .then(({ data }) => data),
};

export default tenantSettingsService;
