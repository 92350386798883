export enum SocketNotificationEventInTypes {
  unreadNotificationsToSet = 'unread-notifications-to-set',
  notificationRead = 'notification-read',
  notificationReadMultiple = 'notification-read-multiple',
  notificationNew = 'notification-new',
}

export enum SocketNotificationEventOutTypes {
  notificationRead = 'notification-read',
  notificationReadMultiple = 'notification-read-multiple',
}
