import clsx from 'clsx';
import React, {
  ReactNode, memo, useCallback, useRef, useState
} from 'react';
import { MdOutlineInfo } from 'react-icons/md';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useTenantColors from 'utils/hooks/useTenantColors';

import TooltipTriangle from './TooltipTriangle';

import './style.scss';


interface TooltipProps {
  children: ReactNode;
  className?: string;
  hover?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  hover = false,
}) => {
  const { colorVariables } = useTenantColors();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const iconContainerRef = useRef<HTMLDivElement>(null);

  const onCloseTooltip = useCallback(
    () => setIsOpen(false),
    [setIsOpen]
  );

  const { ref: clickOutsideOfRef } = useOnClickOutside(isOpen, onCloseTooltip);

  return (
    <div ref={clickOutsideOfRef} className={clsx('tooltip', className)}>
      <div
        className="tooltip__question_mark"
        onClick={() => setIsOpen(true)}
        ref={iconContainerRef}
      >
        <MdOutlineInfo size={18} color={colorVariables?.icons.icon_secondary} />
      </div>

      {(isOpen || hover) && (
        <div className="tooltip__content">
          <TooltipTriangle className="tooltip__content__triangle" />

          <div className="tooltip__content__rectangle">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Tooltip);
