import React, { memo, useEffect } from 'react';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsZendeskSelector } from 'redux/selectors/tenant-settings';


const ZendeskScript: React.FC = () => {
  const zendeskSetting = useAppSelector(tenantSettingsZendeskSelector);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskSetting?.zendeskKey}`;
    script.defer = true;
    script.id = 'ze-snippet';


    document.body.appendChild(script);


    return () => {
      document.body.removeChild(script);
    };
  }, [zendeskSetting?.zendeskKey]);

  return null;
};

export default memo(ZendeskScript);

