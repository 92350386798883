import React, { memo, useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import isEmpty from 'lodash/isEmpty';

import Button from 'app/components/Button';
import ArrowRightCircleIcon from 'app/components/Icons/ArrowRightCircleIcon';
import CodeInput from 'app/components/InputComponents/CodeInput';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { MFACodeUsedInFormEnum, processCodeVerificationRequestError } from './utils';

import './style.scss';


const verificationCodeValidationSchema = object({
  verificationCode: string().matches(/^\d{6}$/).required(),
});

interface MFACodeEnterFormProps {
  mfaCodeUsedInFormType: MFACodeUsedInFormEnum;
  phoneNumber: string;
  goToPreviousPageStage: () => void;
  onFormSubmit: (verificationCode: string) => Promise<void>;
  onCodeResend: () => Promise<void>;
}

const MFACodeEnterForm: React.FC<MFACodeEnterFormProps> = ({
  mfaCodeUsedInFormType,
  phoneNumber,
  goToPreviousPageStage,
  onFormSubmit,
  onCodeResend,
}) => {
  const { t } = useTenantTranslation();

  const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);
  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);
  const [verificationCodeError, setVerificationCodeError] = useState<string | null>(null);

  const onResendCode = useCallback(
    async () => {
      setIsResendingCode(true);

      setVerificationCodeError(null);

      await onCodeResend();

      setIsResendingCode(false);
    }, [setIsResendingCode, onCodeResend, setVerificationCodeError]
  );

  const onVerificationCodeSubmit = useCallback(
    async ({ verificationCode }: { verificationCode: string; }) => {
      setIsVerifyingCode(true);
      setVerificationCodeError(null);

      try {
        await onFormSubmit(verificationCode);
      } catch (e: any) {
        const errorMessage = processCodeVerificationRequestError(
          e, t, mfaCodeUsedInFormType,
        );
        setVerificationCodeError(errorMessage);
        // eslint-disable-next-line no-console
        console.error(e);
      }

      setIsVerifyingCode(false);
    },
    [
      t, mfaCodeUsedInFormType,
      onFormSubmit, setIsVerifyingCode,
      setVerificationCodeError,
    ],
  );

  const isLoadingAnything = isVerifyingCode || isResendingCode;

  return (
    <>
      <Formik
        initialValues={{ verificationCode: '' }}
        validationSchema={verificationCodeValidationSchema}
        onSubmit={onVerificationCodeSubmit}
        validateOnMount
      >
        {({
          errors, setFieldValue,
        }) => (
          <Form className="code_verification_form">
            <div className="code_verification_form__text">
              <p className="code_verification_form__text__title">
                {t('auth.mfa_verification_code_title')}
              </p>
              <p className="code_verification_form__text__subtitle">
                {t('auth.mfa_verification_code_text', { phoneNumber })}
              </p>
            </div>

            <div className="code_verification_form__input">
              <CodeInput
                name="verificationCode"
                cellsNumber={6}
                onChange={(value: string) => setFieldValue('verificationCode', value)}
                errorText={verificationCodeError}
                hasError={!!verificationCodeError}
              />
            </div>

            <Button
              type="btn"
              btnType="submit"
              rightIcon={<ArrowRightCircleIcon />}
              btnLook="filled"
              value={t('misc.continue')}
              size="large"
              isLoading={isVerifyingCode}
              disabled={isLoadingAnything || !isEmpty(errors)}
            />

            <Button
              type="link"
              className="code_verification_form__resend_code_btn"
              btnType="button"
              onClick={onResendCode}
              btnLook="text-underline"
              value={t('auth.mfa_code_resend_btn_text')}
              size="medium"
              isLoading={isResendingCode}
              disabled={isLoadingAnything}
            />

            <Button
              type="link"
              btnLook="text-underline"
              btnType="button"
              className="code_verification_form__go_back_btn"
              size="medium"
              onClick={goToPreviousPageStage}
              value={t('misc.go_back')}
              disabled={isLoadingAnything}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default memo(MFACodeEnterForm);
