import { CourseAPIQueryParams, CourseAPIResponse } from '_types/courses.interface';

import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const coursesService = {
  getNewCourses: (query: CourseAPIQueryParams) => {
    const queryParams = new URLSearchParams(Object.entries(query)
      .map(([key, value]) => [value !== undefined ? [key, value].join('=') : null])
      .filter(param => param !== null)
      .join('&')
    );
    return axiosApiInstance
      .get<CourseAPIResponse>(`${baseUrl}/api/v1/course/card?${queryParams}`)
      .then(({ data }) => data);
  },
  getNewHomeCourses: ({
    offset = 0,
    limit = 5,
  }) => {
    const queryParams = new URLSearchParams({
      offset: offset.toString(),
      limit: limit.toString(),
    }).toString();

    return axiosApiInstance
      .get<CourseAPIResponse >(`${baseUrl}/api/v1/course/card-home?${queryParams}`)
      .then(({ data }) => data);
  },
  getVendorList: () => {
    return axiosApiInstance
      .get<string[]>(`${baseUrl}/api/v1/course/vendor-list`)
      .then(({ data }) => data);
  },
};

export default coursesService;
