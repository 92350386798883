import React, { memo } from 'react';


interface HeaderProgressBarProps {
  percent: number;
}
const HeaderProgressBar: React.FC<HeaderProgressBarProps> = ({ percent = 0 }) => (
  <div className="header_line">
    <div style={{ width: `${percent}%` }} />
  </div>
);

export default memo(HeaderProgressBar);
