import {
  PatchProfileData,
  ProfileInfoType,
  ProfileFileUploadDeleteTypeEnum,
  AdvisorInvitation,
} from '_types/profile.interface';

import axiosApiInstance from './axiosInstance';


type FileUploadPayloadType = {
  type: ProfileFileUploadDeleteTypeEnum;
  useOnboardingLink: boolean;
  fileFormData: any;
  onPercentageUpdate: (percentage: number) => void;
  abortController?: AbortController;
};

type FileDeletePayloadType = {
  type: ProfileFileUploadDeleteTypeEnum;
  useOnboardingLink: boolean;
  abortController?: AbortController;
};

const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const profileService = {
  getProfileData: () => axiosApiInstance.get<ProfileInfoType>(
    `${baseUrl}/api/v1/profile/`,
  ).then(({ data }) => data),

  patchProfileData: (newData: PatchProfileData) => axiosApiInstance.patch<ProfileInfoType>(
    `${baseUrl}/api/v1/profile/`, newData,
  ).then(({ data }) => data),

  uploadProfileFile: ({
    type,
    fileFormData,
    useOnboardingLink,
    onPercentageUpdate,
    abortController,
  }: FileUploadPayloadType) => axiosApiInstance.post<string>(
    `${baseUrl}/api/v1/${useOnboardingLink ? 'onboarding' : 'profile'}/file/${type}/`,
    fileFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = progressEvent.total
          ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
          : 0;
        onPercentageUpdate(percentCompleted);
      },
      signal: abortController?.signal,
    },
  ).then(({ data }) => data),

  deleteProfileFile: ({
    type,
    useOnboardingLink,
    abortController,
  }: FileDeletePayloadType) => axiosApiInstance.delete<void>(
    `${baseUrl}/api/v1/${useOnboardingLink ? 'onboarding' : 'profile'}/file/?type=${type}`,
    { signal: abortController?.signal },
  ).then(({ data }) => data),

  patchAdvisorInvitations: data => axiosApiInstance.post<AdvisorInvitation[]>(
    `${baseUrl}/api/v1/advisory-org/invite-advisors`,
    data,
  ).then(({ data }) => data),

  deleteAdvisor: email => axiosApiInstance.delete<void>(
    `${baseUrl}/api/v1/advisory-org/advisor?email=${email}`,
  ).then(({ data }) => data),
};
export default profileService;
