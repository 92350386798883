/* eslint-disable react/no-danger, jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */
import React, { memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowExternalUrlConsentModal } from 'redux/reducers/modal';
import { showExternalUrlConsentModalSelector } from 'redux/selectors/modal';
import { tenantSettingsSelector } from 'redux/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';


const classNames = { modalFooter: 'info_modal_footer', modalContent: 'info_modal_content', modalTitle: 'info_modal_title' };


const ExternalUrlConsentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(showExternalUrlConsentModalSelector);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const tenantSettings = useAppSelector(tenantSettingsSelector);

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowExternalUrlConsentModal(null));
  }, [dispatch]);

  const openExternalResource = useCallback(() => {
    document.getElementById('external-link-href')!.click();
    closeModal();
  }, [closeModal]);

  const { t } = useTenantTranslation();

  return (
    <BaseModal
      closeOnBackgroundClick
      withAcceptButton
      acceptButtonText={modalState?.acceptButtonText || t('misc.yes_continue')}
      acceptButtonSize={isPhone ? 'small' : 'large'}
      onAcceptButtonClick={openExternalResource}
      withDeclineButton
      declineButtonText={t('misc.no_stay_here')}
      declineButtonSize={isPhone ? 'small' : 'large'}
      declineButtonLook="filled-gray"
      onDeclineButtonClick={closeModal}
      onModalClose={closeModal}
      modalTitle={modalState?.title || t('misc.external_resource_confirmation_title')}
      classNames={classNames}
    >
      <p>
        <Trans
          ns={tenantSettings?.tenantTranslationPrefix}
          components={[
            <p key="0" />,
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a key="1" href="/advisors/list" aria-label="external resource link" />,
          ]}
        >
          {t('misc.external_resource_confirmation_text')}
        </Trans>
      </p>

      <a
        id="external-link-href"
        href={modalState?.externalUrl || ''}
        target={modalState?.target || ''}
        rel={modalState?.rel || ''}
      />
    </BaseModal>
  );
};

export default memo(ExternalUrlConsentModal);
