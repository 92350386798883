import misc from './misc.json';
import onboarding from './onboarding.json';
import auth from './auth.json';
import myAccount from './myAccount.json';
import advisor from './advisor.json';
import validation from './validation.json';
import time from './time.json';
import notifications from './notifications.json';
import funding from './funding.json';
import course from './course.json';


export const resourcesEnNXST = {
  misc,
  auth,
  onboarding,
  myAccount,
  advisor,
  validation,
  time,
  notifications,
  funding,
  course,
};
