import { createSlice } from '@reduxjs/toolkit';

import { CourseType } from '_types/courses.interface';


import { fetchNewHomeCoursesCards, fetchNewCoursesCards, fetchVendorList } from 'redux/actions/courses';


interface NewCoursesState {
  home: {
    isLoading: boolean,
    count: number | null,
    cards: CourseType[],
  },
  courses: {
    count: number,
    isLoading: boolean,
    cards: CourseType[],
  },
  vendorList: {
    isLoading: boolean,
    data: string[] | null,
  }
}

const coursesInitialState: NewCoursesState = {
  home: {
    count: null,
    isLoading: true,
    cards: [],
  },
  courses: {
    count: 0,
    isLoading: true,
    cards: [],
  },
  vendorList: {
    isLoading: false,
    data: null,
  },
};

const coursesSlice = createSlice({
  name: 'course',
  initialState: coursesInitialState,
  reducers: {
    resetState: () => coursesInitialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchNewCoursesCards.pending, state => {
      state.courses.isLoading = true;
      state.courses.cards = [];
      state.courses.count = 0;
    });
    builder.addCase(fetchNewCoursesCards.fulfilled, (state, action) => {
      state.courses.cards = action.payload.courses;
      state.courses.count = action.payload.totalItems;
      state.courses.isLoading = false;
    });
    builder.addCase(fetchNewCoursesCards.rejected, state => {
      state.courses.isLoading = false;
    });
    builder.addCase(fetchNewHomeCoursesCards.pending, state => {
      state.home.isLoading = true;
    });
    builder.addCase(fetchNewHomeCoursesCards.fulfilled, (state, action) => {
      state.home.count = action.payload.count;
      state.home.cards = action.payload.courses;
      state.home.isLoading = false;
    });
    builder.addCase(fetchNewHomeCoursesCards.rejected, state => {
      state.home.isLoading = false;
    });
    builder.addCase(fetchVendorList.pending, state => {
      state.vendorList.isLoading = true;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorList.data = action.payload;
      state.vendorList.isLoading = false;
    });
    builder.addCase(fetchVendorList.rejected, state => {
      state.vendorList.isLoading = false;
    });
  },
});

export default coursesSlice;
