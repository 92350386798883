import { createAsyncThunk } from '@reduxjs/toolkit';

import tenantSettingsService from '_services/tenantSettingsService';


export const fetchGetTenantSettings = createAsyncThunk(
  'user/fetchGetTenantSettings', (_, { rejectWithValue }) => {
    return tenantSettingsService
      .getTenantSettings()
      .catch(rejectWithValue);
  }
);
