import React, { memo } from 'react';


interface LoanDollarIconProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
}
const LoanDollarIcon: React.FC<LoanDollarIconProps> = ({
  className, color, width, height,
}) => (
  <svg className={className} width={width || '41'} height={height || '40'} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.422852" width="40" height="40" rx="20" fill={color || '#053B66'} />
    <path
      d="M24.2808 23.7653H14.4351V27.3456H18.9105V30.9258H22.4907V27.3456H24.2808C25.4677 27.3456 26.6061 26.8741 27.4453 26.0348C28.2846 25.1955 28.7561 24.0572 28.7561 22.8703C28.7561 21.6833 28.2846 20.545 27.4453 19.7057C26.6061 18.8665 25.4677 18.395 24.2808 18.395H17.1203C16.8829 18.395 16.6553 18.3007 16.4874 18.1328C16.3196 17.9649 16.2253 17.7373 16.2253 17.4999C16.2253 17.2625 16.3196 17.0348 16.4874 16.867C16.6553 16.6991 16.8829 16.6048 17.1203 16.6048H26.966V13.0246H22.4907V9.44434H18.9105V13.0246H17.1203C15.9334 13.0246 14.7951 13.4961 13.9558 14.3354C13.1165 15.1747 12.645 16.313 12.645 17.4999C12.645 18.6868 13.1165 19.8251 13.9558 20.6644C14.7951 21.5037 15.9334 21.9752 17.1203 21.9752H24.2808C24.5182 21.9752 24.7459 22.0695 24.9137 22.2374C25.0816 22.4052 25.1759 22.6329 25.1759 22.8703C25.1759 23.1076 25.0816 23.3353 24.9137 23.5032C24.7459 23.671 24.5182 23.7653 24.2808 23.7653Z"
      fill="white"
    />
  </svg>
);

export default memo(LoanDollarIcon);
