import React, {
  ReactNode, memo, useEffect, useMemo, useState
} from 'react';


// import { getAvailableIndustriesList } from '_constants/onboardingConstants';

import { ProfileFieldErrorType, ProfileFieldLocationType } from '_types/profile.interface';

import DropDownList from 'app/components/DropDownList/DropDownList';

import { useAppSelector } from 'store/configureStore';

import { onboardingQuestionsOptionsSelector } from 'redux/selectors/onboarding';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';

import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';


interface SboIndustrySelectComponentProps {
  onChange: (value: string) => void;
  errors?: ProfileFieldErrorType;
  value?: string;
  subTitle?: ReactNode;
  type?: ProfileFieldLocationType;
}

const SboIndustrySelectComponent: React.FC<SboIndustrySelectComponentProps> = ({
  onChange,
  errors,
  value,
  subTitle: subTitleToSetImplicitly,
  type,
}) => {
  const { t } = useTenantTranslation();
  const onboardingQuestions = useAppSelector(onboardingQuestionsOptionsSelector);
  // const availableIndustriesList = useMemo(() => getAvailableIndustriesList(t), [t]);
  const fieldId = useGoToFirstErrorId(['sboData.industry']);

  const [industryLists, setIndustryLists] = useState<{ name: string; value: string; }[]>([]);


  useEffect(() => {
    if (onboardingQuestions) {
      const manipulateListing = onboardingQuestions?.map((elem) => ({ name: t(elem?.name), value: elem?.value }));
      setIndustryLists(manipulateListing);
    }
  }, [t, onboardingQuestions]);

  const subTitle = useMemo(
    () => {
      if (subTitleToSetImplicitly) {
        return subTitleToSetImplicitly;
      }

      if (type === 'onboarding') {
        return t('onboarding.business_info.industry_text');
      }

      return undefined;
    }, [subTitleToSetImplicitly, type, t]
  );


  return (
    <ProfileFieldWrapper
      title={t('onboarding.business_info.industry_title')}
      subTitle={subTitle}
      addAsteriskToTitle
      id={fieldId}
    >
      <DropDownList
        placeholder={`${t('onboarding.business_info.industry_placeholder')}`}
        value={value || ''}
        onChange={onChange}
        availableValuesList={industryLists}
        errors={errors}
        isArrow
      />
    </ProfileFieldWrapper>
  );
};

export default memo(SboIndustrySelectComponent);
