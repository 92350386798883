import {
  MutableRefObject,
  RefObject,
  createContext,
  createRef,
} from 'react';
import { RecaptchaVerifier } from '@firebase/auth';


export interface RecaptchaContextInterface {
  recaptchaVerifierRef: MutableRefObject<RecaptchaVerifier | null>;
  recaptchaContainerRef: RefObject<HTMLDivElement | null>;
}

const RecaptchaContainerContext = createContext<RecaptchaContextInterface>({
  recaptchaVerifierRef: createRef(),
  recaptchaContainerRef: createRef(),
});

export default RecaptchaContainerContext;
