import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import App from 'app';
import GlobalErrorBoundary from 'app/components/GlobalErrorBoundary';

import { store } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages and new relic
import './utils/newRelic';
import './locales/i18n';
import FirebaseRemoteConfigInitialization from './utils/firebase/FirebaseRemoteConfigInitialization';


const MOUNT_NODE = document.getElementById('root') as HTMLElement;

createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <HelmetProvider>
      {/* <React.StrictMode> */}
      <CookiesProvider>
        <BrowserRouter>
          <GlobalErrorBoundary>
            <FirebaseRemoteConfigInitialization>
              <App />
            </FirebaseRemoteConfigInitialization>
          </GlobalErrorBoundary>
        </BrowserRouter>
      </CookiesProvider>
      {/* </React.StrictMode> */}
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
