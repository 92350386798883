import { useCallback, useEffect, useState } from 'react';


export const getLocalStorageValue = (key: string) => localStorage.getItem(key);

export const useValueFromLocalStorage = (key: string) => {
  const [value, setValue] = useState(getLocalStorageValue(key));

  const updateLocalStorageValue = useCallback(
    () => {
      setValue(getLocalStorageValue(key));
    }, [key]
  );

  useEffect(() => {
    window.addEventListener('storage', updateLocalStorageValue);

    return () => window.removeEventListener('storage', updateLocalStorageValue);
  }, [updateLocalStorageValue]);

  return value;
};

export const setLocalStorageValue = (key: string, value: string) => {
  localStorage.setItem(key, value);
  window.dispatchEvent(new Event('storage'));
};

export const removeLocalStorageValue = (key: string) => {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event('storage'));
};
