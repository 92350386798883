import React from 'react';
import clsx from 'clsx';

import './style.scss';


interface TooltipTriangleProps {
  fillColor?: string;
  borderColor?: string;
  className?: string;
}

const TooltipTriangle: React.FC<TooltipTriangleProps> = ({
  fillColor,
  borderColor,
  className,
}) => (
  <svg viewBox="0 0 1050 1050" fill="none" className={clsx('tooltip_triangle', className)}>
    <polygon
      points="25,1025 525,200 1025,1025"
      fill={fillColor || 'var(--v2-white)'}
      stroke={borderColor || 'var(--v2-neutral-shade-5)'}
      className="tooltip_triangle__triangle"
    />

    <line
      x1="0"
      y1="1025"
      x2="1050"
      y2="1025"
      stroke={fillColor || 'var(--v2-white)'}
      className="tooltip_triangle__bline"
    />
  </svg>
);

export default TooltipTriangle;
