import clsx from 'clsx';
import React, {
  memo,
  useCallback,
  useState,
  HTMLInputTypeAttribute,
} from 'react';
import {
  Field, FormikHandlers, FormikHelpers
} from 'formik';
import { DefaultTFuncReturn } from 'i18next';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ErrorCloud from 'app/components/ErrorCloud';
import CloseEyeIcon from 'app/components/Icons/CloseEyeIcon';
import EyeIcon from 'app/components/Icons/EyeIcon';
import BulletsRequiredList from 'app/components/BulletsRequiredList';

import './style.scss';


type BulletsRequiredListProps = {
  withBulletsRequiredList: true;
  bulletsRequiredTitle?: string;
  bulletsRequiredChecksList: string[] | DefaultTFuncReturn[];
  bulletsRequiredClassName?: string;
} | {
  withBulletsRequiredList?: false;
};

type FormikInputFieldProps = {
  type: HTMLInputTypeAttribute | undefined;
  name: string;
  placeholder: string;
  handleBlur: FormikHandlers['handleBlur'];
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  showErrorWhenInFocus?: boolean;
  className?: string;
  autocomplete?: boolean | string;
  disabled?: boolean;
  readonly?: boolean;
  errors?: ProfileFieldErrorType;
} & BulletsRequiredListProps;

const FormikInputField: React.FC<FormikInputFieldProps> = ({
  type,
  name,
  handleBlur,
  setFieldTouched,
  showErrorWhenInFocus,
  className,
  autocomplete,
  disabled,
  placeholder,
  readonly,
  errors: errorsFromProps,
  ...bulletsRequiredListProps
}) => {
  const [inputType, setInputType] = useState<HTMLInputTypeAttribute | undefined>(type);
  const [isInFocus, setIsInFocus] = useState<boolean>(false);

  const setPasswordVisible = useCallback(
    () => {
      if (inputType === 'password') {
        setInputType('text');
      } else {
        setInputType('password');
      }
    }, [inputType]
  );

  const onFocus = useCallback(() => {
    setFieldTouched(name);
    setIsInFocus(true);
  }, [name]);
  const onBlur = useCallback((e: any) => {
    handleBlur(e);
    setIsInFocus(false);
  }, []);

  const autoCompleteValue = typeof autocomplete === 'string'
    ? autocomplete
    : autocomplete ? 'on' : 'off';

  const errors = (!isInFocus || showErrorWhenInFocus)
    ? errorsFromProps
    : null;

  return (
    <div className={clsx('entry_field', className, { error: errors?.length })}>
      <Field
        type={inputType}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete={autoCompleteValue}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readonly}
      />

      {
        !!errors?.length
        && !bulletsRequiredListProps.withBulletsRequiredList
        && <ErrorCloud errorsListOrErrorText={errors} />
      }

      {bulletsRequiredListProps.withBulletsRequiredList && (
        <BulletsRequiredList
          errorsContainerTitle={bulletsRequiredListProps.bulletsRequiredTitle}
          checksList={bulletsRequiredListProps.bulletsRequiredChecksList}
          errorsList={errors}
          className={bulletsRequiredListProps.bulletsRequiredClassName}
        />
      )}

      {type === 'password' && (
        <div onClick={setPasswordVisible}>
          {inputType === 'password' ? <CloseEyeIcon className="eye" /> : <EyeIcon className="eye" />}
        </div>
      )}
    </div>
  );
};

export default memo(FormikInputField);
