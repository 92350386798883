import React, { memo } from 'react';


import AddButtonIcon from 'app/components/Icons/AddButtonIcon';
import BaseModal from 'app/components/Modals/BaseModal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import VideoPlayer from '../../VideoPlayer';

import './style.scss';


interface PreviewVideoModalProps {
  isOpen: boolean;
  videoUrl: string | undefined;
  onModalClose: () => void;
}

const PreviewVideoModal: React.FC<PreviewVideoModalProps> = ({ isOpen, videoUrl, onModalClose }) => {
  const { t } = useTenantTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal
      onModalClose={onModalClose}
      closeOnBackgroundClick
      classNames={{
        modalContainer: 'video_preview',
      }}
    >
      <div onClick={onModalClose}><AddButtonIcon className="video_preview__close" /></div>
      { videoUrl ? (
        <VideoPlayer url={videoUrl} />
      ) : (
        <p>{t('misc.video_url_not_specified')}</p>
      )}
    </BaseModal>
  );
};

export default memo(PreviewVideoModal);
