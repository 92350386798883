import React, { memo, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { ProfileAdvisor } from '_types/profile.interface';

import { MyAdvisorProfileErrorsType } from 'app/pages/MyAccount/components/MyProfile/MyAdvisorProfile/validationSchemas';
import AdvisorIndustriesSelectComponent from 'app/components/ProfileFields/advisor/AdvisorIndustriesSelectComponent';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { profileErrorsSelector, profileInfoSelector } from 'redux/selectors/profile';
import { actionChangeProfileMissingDataValidationErrors, actionEditAdvisor } from 'redux/reducers/profile';
import { showProfileMissingDataModalSelector } from 'redux/selectors/modal';

import './styles.scss';


const AdvisorMissingDataForm: React.FC = () => {
  const errors = useAppSelector(profileErrorsSelector<MyAdvisorProfileErrorsType>);
  const showProfileMissingDataModal = useAppSelector(showProfileMissingDataModalSelector);

  const dispatch = useAppDispatch();

  const profileInfo = useAppSelector(profileInfoSelector);

  const onChangeAdvisorData = useCallback(
    (key: keyof ProfileAdvisor) => (value: unknown) => {
      dispatch(actionEditAdvisor({ [key]: value }));

      if (errors.advisorData?.industries) {
        const newErrors = cloneDeep(errors);

        if (Object.keys(errors.advisorData).length === 1) {
          delete newErrors.advisorData;
        } else {
          delete newErrors.advisorData?.industries;
        }

        dispatch(actionChangeProfileMissingDataValidationErrors(newErrors));
      }
    },
    [dispatch],
  );

  const findMissingField = useCallback((field: string) => {
    return showProfileMissingDataModal?.missingFields.find((value) => value === field);
  }, [showProfileMissingDataModal?.missingFields]);

  return (
    <div>
      {
        findMissingField('advisorData.industries') && (
          <AdvisorIndustriesSelectComponent
            onChange={onChangeAdvisorData('industries')}
            errors={errors.advisorData?.industries}
            value={profileInfo?.advisorData?.industries}
            titleClassName="field_name no_sub_title"
          />
        )
      }
    </div>
  );
};

export default memo(AdvisorMissingDataForm);
