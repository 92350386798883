import { resourcesEnDefault } from './default';
import { resourcesEnNycFundsFinder } from './nyc_funds_finder';
import { resourcesEnNXST } from './nxst';
import { resourcesEnVerizon } from './verizon';


export const resourcesEn = {
  default: resourcesEnDefault,
  nxst: resourcesEnNXST,
  nyc_funds_finder: resourcesEnNycFundsFinder,
  verizon: resourcesEnVerizon,
};
