import React, { memo } from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { MdOutlineAccountBalance } from 'react-icons/md';

import { phoneConstant } from '_constants/otherConstants';

import { GrantItemType } from '_types/funding.interface';

import FundingCardTag from 'app/components/FundingCardTag';

import { useAppSelector } from 'store/configureStore';

import { showFundingExpandedViewModalSelector } from 'redux/selectors/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { formatMoneyWithComma, processFundingValues } from 'utils/funding';
import useTenantColors from 'utils/hooks/useTenantColors';
import { useCurrentPlatformLanguage } from 'utils/translations';

import './style.scss';


const GrantCardExpandedView: React.FC = () => {
  const { t } = useTenantTranslation();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const { colorVariables } = useTenantColors();
  const currentLanguage = useCurrentPlatformLanguage();

  const funding = useAppSelector(showFundingExpandedViewModalSelector);
  const fundingType = funding?.fundingType;
  const fundingValue = funding?.fundingValue;
  let grantValue!: GrantItemType;
  if (fundingType === 'Grant' && fundingValue) {
    grantValue = (fundingValue as GrantItemType);
  }
  const loanStyle = {
    backgroundColor: grantValue.mainColor,
    color: grantValue.textColor,
  };


  return (
    <div className="grant_card_modal_container">
      <div className="tags">
        {/* {(grantValue.communityServed && grantValue.communityServed.length > 0) && <p>{t('funding.ideal_for')}</p>} */}
        <FundingCardTag communityServed={grantValue.communityServed || []} />
      </div>

      <div className="card">
        <div className="card_header" style={loanStyle}>
          <div className="card_header_icon">
            <MdOutlineAccountBalance size={24} color={colorVariables?.icons.icon_secondary_tetriary_button} />
          </div>
          <div className="card_header_text">{grantValue?.grantName[currentLanguage]}</div>
        </div>

        <div className="grant_body">
          <div className="card_item">
            <p>{t('funding.grant_expanded_view.grant_size')}</p>
            <p>{processFundingValues(grantValue.minGrantSize, grantValue.maxGrantSize, 'money', t)}</p>
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.where')}</p>
            <p>{grantValue?.eligibilityGeography?.[currentLanguage]?.[0]}</p>
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.application_deadline')}</p>
            <p>
              {
                grantValue?.applicationDeadline
                  ? moment(grantValue?.applicationDeadline).format('MMM D, YYYY')
                  : t('funding.rolling')
              }
            </p>
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.application_fees')}</p>
            <p>{grantValue.applicationFee ? formatMoneyWithComma(grantValue.applicationFee) : t('misc.none')}</p>
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.ideal_sectors')}</p>
            <p>{grantValue?.sector[currentLanguage] || t('misc.none')}</p>
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.use_of_grant')}</p>
            <p>{grantValue?.useOfGrant[currentLanguage] || t('misc.none')}</p>
            {/* {grantValue?.useOfGrant ? (
              <ul>
                <li>{grantValue?.useOfGrant}</li>
              </ul>
            ) : (
              <p>{t('misc.none')}</p>
            )} */}
          </div>

          <div className="card_item">
            <p>{t('funding.grant_expanded_view.eligibility_criteria')}</p>

            {grantValue?.eligibility?.[currentLanguage] ? (
              <ul>
                {(
                  Array.isArray(grantValue?.eligibility?.[currentLanguage])
                  && grantValue?.eligibility[currentLanguage])
                  && grantValue?.eligibility[currentLanguage]!.map((el, index) => {
                    return (
                      <li key={index}>{el}</li>
                    );
                  })}
              </ul>
            ) : (
              <p>{t('misc.none')}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GrantCardExpandedView);
