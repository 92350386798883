import {
  array, object, string
} from 'yup';

import { NewYorkStateValue } from '_constants/onboardingConstants';


export const advisoryOrgAndAdvisorValidationSchema = object({
  advisorData: object({
    industries: array()
      .of(string())
      .required('validation.field_required')
      .min(1, 'validation.at_least_one_element_chosen')
      .compact(),
  }),
});

export const sboValidationSchema = object({
  sboData: object({
    industry: string().nullable().required('validation.field_required'),
    businessState: string().nullable(),
    NYCBorough: string()
      .nullable()
      .when('businessState', {
        is: NewYorkStateValue,
        then: schema => schema.required('validation.field_required'),
      }),
  }),
});

export const advisorValidationSchema = object({
  advisorData: object({
    industries: array()
      .of(string())
      .required('validation.field_required')
      .min(1, 'validation.at_least_one_element_chosen')
      .compact(),
  }),
});

export const advisoryOrgOnlyValidationSchema = object({});
