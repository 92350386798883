import { createSlice } from '@reduxjs/toolkit';

import {
  GrantItemType, LoanItemType, LoanFilterSettings, GrantFilterSettings
} from '_types/funding.interface';
import { PartialPlatformLanguagesMap } from '_types/utils.interface';

import {
  fetchGetLoansCards, fetchGetGrantCards, fetchGetProductTypes, fetchLoanFilterSettings,
  fetchGrantFilterSettings
} from 'redux/actions/funding';


interface FundingState {
  loansCount: number;
  loansList: LoanItemType[];
  grantsCount: number;
  grantsList: GrantItemType[];
  isLoading: boolean;
  availableProductTypes: PartialPlatformLanguagesMap<string[]> | null;
  loanFilterDetails: LoanFilterSettings;
  isLoanFilterDetailsLoading: boolean,
  grantFilterDetails: GrantFilterSettings
  isGrantFilterDetailsLoading: boolean,
}

const fundingInitialState: FundingState = {
  loansCount: 0,
  loansList: [],
  grantsCount: 0,
  grantsList: [],
  isLoading: true,
  availableProductTypes: null,
  loanFilterDetails: {} as LoanFilterSettings,
  isLoanFilterDetailsLoading: false,
  grantFilterDetails: {} as GrantFilterSettings,
  isGrantFilterDetailsLoading: false,
};

const fundingSlice = createSlice({
  name: 'funding',
  initialState: fundingInitialState,
  reducers: {
    resetState: () => fundingInitialState,
    resetLoanState: (state) => {
      state.loansCount = 0;
      state.loansList = [];
    },
    resetGrantState: (state) => {
      state.grantsCount = 0;
      state.grantsList = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGetLoansCards.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetLoansCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loansCount = action.payload.count;
      state.loansList = action.payload.list;
    });
    builder.addCase(fetchGetLoansCards.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchGetGrantCards.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchGetGrantCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.grantsCount = action.payload.count;
      state.grantsList = action.payload.list;
    });
    builder.addCase(fetchGetGrantCards.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(fetchGetProductTypes.fulfilled, (state, action) => {
      state.availableProductTypes = action.payload.productTypes;
    });
    builder.addCase(fetchLoanFilterSettings.pending, state => {
      state.isLoanFilterDetailsLoading = true;
    });
    builder.addCase(fetchLoanFilterSettings.fulfilled, (state, action) => {
      state.isLoanFilterDetailsLoading = false;
      state.loanFilterDetails = action.payload;
    });
    builder.addCase(fetchLoanFilterSettings.rejected, (state, _action) => {
      state.isLoanFilterDetailsLoading = false;
    });

    builder.addCase(fetchGrantFilterSettings.pending, state => {
      state.isGrantFilterDetailsLoading = true;
    });
    builder.addCase(fetchGrantFilterSettings.fulfilled, (state, action) => {
      state.isGrantFilterDetailsLoading = false;
      state.grantFilterDetails = action.payload;
    });
    builder.addCase(fetchGrantFilterSettings.rejected, (state, _action) => {
      state.isGrantFilterDetailsLoading = false;
    });
  },
});

export default fundingSlice;
