import { resourcesEsDefault } from './default';
import { resourcesEsNycFundsFinder } from './nyc_funds_finder';
import { resourcesEsVerizon } from './verizon';
import { resourcesEsNXST } from './nxst';


export const resourcesEs = {
  default: resourcesEsDefault,
  nxst: resourcesEsNXST,
  verizon: resourcesEsVerizon,
  nyc_funds_finder: resourcesEsNycFundsFinder,
};
