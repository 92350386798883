import React, { memo } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { useAppSelector } from 'store/configureStore';

import { isProfileInfoPresentSelector } from 'redux/selectors/profile';

import { useCurrentAccessToken } from 'utils/firebase/hooks';


const AuthRequiredRoutesWrapper: React.FC = () => {
  const location = useLocation();

  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);

  const currentAccessToken = useCurrentAccessToken();

  if (!currentAccessToken || !isProfileInfoPresent) {
    const encodedUrl = encodeURIComponent(`${location.pathname}${location.search}`);
    return <Navigate to={`/auth/login?next=${encodedUrl}`} />;
  }

  return <Outlet />;
};

export default memo(AuthRequiredRoutesWrapper);
