
import React, { memo, useRef } from 'react';
import ReactPlayer from 'react-player';

import './style.scss';


interface VideoPlayerProps {
  url: string;
  width?: number | string;
  height?: number | string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  height,
  width,
}) => {
  const videoRef = useRef<any>();

  return (
    <div className="video_player">
      <ReactPlayer
        url={url}
        pip
        loop
        ref={videoRef}
        config={{
          file: {
            attributes: {
              disablepictureinpicture: 'false',
              controlsList: ' nodownload noremoteplayback noplaybackrate',
            },
          },
        }}
        controls
        width={width || '100%'}
        height={height || '495px'}
      />
    </div>
  );
};

export default memo(VideoPlayer);
