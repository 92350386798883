import type {
  AdvisorInvitationFull,
  SetUserCookieConsentRequestBody,
  SetUserCookieConsentResponseBody,
  TenantLegalDocumentTypeToLatestAcceptDto,
} from '_types/profile.interface';

// eslint-disable-next-line import/no-cycle
import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const UserService = {
  registrationSbo: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/sbo/signup`, query)
    .then(({ data }) => data),
  registrationAdvisoryOrgAdmin: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/advisory-org/signup`, query)
    .then(({ data }) => data),
  registrationAdvisor: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/advisor/signup`, query)
    .then(({ data }) => data),
  getUser: () => axiosApiInstance
    .get(`${baseUrl}/api/v1/users`)
    .then(({ data }) => data),
  updateUser: query => axiosApiInstance
    .patch(`${baseUrl}/api/v1/users`, query)
    .then(({ data }) => data),
  getAdvisoryOrgAdminInvitation: (token: string) => axiosApiInstance
    .get<AdvisorInvitationFull>(`${baseUrl}/api/v1/advisory-org-admin-invitation/${token}`)
    .then(({ data }) => data),
  getAdvisorInvitation: (token: string) => axiosApiInstance
    .get<AdvisorInvitationFull>(`${baseUrl}/api/v1/advisor-invitation/${token}`)
    .then(({ data }) => data),
  resetPasswordSendEmail: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/reset-password/send-email`, query)
    .then(({ data }) => data),
  resetPasswordValidateToken: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/reset-password/validate-token`, query)
    .then(({ data }) => data),
  resetPassword: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/reset-password`, query)
    .then(({ data }) => data),
  changeEmail: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/users/change-email`, query)
    .then(({ data }) => data),
  changePassword: query => axiosApiInstance
    .post(`${baseUrl}/api/v1/users/change-password`, query)
    .then(({ data }) => data),
  canLoginOnTenant: (email, password) => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/can-login-on-tenant`, { email, password })
    .then(({ data }) => data),
  loginWithInvalidCredentials: (email) => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/login-with-invalid-credentials`, { email })
    .then(({ data }) => data),
  onLoginActions: (idToken: string) => axiosApiInstance
    .get(`${baseUrl}/api/v1/auth/on-login-actions`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    .then(({ data }) => data),
  setCookieConsent: (body: SetUserCookieConsentRequestBody) => axiosApiInstance
    .post<SetUserCookieConsentResponseBody>(`${baseUrl}/api/v1/users/cookie-consent`, body)
    .then(({ data }) => data),
  sendVerificationEmail: () => axiosApiInstance
    .post(`${baseUrl}/api/v1/auth/email-verification/send`)
    .then(({ data }) => data),
  acceptMultipleTenantLegalDocuments: (documentIdsToAccept: number[]) => axiosApiInstance
    .post<TenantLegalDocumentTypeToLatestAcceptDto>(
      `${baseUrl}/api/v1/users/tenant-legal-document-accept/accept-multiple`,
      { documentIdsToAccept },
    )
    .then(({ data }) => data),
};

export default UserService;
