import clsx from 'clsx';
import React, { memo } from 'react';
import isArray from 'lodash/isArray';

import { ProfileFieldErrorType } from '_types/profile.interface';

import ErrorCloudErrorTextLine from './ErrorCloudErrorTextLine';

import './style.scss';


export type ErrorCloudProps = {
  errorsListOrErrorText: ProfileFieldErrorType | ProfileFieldErrorType[];
  className?: string;
  pointerDirection?: boolean;
};

const ErrorCloud: React.FC<ErrorCloudProps> = ({
  errorsListOrErrorText,
  className,
  pointerDirection = true,
}) => {
  if (!errorsListOrErrorText) {
    return null;
  }

  const errorsList = isArray(errorsListOrErrorText) ? errorsListOrErrorText : [errorsListOrErrorText];

  return (
    <div className={clsx('error_cloud', { error: errorsListOrErrorText?.length }, className)}>
      <div className={clsx('error_cloud__triangle', {
        top: pointerDirection,
        bottom: !pointerDirection,
      })}
      >
        <div />
        <div />
        <div />
      </div>

      {
        errorsList?.length > 1 ? (
          <ul className="error_cloud__bullet_list">
            {errorsList.map((error, index) => (
              <li key={index}>
                <ErrorCloudErrorTextLine error={error} />
              </li>
            ))}
          </ul>
        ) : !!errorsList?.length && <ErrorCloudErrorTextLine error={errorsList[0]} />
      }
    </div>
  );
};

export default memo(ErrorCloud);
