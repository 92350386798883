/* eslint-disable react-hooks/rules-of-hooks */
import {
  useContext,
  useLayoutEffect
} from 'react';
import { uniqueId } from 'lodash';

import type { DottedProfileFieldIdsPath } from '_types/profile.interface';

import ProfileFieldIdsContext from '.';


const fieldIdPrefix = 'field-';

const useGoToFirstErrorId = (fieldPathsToAttach: DottedProfileFieldIdsPath[]) => {
  // fieldPathsToAttach - a list of paths (normally one) from the field data path
  // to be used as a key to store and access the ref for moving to the top errored element on page.
  // In case multiple are specified, then any of them having an error will trigger the scroll to the ref
  // in case it's the most top ref being shown.
  const { addProfileFieldId, profileFieldIds } = useContext(ProfileFieldIdsContext);

  const firstPresentPath = fieldPathsToAttach.find(
    path => path in profileFieldIds,
  );

  let id: string;

  if (firstPresentPath) {
    id = profileFieldIds[firstPresentPath]!;
  } else {
    id = uniqueId(fieldIdPrefix);
  }

  useLayoutEffect(
    () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const path of fieldPathsToAttach) {
        if (!(path in profileFieldIds) || profileFieldIds[path] !== id) {
          addProfileFieldId(path, id);
        }
      }
    }, [id, profileFieldIds, fieldPathsToAttach]
  );

  return id;
};

export default useGoToFirstErrorId;
