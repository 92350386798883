/* eslint-disable max-len */
import { PageName, PlatformPageData } from '_types/tenant-settings.interface';

import { RootState } from 'store/configureStore';


export const tenantSettingsSelector = (state: RootState) => state.tenantSettings.tenantSettings;
export const tenantSettingsPresentSelector = (state: RootState) => !!state.tenantSettings.tenantSettings;
export const tenantSettingsLanguagesAvailableSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.languagesAvailable;
export const tenantSettingsTenantStylingSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.tenantStyling;
export const tenantSettingsTenantFeatureFlagsSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.tenantFeatureFlags;
export const tenantSettingsLmsSettingsSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.lmsSettings;
export const tenantSettingsLoaderSelector = (state: RootState) => state.tenantSettings.loader;
export const tenantSettingsTenantNameSelector = (state: RootState) => state.tenantSettings.tenantSettings?.tenantName;
export const tenantSettingsTenantTypeSelector = (state: RootState) => state.tenantSettings.tenantSettings?.tenantType;
export const tenantSettingsZendeskSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.zendeskSettings;
export const tenantSettingsPageAttributesSelector = (
  pageName: PageName,
) => (
  state: RootState,
) => state.tenantSettings.tenantSettings?.pageAttributes?.pageData?.[pageName] || {} as PlatformPageData;
export const tenantSettingsFavIconSelector = (state: RootState) => state.tenantSettings.tenantSettings?.pageAttributes?.favIcon;
export const tenantSettingsSboBusinessNeedsAvailableSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.sboBusinessNeedsAvailable;
export const tenantSettingsAdvisorSkillsAvailableSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.advisorSkillsAvailable;
export const tenantSettingsLatestLegalDocumentsSelector = (
  state: RootState,
) => state.tenantSettings.tenantSettings?.latestTenantLegalDocuments;
