import React, { memo, useMemo } from 'react';


import { useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'redux/selectors/profile';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import NotificationBlock from '../NotificationBlock';

import {
  getContentBlock,
  getLinkToObject,
  getTitleBlock,
} from './utils';
import { SessionDataNotificationNotificationObject, SessionDataNotificationTypeEnum } from './types';


interface SessionDataNotificationProps {
  notification: SessionDataNotificationNotificationObject;
  sessionNotificationType: SessionDataNotificationTypeEnum;
}

const SessionDataNotification: React.FC<SessionDataNotificationProps> = ({
  notification,
  sessionNotificationType,
}) => {
  const { t } = useTenantTranslation();

  const profileRole = useAppSelector(profileRoleSelector);

  const titleBlock = useMemo(
    () => getTitleBlock(sessionNotificationType, notification.data.userMentioned, t),
    [sessionNotificationType, notification.data.userMentioned, t]
  );

  const contentBlock = useMemo(
    () => getContentBlock(notification.data.session, t),
    [notification.data.session, t]
  );

  const linkToObject = useMemo(
    () => getLinkToObject(
      notification.data.session,
      profileRole!,
      t,
    ),
    [notification.data.session, profileRole, t]
  );

  return <NotificationBlock
    notificationId={notification.id}
    isRead={notification.isRead}
    createdAtUnix={notification.createdAtUnix}
    logoUser={notification.data.userMentioned}
    titleBlock={titleBlock}
    contentBlock={contentBlock}
    linkToObject={linkToObject}
  />;
};

export default memo(SessionDataNotification);
