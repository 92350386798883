import React, { memo } from 'react';


interface PlayIconProps {
  className?: string;
}
const PlayIcon: React.FC<PlayIconProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none">
    <path fill="#676767" fillRule="evenodd" d="M6.5 12.9A6.4 6.4 0 1 0 6.5.1a6.4 6.4 0 0 0 0 12.8Zm-.4-8.7A.8.8 0 0 0 5 5v3.2a.8.8 0 0 0 1.2.7l2.4-1.6a.8.8 0 0 0 0-1.4L6.1 4.2Z" clipRule="evenodd" />
  </svg>
);

export default memo(PlayIcon);
