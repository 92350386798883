import React, { memo } from 'react';


interface TwitterIconProps {
  className?: string;
}
const TwitterIcon: React.FC<TwitterIconProps> = ({ className }) => (
  <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.3333 0H6.66667C2.98533 0 0 2.98533 0 6.66667V25.3333C0 29.0147 2.98533 32 6.66667 32H25.3333C29.016 32 32 29.0147 32 25.3333V6.66667C32 2.98533 29.016 0 25.3333 0ZM25.148 12.316C25.4267 18.472 20.836 25.336 12.708 25.336C10.236 25.336 7.936 24.612 5.99867 23.3693C8.32133 23.6427 10.6387 22.9987 12.4787 21.5573C10.5627 21.5213 8.94667 20.256 8.39067 18.5173C9.07733 18.648 9.752 18.6093 10.3667 18.4427C8.26133 18.02 6.80933 16.124 6.856 14.096C7.44533 14.424 8.12133 14.6213 8.83733 14.644C6.88933 13.3413 6.33733 10.768 7.48267 8.8C9.64133 11.448 12.8667 13.1907 16.504 13.3733C15.8653 10.636 17.944 8 20.7693 8C22.0267 8 23.1653 8.53067 23.9627 9.38267C24.96 9.18667 25.8973 8.82267 26.744 8.32133C26.416 9.344 25.7227 10.2013 24.82 10.7427C25.7053 10.636 26.5493 10.4013 27.3333 10.0533C26.748 10.928 26.0053 11.6987 25.148 12.316Z" fill="#1DA1F2" />
  </svg>

);

export default memo(TwitterIcon);
