import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import Button from 'app/components/Button';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionUnreadNotificationsSeen } from 'redux/reducers/notification';
import {
  isLoadingReadNotificationsSelector,
  readNotificationsSelector,
  unreadNotificationsSelector,
} from 'redux/selectors/notification';
import { fetchReadNotifications } from 'redux/actions/notification';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import SocketContext from 'utils/contexts/SocketContext';
import { notificationReadMultiple } from 'utils/socket/emitters/notifications';

import UnreadNotificationsEmptyComponent from './components/UnreadNotificationsEmptyComponent';
import { getFullyVisibleNotificationIds, renderNotification } from './utils';


const readNotificationsPageSize = 5;

const NotificationsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();

  const unreadNotifications = useAppSelector(unreadNotificationsSelector);
  const readNotifications = useAppSelector(readNotificationsSelector);
  const isLoadingReadNotifications = useAppSelector(isLoadingReadNotificationsSelector);

  const { socket, saveMessageOnSocketNotConnected } = useContext(SocketContext);

  const onLoadMoreReadNotifications = useCallback(
    async () => {
      const offset = readNotifications ? readNotifications.offset + readNotificationsPageSize : 0;
      const limit = readNotificationsPageSize;

      try {
        await dispatch(fetchReadNotifications({ offset, limit })).unwrap();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occurred during read notifications fetch:', e);
      }
    }, [readNotifications?.offset]
  );

  const onMarkAllAsRead = useCallback(
    () => {
      if (!unreadNotifications.length) {
        return;
      }

      const unreadNotificationsIds = unreadNotifications
        .filter(({ isRead }) => !isRead)
        .map(({ id }) => id);

      notificationReadMultiple(
        socket,
        saveMessageOnSocketNotConnected,
        unreadNotificationsIds,
      );
      dispatch(actionUnreadNotificationsSeen(unreadNotificationsIds));
    }, [unreadNotifications, socket, saveMessageOnSocketNotConnected]
  );

  const markVisibleNotificationsAsRead = useCallback(
    () => {
      const unreadNotificationsExists = !!unreadNotifications.find(n => !n.isRead);
      if (!unreadNotificationsExists) {
        return;
      }

      const fullyVisibleNotificationIds = new Set(getFullyVisibleNotificationIds());

      const anyNotificationShouldBeChanged = !!unreadNotifications
        .find(unread => fullyVisibleNotificationIds.has(unread.id) && !unread.isRead);
      if (!anyNotificationShouldBeChanged) {
        return;
      }

      const unreadNotificationsIdsSeen = unreadNotifications
        .filter(unread => fullyVisibleNotificationIds.has(unread.id) && !unread.isRead)
        .map(({ id }) => id);

      notificationReadMultiple(
        socket,
        saveMessageOnSocketNotConnected,
        unreadNotificationsIdsSeen,
      );
      dispatch(actionUnreadNotificationsSeen(unreadNotificationsIdsSeen));
    }, [unreadNotifications, getFullyVisibleNotificationIds, socket, saveMessageOnSocketNotConnected]
  );

  const getCanLoadMoreReadNotifications = useCallback(
    () => {
      if (!readNotifications) {
        return true;
      }

      const nextOffsetWillBe = readNotifications.offset + readNotificationsPageSize;

      return nextOffsetWillBe < readNotifications.count;
    }, [readNotifications, readNotificationsPageSize]
  );

  const unreadNotificationComponents = useMemo(
    () => {
      if (!unreadNotifications.length) {
        return [<UnreadNotificationsEmptyComponent key={0} />];
      }

      return unreadNotifications.map(renderNotification);
    }, [unreadNotifications]
  );

  const readNotificationComponents = useMemo(
    () => {
      if (!readNotifications?.notifications.length) {
        return [];
      }

      return readNotifications.notifications.map(renderNotification);
    }, [readNotifications]
  );

  useEffect(
    () => {
      markVisibleNotificationsAsRead();
    }, [unreadNotifications]
  );

  return (
    <div
      className="profile_notifications__container"
      onScroll={markVisibleNotificationsAsRead}
      id="profile-notifications-container"
    >
      <div className="profile_notifications__container__inner">
        <div className="profile_notifications__container__inner__top">
          <p className="profile_notifications__container__inner__top__title">
            {t('notifications.new_for_you')}
          </p>
          <Button
            type="link"
            btnLook="text"
            size="large"
            value={t('notifications.mark_all_as_read')}
            onClick={onMarkAllAsRead}
            className="profile_notifications__container__inner__top__markread"
          />
        </div>

        {unreadNotificationComponents}
        {readNotificationComponents}
      </div>

      <div className="profile_notifications__container__footer">
        <Button
          type="btn"
          btnLook="filled"
          size="large"
          isLoading={isLoadingReadNotifications}
          value={t('notifications.view_previous_notifications')}
          disabled={isLoadingReadNotifications || !getCanLoadMoreReadNotifications()}
          onClick={onLoadMoreReadNotifications}
        />
      </div>
    </div>
  );
};

export default memo(NotificationsContainer);
