import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const ssoService = {
  getSamlResponse: () => {
    return axiosApiInstance
      .post(
        `${baseUrl}/api/v1/sso/login`,
      )
      .then(({ data }) => data);
  },
};

export default ssoService;
