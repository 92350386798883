import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { TFunction } from 'i18next';


const secondsInMinute = 60;
const secondsInHour = secondsInMinute * 60;
const secondsInDay = secondsInHour * 24;

const getTimeAgoTranslated = (createdAtUnix: number, t: TFunction) => {
  const secondsAgo = moment().unix() - createdAtUnix;

  if (secondsAgo < secondsInMinute) {
    return t('time.less_than_minute_ago');
  }

  if (secondsAgo < secondsInHour) {
    const minutesAgo = Math.floor(secondsAgo / secondsInMinute);
    return t('time.n_minutes_ago', { count: minutesAgo });
  }

  if (secondsAgo < secondsInDay) {
    const hoursAgo = Math.floor(secondsAgo / secondsInHour);
    return t('time.n_hours_ago', { count: hoursAgo });
  }

  const daysAgo = Math.floor(secondsAgo / secondsInDay);
  return t('time.n_days_ago', { count: daysAgo });
};

export const useTimeAgoTranslated = (createdAtUnix: number, t: TFunction) => {
  const [timeAgoTranslated, setTimeAgoTranslated] = useState<null | string>(null);

  const setTime = useCallback(
    () => {
      const newTimeAgoTranslated = getTimeAgoTranslated(createdAtUnix, t);
      setTimeAgoTranslated(newTimeAgoTranslated);
    }, [createdAtUnix, t]
  );

  useEffect(
    () => {
      setTime();
      const interval = setInterval(setTime, 1000);
      return () => { clearInterval(interval); };
    }, [setTime]
  );

  return timeAgoTranslated;
};
