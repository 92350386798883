import React, { memo } from 'react';


interface CrossIconProps {
  className?: string;
}
const CrossIcon: React.FC<CrossIconProps> = ({ className }) => (

  <svg className={className} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.292787 1.29308C0.480314 1.10561 0.734622 1.00029 0.999786 1.00029C1.26495 1.00029 1.51926 1.10561 1.70679 1.29308L5.99979 5.58608L10.2928 1.29308C10.385 1.19757 10.4954 1.12139 10.6174 1.06898C10.7394 1.01657 10.8706 0.988985 11.0034 0.987831C11.1362 0.986677 11.2678 1.01198 11.3907 1.06226C11.5136 1.11254 11.6253 1.18679 11.7192 1.28069C11.8131 1.37458 11.8873 1.48623 11.9376 1.60913C11.9879 1.73202 12.0132 1.8637 12.012 1.99648C12.0109 2.12926 11.9833 2.26048 11.9309 2.38249C11.8785 2.50449 11.8023 2.61483 11.7068 2.70708L7.41379 7.00008L11.7068 11.2931C11.8889 11.4817 11.9897 11.7343 11.9875 11.9965C11.9852 12.2587 11.88 12.5095 11.6946 12.6949C11.5092 12.8803 11.2584 12.9855 10.9962 12.9878C10.734 12.99 10.4814 12.8892 10.2928 12.7071L5.99979 8.41408L1.70679 12.7071C1.51818 12.8892 1.26558 12.99 1.00339 12.9878C0.741188 12.9855 0.490376 12.8803 0.304968 12.6949C0.11956 12.5095 0.0143906 12.2587 0.0121121 11.9965C0.00983372 11.7343 0.110629 11.4817 0.292787 11.2931L4.58579 7.00008L0.292787 2.70708C0.105316 2.51955 0 2.26525 0 2.00008C0 1.73492 0.105316 1.48061 0.292787 1.29308Z" fill="white" />
  </svg>
);

export default memo(CrossIcon);
