import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  OnboardingData, OnboardingProgress, OnboardingPageData, OnboardingQuestions
} from '_types/onboarding.interface';

import {
  fetchGetOnboardingData,
  fetchGetOnboardingPages,
  fetchGetOnboardingProgress,
  fetchGetOnboardingQuestion,
  fetchPutOnboardingData,
  fetchPutOnboardingProgress,
} from 'redux/actions/onboarding';


interface OnboardingState {
  onboardingPagesData: OnboardingPageData[] | null;
  onboardingData: OnboardingData | null;
  onboardingProgress: OnboardingProgress | null;
  onboardingQuestions: OnboardingQuestions | null;
  error: any;
  dataLoader: boolean;
  pagesLoader: boolean;
  requestErrors: Record<string, any> | null;
}

const onboardingInitialState: OnboardingState = {
  onboardingPagesData: null,
  onboardingData: null,
  onboardingProgress: null,
  onboardingQuestions: null,
  error: '',
  requestErrors: null,
  dataLoader: false,
  pagesLoader: false,
};

const setError = (state: OnboardingState, action: PayloadAction<any>) => {
  state.dataLoader = false;
  state.error = action.payload;
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: onboardingInitialState,
  reducers: {
    resetState: () => onboardingInitialState,
    actionSetOnboardingData(state, action: PayloadAction<OnboardingData>) {
      state.onboardingData = action.payload;
      state.error = '';
    },
    actionEditOnboardingData(state, action: PayloadAction<Partial<OnboardingData>>) {
      state.onboardingData = {
        ...state.onboardingData!,
        ...action.payload,
      };
      state.error = '';
    },
    actionSetOnboardingRequestErrors(state, action: PayloadAction<Record<string, any> | null>) {
      state.requestErrors = action.payload;
    },
    actionEditOnboardingAdvisor(state, action: PayloadAction<Partial<OnboardingData['advisorData']>>) {
      state.onboardingData = {
        ...state.onboardingData!,
        advisorData: {
          ...state.onboardingData!.advisorData!,
          ...action.payload,
        },
      };
    },
    actionClearOnboardingErrors(state) {
      state.error = '';
      state.requestErrors = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGetOnboardingPages.pending, (state, _action) => {
      state.pagesLoader = true;
      state.error = '';
    });
    builder.addCase(fetchGetOnboardingPages.fulfilled, (state, action) => {
      state.pagesLoader = false;
      state.onboardingPagesData = action.payload;
    });
    builder.addCase(fetchGetOnboardingPages.rejected, (state, action) => {
      state.pagesLoader = false;
      state.error = action.payload;
    });
    builder.addCase(fetchGetOnboardingData.pending, (state, _action) => {
      state.dataLoader = true;
      state.error = '';
    });
    builder.addCase(fetchGetOnboardingData.fulfilled, (state, action) => {
      state.dataLoader = false;
      state.onboardingData = action.payload;
    });
    builder.addCase(fetchGetOnboardingData.rejected, setError);
    builder.addCase(fetchPutOnboardingData.pending, (state, _action) => {
      state.dataLoader = true;
      state.error = '';
    });
    builder.addCase(fetchPutOnboardingData.fulfilled, (state, action) => {
      state.dataLoader = false;
      state.error = '';
      state.onboardingData = { ...state.onboardingData, ...action.payload };
    });
    builder.addCase(fetchPutOnboardingData.rejected, setError);
    builder.addCase(fetchGetOnboardingProgress.pending, (state, _action) => {
      state.dataLoader = true;
      state.error = '';
    });
    builder.addCase(fetchGetOnboardingProgress.fulfilled, (state, action) => {
      state.dataLoader = false;
      state.error = '';
      state.onboardingProgress = { ...state.onboardingProgress, ...action.payload };
    });
    builder.addCase(fetchGetOnboardingProgress.rejected, setError);
    builder.addCase(fetchPutOnboardingProgress.pending, (state, _action) => {
      state.dataLoader = true;
      state.error = '';
    });
    builder.addCase(fetchPutOnboardingProgress.fulfilled, (state, action) => {
      state.dataLoader = false;
      state.error = '';
      state.onboardingProgress = { ...state.onboardingProgress, ...action.payload };
    });
    builder.addCase(fetchGetOnboardingQuestion.pending, (state, _action) => {
      state.error = '';
    });
    builder.addCase(fetchGetOnboardingQuestion.fulfilled, (state, action) => {
      state.onboardingQuestions = action.payload;
    });
    builder.addCase(fetchGetOnboardingQuestion.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(fetchPutOnboardingProgress.rejected, setError);
  },
});

export const {
  actionSetOnboardingData,
  actionEditOnboardingData,
  actionEditOnboardingAdvisor,
  actionSetOnboardingRequestErrors,
  actionClearOnboardingErrors,
} = onboardingSlice.actions;

export default onboardingSlice;
