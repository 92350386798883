
import {
  LoansSearchType, FundingsProductTypes, PaginatedGrantsResponseType, PaginatedLoansResponseType, GrantsSearchType,
  LoanFilterSettings,
  GrantFilterSettings
} from '_types/funding.interface';
import { TenantFilterTypeEnum, PlatformLanguages } from '_types';

import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const fundingService = {
  getLoansList: (
    loansSearchPayload: LoansSearchType,
    currentLanguage: PlatformLanguages,
    controller?: AbortController,
    isUnauth?: boolean,
  ) => {
    const pathEnding = isUnauth ? '/unauth' : '';

    return axiosApiInstance
      .post<PaginatedLoansResponseType>(
        `${baseUrl}/api/v1/fundings/search-loans${pathEnding}`,
        loansSearchPayload,
        {
          signal: controller?.signal,
          params: { lang: currentLanguage },
        },
      )
      .then(({ data }) => data);
  },
  getGrantsList: (
    grantsSearchPayload: GrantsSearchType,
    currentLanguage: PlatformLanguages,
    controller?: AbortController,
    isUnauth?: boolean,
  ) => {
    const pathEnding = isUnauth ? '/unauth' : '';

    return axiosApiInstance
      .post<PaginatedGrantsResponseType>(
        `${baseUrl}/api/v1/fundings/search-grants${pathEnding}`,
        grantsSearchPayload,
        {
          signal: controller?.signal,
          params: { lang: currentLanguage },
        },
      )
      .then(({ data }) => data);
  },
  getProductTypes: () => {
    return axiosApiInstance
      .get<FundingsProductTypes>(`${baseUrl}/api/v1/fundings/product-types`)
      .then(({ data }) => data);
  },
  getLoanFilterSettings: () => axiosApiInstance
    .get<LoanFilterSettings>(
      `${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.LOAN_FILTER}`)
    .then(({ data }) => data),
  getGrantFilterSettings: () => axiosApiInstance
    .get<GrantFilterSettings>(
      `${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.GRANT_FILTER}`)
    .then(({ data }) => data),
};
export default fundingService;
