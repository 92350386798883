
import about_us from './about_us.json';
import funding from './funding.json';
import glossary from './glossary.json';
import misc from './misc.json';
import myAccount from './myAccount.json';


export const resourcesEsVerizon = {
  about_us,
  funding,
  glossary,
  misc,
  myAccount,
};
