import React, { memo, useCallback, useMemo } from 'react';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';


import { rateStepsText } from '_constants/advisorConstants';

import Textarea from 'app/components/Textarea/Textarea';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


interface StepTimeSelectorProps {
  setRate: Function;
  step: number;
  totalSteps: number;
  rate: string[] | number[];
  advisor: {
    firstName: string;
    lastName: string;
  };
}

const StepRateSelectorComponent: React.FC<StepTimeSelectorProps> = ({
  totalSteps,
  setRate,
  step,
  rate,
  advisor,
}) => {
  const { t } = useTenantTranslation();

  const onChange = useCallback((value: number) => {
    const newRate: any = [...rate];
    newRate[step] = value;
    setRate(newRate);
  }, [step]);

  const rateText = useMemo(() => rateStepsText(t, advisor)[step], [t, advisor, step]);

  return (
    <>
      <h2 className="modal_body_title">{rateText.title}</h2>
      <p className="modal_body_subtitle">{rateText.subtitle}</p>
      {step !== totalSteps
        ? (
          <>
            <Rate
              value={Number(rate[step]) || 0}
              allowClear={false}
              onChange={onChange}
            />

            <div className="modal_body_skip">
              <p>{t('misc.not_for_me')}</p>
              <p>{t('misc.love_it')}</p>
            </div>
          </>
        ) : <Textarea type="text" placeholder={''} value={String(rate[step] || '')} onChange={onChange} />}

    </>
  );
};

export default memo(StepRateSelectorComponent);
