import React, { memo, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { actionHandleShowProfileMissingDataModal } from 'redux/reducers/modal';
import { showProfileMissingDataModalSelector } from 'redux/selectors/modal';
import { profileInfoSelector } from 'redux/selectors/profile';
import { onboardingProgressSelector } from 'redux/selectors/onboarding';

import ProfileMissingDataModal from '..';
import { getProfileMissingData } from '../utils';


const ProfileMissingDataModalWrapper: React.FC = () => {
  const dispatch = useAppDispatch();

  const showProfileMissingDataModal = useAppSelector(showProfileMissingDataModalSelector);
  const profileInfo = useAppSelector(profileInfoSelector);
  const onboardingProgress = useAppSelector(onboardingProgressSelector);

  useEffect(
    () => {
      if (!profileInfo || !onboardingProgress?.isOnboardingCompleted) {
        return;
      }

      const missingFields = getProfileMissingData(profileInfo);

      if (missingFields.length) {
        dispatch(actionHandleShowProfileMissingDataModal({ missingFields }));
        document.body.style.overflow = 'hidden';
      }
    }, [profileInfo, onboardingProgress?.isOnboardingCompleted],
  );

  return showProfileMissingDataModal ? <ProfileMissingDataModal /> : null;
};

export default memo(ProfileMissingDataModalWrapper);
