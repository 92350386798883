import React, { memo, useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ZendeskAPI } from 'react-zendesk';
import { MdQuestionMark } from 'react-icons/md';

import { phoneConstant } from '_constants/otherConstants';

import Button from 'app/components/Button';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector, tenantSettingsZendeskSelector } from 'redux/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import useTenantColors from 'utils/hooks/useTenantColors';
import { getCurrentPlatformLanguage } from 'utils/translations';

import ChatSupportIcon from '../Icons/ChatSupportIcon';
import HelpCenterIcon from '../Icons/HelpCenterIcon';

import './style.scss';


const HelpButton: React.FC = () => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);

  const zendeskSettings = useAppSelector(tenantSettingsZendeskSelector);

  const closeModal = useCallback(
    () => {
      if (isOpenModal) {
        setIsOpenModal(false);
      }
    }, [isOpenModal]
  );

  const { ref } = useOnClickOutside(
    isOpenModal,
    closeModal,
  );

  const openWidget = useCallback(
    () => {
      try {
        const lang = getCurrentPlatformLanguage();
        ZendeskAPI('webWidget', 'setLocale', lang);
        ZendeskAPI('webWidget', 'updateSettings', { color: { theme: tenantStyling?.mainColor } });
        ZendeskAPI('webWidget', 'open');
        ZendeskAPI('webWidget', 'show');
        closeModal();
      } catch (error) { /* empty */ }
    }, [tenantStyling, closeModal]
  );

  return (
    <div className="help" ref={ref}>
      <Button
        className="help_btn"
        type="btn"
        btnLook="filled-gray"
        value={isPhone ? '' : t('misc.need_help')}
        onClick={() => setIsOpenModal(!isOpenModal)}
        size="large"
        leftIcon={<MdQuestionMark size={24} color={colorVariables?.icons.icon_secondary_tetriary_button} />}
      />

      {isOpenModal && (
        <div className="help_modal">
          <div
            className="help_modal_item"
            onClick={openWidget}
          >
            <ChatSupportIcon className="help_modal_item__icon" />
            {t('misc.chat_with_support')}
          </div>

          <a
            href={zendeskSettings?.zendeskUrl || '#'}
            target="_blank"
            rel="noopener noreferrer"
            className="help_modal_item"
            onClick={closeModal}
          >
            <HelpCenterIcon className="help_modal_item__icon" />{t('misc.help_center')}
          </a>
        </div>
      )}
    </div>
  );
};

export default memo(HelpButton);


