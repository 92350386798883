export enum PlatformLanguages {
  ENGLISH = 'en',
  SPANISH = 'es',
  CHINESE = 'zh',
  ARABIC = 'ar',
}

export enum TenantFilterTypeEnum {
  LOAN_FILTER = 'loan-filter',
  GRANT_FILTER = 'grant-filter',
  ADVISOR_FILTER = 'advisor-filter',
  ADVISOR_ORG_FILTER = 'advisor-org-filter',
  COURSE_FILTER = 'course-filter',
}
export interface IconType {
  className?: string;
  color?: string;
}

export interface SessionRateType {
  id: number;
  experience: number;
  wouldRecommend: number;
  expertise: number;
  feedback: string;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export type CoursesFilterType = {
  order: string;
  filters: string[];
} & Pagination;


export type TranslateValueType<T = string> = {
  name: string;
  value: T;
  icon?: JSX.Element;
  hint?: string;
}

export type FilterValueType = any | any[];

export type FilterInfoAvailableValuesList = TranslateValueType<any>[];

export type FilterInfoType = {
  availableValues: FilterInfoAvailableValuesList;
  anyText: string;
  description?: string;
  title: string;
  phoneText?: string;
  buttonText: string;
  isMultiple: boolean;
}

export type FilterInfoListType = Record<string, FilterInfoType>;
