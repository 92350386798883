import React, { ReactElement } from 'react';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import { RoleType } from '_types/user.interface';

import ScheduleIcon from 'app/components/Icons/ScheduleIcon';
import CheckMarkIcon from 'app/components/Icons/CheckMarkIcon';

import { NotificationUserMentioned } from '../../types';
import { LinkToObjectType } from '../NotificationBlock/types';

import { SessionCompletedNotificationSessionObject } from './types';


export const getTitleBlock = (
  userMentioned: NotificationUserMentioned,
  t: TFunction,
): ReactElement | null => {
  return (
    <Trans components={[<span key="0" />]}>
      {t('notifications.title.marked_session_completed', {
        name: `${userMentioned.firstName} ${userMentioned.lastName}`,
      })}
    </Trans>
  );
};

export const getContentBlock = (
  session: SessionCompletedNotificationSessionObject,
): ReactElement | null => {
  return (
    <div className="marked">
      <CheckMarkIcon />
      <p>{session.sessionName}</p>
    </div>
  );
};

export const getLinkToObject = (
  session: SessionCompletedNotificationSessionObject,
  currentUserRole: RoleType,
  t: TFunction,
): LinkToObjectType | null => {
  return {
    startIcon: <ScheduleIcon />,
    parts: [
      {
        content: <>{t('misc.session_upper')}</>,
        link: currentUserRole === RoleType.SBO ? '/advisors/list' : '/clients',
      },
      {
        content: session.sessionName,
        link: `/advisors/session/${session.sessionId}`,
      },
    ],
  };
};
