import React, { memo } from 'react';

import { RoleType } from '_types/user.interface';

import AdvisoryOrgMissingDataForm from '../AdvisoryOrgMissingDataForm';
import AdvisorMissingDataForm from '../AdvisorMissingDataForm';
import SboMissingDataForm from '../SboMissingDataForm';


interface ProfileMissingDataFormProps {
  role: RoleType;
}

const ProfileMissingDataForm = ({ role }: ProfileMissingDataFormProps) => {
  switch (role) {
    case RoleType.ADVISORY_ORG_ADMIN:
      return <AdvisoryOrgMissingDataForm />;
    case RoleType.ADVISOR:
      return <AdvisorMissingDataForm />;
    case RoleType.SBO:
      return <SboMissingDataForm />;
    default:
      return null;
  }
};

export default memo(ProfileMissingDataForm);
