import React, { useCallback, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { RiArrowDownSFill } from 'react-icons/ri';

import { phoneConstant } from '_constants/otherConstants';

import { RoleType } from '_types/user.interface';

import AvatarCircle from 'app/components/AvatarCircle/AvatarCircle';
import BusinessIcon from 'app/components/Icons/BusinessIcon';
import ExitIcon from 'app/components/Icons/ExitIcon';
// import LanguageSwitch from 'app/components/LanguageSwitch';
import NotificationsComponent from 'app/components/NotificationsComponent';
import MenuIcon from 'app/components/Icons/MenuIcon';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { isProfileChangedSelector, profileInfoAssuredSelector, profilePhotoSelector } from 'redux/selectors/profile';
import { actionSetShowUnsavedChangesPrompt } from 'redux/reducers/profile';
import { showMenuModalSelector } from 'redux/selectors/modal';
import { actionHandleShowMenuModal } from 'redux/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { auth } from 'utils/firebase/firebase';
import useTenantColors from 'utils/hooks/useTenantColors';


const avatarSize = { small: '40', large: '40' };

const GeneralHeaderAuthProfilePart: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });
  const profileInfo = useAppSelector(profileInfoAssuredSelector);
  const profilePhoto = useAppSelector(profilePhotoSelector);
  const hasProfileUnsavedChanges = useAppSelector(isProfileChangedSelector);
  const { colorVariables } = useTenantColors();

  const [isOpenBurger, setIsOpenBurger] = useState<boolean>(false);

  const logout = useCallback(
    async () => {
      sessionStorage.clear();
      await auth.signOut();

      navigate('/');
    }, [dispatch, navigate]
  );

  const handleLogoutClick = useCallback(
    () => {
      if (hasProfileUnsavedChanges) {
        dispatch(actionSetShowUnsavedChangesPrompt({
          onDecline: () => { },
          onOk: logout,
        }));
      } else {
        logout();
      }
    }, [dispatch, logout, hasProfileUnsavedChanges]
  );

  const onOutsideClick = useCallback(
    () => {
      if (isOpenBurger) {
        setIsOpenBurger(false);
      }
    }, [isOpenBurger, setIsOpenBurger]
  );

  const onProfileBurgerOpenToggle = useCallback(
    () => setIsOpenBurger(isOpen => !isOpen), [setIsOpenBurger]
  );

  const { ref } = useOnClickOutside(true, onOutsideClick);

  const isAdvisorOrAdvisoryOrgAdmin = (
    profileInfo.role === RoleType.ADVISOR
    || profileInfo.role === RoleType.ADVISORY_ORG_ADMIN
  );

  const isModalShown = useAppSelector(showMenuModalSelector);
  const handleMenuButton = useCallback(() => {
    dispatch(actionHandleShowMenuModal(!isModalShown));
  }, []);

  return (
    <>
      <NotificationsComponent />

      <div
        className="profile_avatar"
        ref={ref}
      >
        <div className="profile_avatar__wrapper" onClick={onProfileBurgerOpenToggle}>
          <AvatarCircle
            className="profile_avatar__img"
            firstName={profileInfo.firstName}
            lastName={profileInfo.lastName}
            src={profilePhoto}
            size={avatarSize}
            color={colorVariables?.background.background_avatar_with_letters || '#459396'}
          />
          <RiArrowDownSFill size={20} color={colorVariables?.icons.icon_dropdown} className={isOpenBurger ? 'active' : ''} />
        </div>

        {isOpenBurger && (
          <div className="profile_avatar__burger">
            <div
              className="burger_item"
              onClick={() => navigate('/my-account')}
            >
              <BusinessIcon className="burger_item__icon" />
              {isAdvisorOrAdvisoryOrgAdmin ? t('misc.my_profile') : t('misc.my_account')}
            </div>

            <div
              className="burger_item"
              onClick={handleLogoutClick}
            >
              <ExitIcon className="burger_item__icon" />
              {t('misc.logout')}
            </div>
          </div>
        )}
      </div>
      <button type="button" onClick={handleMenuButton} className="profile_menu_button">
        <MenuIcon />
      </button>
    </>
  );
};

export default memo(GeneralHeaderAuthProfilePart);
