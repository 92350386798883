import React, { useCallback, useState } from 'react';

import BookingModal from 'app/components/Modals/BookingModal';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { fetchAdvisorDetails } from 'redux/actions/advisor';
import { advisorDetailsSelector } from 'redux/selectors/advisor';
import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';

import BookingContext from 'utils/contexts/BookingContext';
import { getCurrentTimeZone } from 'utils/moment';


interface BookingContextHandlerProviderProps {
  children: any;
}

const BookingContextHandlerProvider: React.FC<BookingContextHandlerProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const advisorDetails = useAppSelector(advisorDetailsSelector);

  const [advisor, setAdvisor] = useState<any | null>(null); // TODO: change any to specific type
  const [advisorId, setAdvisorId] = useState<number | null>(null);
  const [isModalOpen, setModalState] = useState<boolean>(false);

  const closeModal = () => {
    setModalState(false);
  };

  const openModal = useCallback(async (id: number) => {
    if (!advisorDetails || advisorDetails?.id !== id) {
      await dispatch(fetchAdvisorDetails({
        id: Number(id),
        timeZone: getCurrentTimeZone(),
        callBack: setAdvisor,
      }));
    } else {
      setAdvisor(advisorDetails);
    }
    setAdvisorId(id);
    setModalState(true);
  }, [advisorDetails, dispatch]);

  return (
    <BookingContext.Provider value={openModal}>
      {(isModalOpen) && (
        <BookingModal
          advisor={advisor!}
          onClose={closeModal}
          advisorId={advisorId}
          isModalOpen={isModalOpen}
        />
      )}
      {children}
    </BookingContext.Provider>
  );
};

export const BookingContextProvider: React.FC<BookingContextHandlerProviderProps> = ({
  children,
}) => {
  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);

  if (!tenantFeatureFlags?.sessions) {
    return children;
  }

  return <BookingContextHandlerProvider>{children}</BookingContextHandlerProvider>;
};
