import { TFunction } from 'i18next';

import {
  HeaderTabs,
  advisorHeaderTabs,
  unauthenticatedHeaderTabs,
  sboHeaderTabs,
} from '_constants/headerConstants';

import { RoleType } from '_types/user.interface';
import { TenantFeatureFlags } from '_types/tenant-settings.interface';


const filterTabsWithTenantFeatureFlags = (
  tabs: HeaderTabs[],
  tenantFeatureFlags: TenantFeatureFlags,
) => tabs.filter(tab => !tab.featureFlagField || tenantFeatureFlags[tab.featureFlagField]);

export const headerTabsConstantsFn = (
  role: RoleType | undefined,
  tenantFeatureFlags: TenantFeatureFlags | null | undefined,
  t: TFunction,
): HeaderTabs[] | undefined => {
  let headerTabsList: HeaderTabs[];

  switch (role) {
    case RoleType.ADVISORY_ORG_ADMIN:
    case RoleType.ADVISOR:
      headerTabsList = advisorHeaderTabs(t);
      break;
    case RoleType.SBO:
      headerTabsList = sboHeaderTabs(t);
      break;
    default:
      headerTabsList = unauthenticatedHeaderTabs(t);
      break;
  }

  if (!tenantFeatureFlags) {
    return headerTabsList;
  }

  return filterTabsWithTenantFeatureFlags(headerTabsList, tenantFeatureFlags);
};
