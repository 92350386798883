import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Loader from 'app/components/Loader';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { fetchGetProfileInfo } from 'redux/actions/profile';
import { isProfileInfoPresentSelector } from 'redux/selectors/profile';
import { onUserDataClean } from 'redux/reducers/root';
import { fetchGetOnboardingProgress } from 'redux/actions/onboarding';
import { isOnboardingProgressPresentSelector } from 'redux/selectors/onboarding';
import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';

import useManageSocketConnection from 'utils/contexts/SocketContext/useManageSocketConnection';
import FirebaseAuthDataContextProvider from 'utils/contexts/FirebaseAuthDataContext/FirebaseAuthDataContextProvider';
import { useCurrentAccessToken, useIsFirebaseInitializing } from 'utils/firebase/hooks';
import { useGATagAddUserId } from 'utils/hooks/useGATagAddUserId';
import { auth } from 'utils/firebase/firebase';
import ProfileFieldIdsContextProvider from 'utils/contexts/ProfileFieldIdsContext/ProfileFieldIdsContextProvider';
import { BookingContextProvider } from 'utils/contexts/BookingContext/BookingContextProvider';


const UserDataLoaderComponent: React.FC = memo(
  () => {
    const dispatch = useAppDispatch();

    const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
    const isOnboardingProgressPresent = useAppSelector(isOnboardingProgressPresentSelector);
    const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);

    const accessToken = useCurrentAccessToken();
    const isFirebaseInitializing = useIsFirebaseInitializing();

    useManageSocketConnection(accessToken);

    useGATagAddUserId(accessToken, isProfileInfoPresent);

    useEffect(() => {
      if (isFirebaseInitializing) {
        return;
      }

      (async () => {
        try {
          if (accessToken && !tenantFeatureFlags?.users) {
            await auth.signOut();
          } else if (accessToken && !isProfileInfoPresent) {
            await dispatch(fetchGetProfileInfo()).unwrap();
            await dispatch(fetchGetOnboardingProgress()).unwrap();
          } else if (!accessToken && isProfileInfoPresent) {
            onUserDataClean(dispatch);
          }
        } catch (e) {
          // eslint-disable-next-line no-alert
          alert('An error occurred while loading user data. Please try reloading page or contact support');
          // eslint-disable-next-line no-console
          console.error(e);
        }
      })();
    }, [accessToken, isProfileInfoPresent, isFirebaseInitializing, !tenantFeatureFlags?.users]);

    const isRequiredUserDataLoaded = isProfileInfoPresent && isOnboardingProgressPresent;

    if (isFirebaseInitializing || (accessToken && !isRequiredUserDataLoaded)) {
      return <Loader />;
    }

    return <Outlet />;
  },
);

const UserDataLoaderWrapper: React.FC = memo(() => (
  <FirebaseAuthDataContextProvider>
    <ProfileFieldIdsContextProvider>
      <BookingContextProvider>
        <UserDataLoaderComponent />
      </BookingContextProvider>
    </ProfileFieldIdsContextProvider>
  </FirebaseAuthDataContextProvider>
));

export default UserDataLoaderWrapper;
