import { createSlice } from '@reduxjs/toolkit';

import { fetchClient } from 'redux/actions/client';


interface ClientState {
  isLoading: boolean,
  data: any,
}

const clientInitialState: ClientState = {
  isLoading: true,
  data: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState: clientInitialState,
  reducers: {
    resetState: () => clientInitialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchClient.pending, state => {
      state.isLoading = true;
      state.data = null;
    });
    builder.addCase(fetchClient.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchClient.rejected, state => {
      state.isLoading = false;
    });
  },
});


export default clientSlice;
