import { createAsyncThunk } from '@reduxjs/toolkit';

import clientService from '_services/clientService';


export const fetchClient = createAsyncThunk('client/fetchClient', async (id: number, { rejectWithValue }) => {
  try {
    const response = await clientService.getClientInfo(id);
    return response;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
