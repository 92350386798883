import { TFunction } from 'i18next';

import { ProductLocationType } from '_types/funding.interface';


export const getButtonProp = (referralMethod: string) => {
  switch (referralMethod) {
    case 'WEBSITE':
      return { btnText: 'misc.link_to_website' };
    case 'URL':
      return { btnText: 'misc.apply_online' };
    case 'EMAIL':
      return { btnText: 'misc.email_to_connect' };

    default:
      return { btnText: '' };
  }
};

const isWholeYears = (months: number) => months % 12 === 0;

export const formatMonthsToYears = (
  totalMonths: number,
  t: TFunction,
  shortEnd: boolean = false,
) => {
  const years: number = Math.floor(totalMonths / 12);
  const months: number = totalMonths % 12;
  const yearsString: string = t('funding.n_years', { count: years });
  const monthsString: string = shortEnd
    ? t('funding.n_months_short', { count: months })
    : t('funding.n_months', { count: months });

  if (years === 0) {
    return monthsString;
  }

  if (months === 0) {
    return yearsString;
  }

  return `${yearsString} ${monthsString}`;
};

export const formatLoanTerm = (
  minTerm: number,
  maxTerm: number,
  t: TFunction,
): string => {
  // minTerm = 12;
  // maxTerm = 15;
  if (minTerm === maxTerm && minTerm === 0) {
    return t('funding.varies');
  }
  if (minTerm === maxTerm) {
    return `${formatMonthsToYears(maxTerm, t)}`;
  }

  if (isWholeYears(minTerm) && isWholeYears(maxTerm)) {
    return t('funding.m_to_n_years', { m: minTerm / 12, n: maxTerm / 12 });
  }
  if (
    (minTerm < 12 && maxTerm < 12)
    || (minTerm > 12 && maxTerm > 12)
  ) {
    return t('funding.m_to_n_months', { m: minTerm, n: maxTerm });
  }

  let shortend = true;
  if (isWholeYears(minTerm) || isWholeYears(maxTerm)) {
    shortend = false;
  }

  return t('funding.m_to_n', {
    m: formatMonthsToYears(minTerm, t, shortend),
    n: formatMonthsToYears(maxTerm, t, shortend),
  });
};

export const formatPercent = (value: number | null, decimalDigits = 2) => {
  if (value || value === 0) {
    if (Math.floor(value) + 0.1 ** decimalDigits * 0.5 > value) {
      return `${Math.floor(value).toString()}%`;
    }
    return `${value.toFixed(decimalDigits)}%`;
  }
  return value;
};

export const formatMoney = (value: number | null) => {
  if (value || value === 0) {
    let result = `$${value}`;
    if (value >= 10 ** 3 && value < 10 ** 6) {
      result = `$${value / 10 ** 3}K`;
    } else if (value >= 10 ** 6 && value < 10 ** 9) {
      result = `$${value / 10 ** 6}M`;
    } else if (value >= 10 ** 9) {
      result = `$${value / 10 ** 9}B`;
    }

    return result;
  }
  return value;
};

export const formatMoneyWithComma = (value: number | null) => {
  if (value) {
    return `$${value.toLocaleString()}`;
  }
  return value;
};

export const formatAs = (value: number | null, type: 'money' | 'percent' | 'none') => {
  if (value !== null || value !== undefined) {
    if (type === 'money') {
      return formatMoney(value) as string;
    } if (type === 'percent') {
      return formatPercent(value);
    }
  }
  return value;
};

export const processFundingValues = (
  minValue: any,
  maxValue: any,
  formatTo: 'money' | 'percent' | 'none' | 'term',
  t: TFunction,
  defaultString?: string,
) => {
  let value;

  if (formatTo === 'term') {
    value = formatLoanTerm(minValue, maxValue, t);
    return value;
  }

  if (minValue === maxValue && minValue !== null && minValue !== 0) {
    value = formatAs(maxValue, formatTo);
    return value;
  }

  if (minValue === maxValue && minValue === 0 && maxValue === 0) {
    value = formatAs(maxValue, formatTo);
    return value;
  }

  if ((minValue === null || minValue === 0) && maxValue !== null) {
    value = t('funding.up_to', { value: formatAs(maxValue, formatTo) });
  } else if (minValue !== null && maxValue !== null && maxValue !== 0) {
    value = t('funding.m_to_n', { m: formatAs(minValue, formatTo), n: formatAs(maxValue, formatTo) });
  } else if (minValue !== null && minValue !== 0 && maxValue === null || 0) {
    value = `${formatAs(minValue, formatTo)}+`;
  }

  if (value) {
    return value;
  }

  if (defaultString !== undefined) {
    return defaultString;
  }

  return t('funding.varies');
};

export const capitalizeFirstLetter = (inputString: string | null) => {
  if (inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
  }
  return inputString;
};

export const getFundingProductLocationDisplayValue = (
  productLocation: ProductLocationType,
  t: TFunction,
) => t(`funding.location_${productLocation.toLowerCase()}`);
