import React, { ReactNode, memo } from 'react';

import './style.scss';


interface FooterWrapperProps {
  children?: ReactNode;
}

const FooterWrapper: React.FC<FooterWrapperProps> = ({
  children,
}) => (<footer className="footer_wrapper">{children}</footer>);

export default memo(FooterWrapper);
