import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import { SboType } from '_types/sbo.interface';
import { RoleType } from '_types/user.interface';

import { RootState } from 'store/configureStore';


type ProfileSelectorType = <T> (state: RootState) => T;

export const profileInfoSelector = (state: RootState) => state.profile.profileInfo;
export const profileInfoAssuredSelector = (state: RootState) => state.profile.profileInfo!;
export const prevProfileInfoSelector = (state: RootState) => state.profile.prevProfileInfo;
export const profileErrorsSelector: ProfileSelectorType = (state: RootState) => merge(
  { ...state.profile.dataValidationErrors as any },
  { ...state.profile.profileFieldsExternalErrorsObject as any },
);
export const profileMissingDataErrorsSelector: ProfileSelectorType = (
  state
) => state.profile.missingDataValidationErrors as any;
export const profileDataValidationSchemaSelector = (state: RootState) => state.profile.dataValidationSchema;

export const profileIdSelector = (state: RootState) => state.profile.profileInfo?.id;
export const profileInfoEmailSelector = (state: RootState) => state.profile.profileInfo?.email;
export const profileInfoFirstNameSelector = (state: RootState) => state.profile.profileInfo?.firstName;
export const profileInfoLastNameSelector = (state: RootState) => state.profile.profileInfo?.lastName;
export const profileInfoIsUserCookieConsentSelector = (
  state: RootState,
) => state.profile.profileInfo?.isUserCookieConsent;
export const isProfileInfoPresentSelector = (state: RootState) => !!state.profile.profileInfo;
export const profileRoleSelector = (state: RootState) => state.profile.profileInfo?.role;
export const profileSboInfoSelector = (state: RootState) => state.profile.profileInfo?.sboData!;
export const profileAdvisorSelector = (state: RootState) => state.profile.profileInfo?.advisorData!;
export const profileAdvisorPhoneNumberSelector = (
  state: RootState,
) => state.profile.profileInfo?.advisorData?.phoneNumber;
export const profileAdvisoryOrgInfoSelector = (state: RootState) => state.profile.profileInfo?.advisoryOrgData!;
export const profileIsAdvisorSelector = (state: RootState) => state.profile.profileInfo?.isAdvisor;
export const profileHasSkippedMFASetupSelector = (state: RootState) => !!state.profile.profileInfo?.hasSkippedMFASetup;
export const profileIsEmailConfirmedSelector = (state: RootState) => !!state.profile.profileInfo?.isEmailConfirmed;
export const profileIsMFASetUpSelector = (state: RootState) => !!state.profile.profileInfo?.isMFASetUp;
export const profileErrorSelector = (state: RootState) => state.profile.error;
export const profileResetEmailErrorSelector = (state: RootState) => state.profile.resetEmailError;
export const isResetEmailLoadingSelector = (state: RootState) => state.profile.isResetEmailLoading;
export const profileSboInfoSboTypeSelector = (state: RootState) => state.profile.profileInfo?.sboData?.sboType;
export const profileSboInfoAdvisoryOrganizationsSelector = (
  state: RootState,
) => state.profile.profileInfo?.sboData?.advisoryOrganizations!;
export const profileSboInfoTopicsSelector = (state: RootState) => state.profile.profileInfo?.sboData?.topics!;
export const profileSboInfoLanguagesSelector = (state: RootState) => state.profile.profileInfo?.sboData?.languages!;
export const profileSboInfoEthnicitySelector = (state: RootState) => state.profile.profileInfo?.sboData?.ethnicity!;

export const profilePhotoSelector = (state: RootState) => state.profile.profileInfo?.sboData?.photo
    || state.profile.profileInfo?.advisorData?.photo;

export const isProfileChangedSelector = (state: RootState) => !isEqual(
  state.profile.profileInfo!, state.profile.prevProfileInfo!,
);
export const isNeedRedirectToMyAccountSelector = (state: RootState) => {
  return state.profile.prevProfileInfo?.sboData?.sboType === SboType.PRE_STARTUP && isProfileChangedSelector(state);
};

export const isProfileSBOSelector = (state: RootState) => {
  return state.profile.profileInfo?.role === RoleType.SBO;
};

export const isProfileAdvisorOrAdvisoryOrgAdminSelector = (state: RootState) => {
  const role = state.profile.profileInfo?.role;
  return role === RoleType.ADVISOR || role === RoleType.ADVISORY_ORG_ADMIN;
};

export const isProfileSuperAdminSelector = (state: RootState) => {
  return state.profile.profileInfo?.role === RoleType.SUPER_ADMIN;
};

export const profileTopicsSelector = (state: RootState) => {
  if (state.profile.profileInfo?.isAdvisor) {
    return state.profile.profileInfo?.advisorData?.topics || [];
  }

  return state.profile.profileInfo?.sboData?.topics || [];
};

export const profileInfoLoaderSelector = (state: RootState) => state.profile.loader;

export const showUnsavedChangesPromptSelector = (state: RootState) => state.profile.showUnsavedChangesPrompt;

export const profileInfoUserCreatedAtSelector = (state: RootState) => state.profile.profileInfo?.createdAt;

export const haveProfileFieldsInnerErrorsObjectSelector = (
  state: RootState,
) => state.profile.haveProfileFieldsInnerErrorsObject;

export const profileFieldsExternalErrorsObjectSelector = (
  state: RootState,
) => state.profile.profileFieldsExternalErrorsObject;

export const profileLatestTenantLegalDocumentUserAcceptsSelector = (
  state: RootState,
) => state.profile.profileInfo?.latestTenantLegalDocumentUserAccepts;
