import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { PageAction } from '@newrelic/browser-agent/features/page_action';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';


const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { enabled: true },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    sa: 1,
  },
  loader_config: {
    accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    trustKey: process.env.REACT_APP_NEW_RELIC_TRUSTKEY,
    agentID: process.env.REACT_APP_NEW_RELIC_AGENTID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSEKEY,
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
  },
  features: [
    PageAction,
    Spa,
  ],
};

// eslint-disable-next-line no-new
new BrowserAgent({ ...options });

export const newRelic = new MicroAgent({ ...options });
