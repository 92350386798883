import React, {
  memo, useCallback, useEffect, useState
} from 'react';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { unreadNotificationsSelector } from 'redux/selectors/notification';
import { actionRemoveReadNotificationsFromUnread, actionSetReadNotifications } from 'redux/reducers/notification';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import NotificationsIconUnread from '../Icons/NotificationsIconUnread';
import NotificationsIconRead from '../Icons/NotificationsIconRead';

import NotificationsContainer from './NotificationsContainer';

import './style.scss';


const NotificationsComponent: React.FC = () => {
  const dispatch = useAppDispatch();

  const [
    isNotificationsDropdownOpen,
    setIsNotificationsDropdownOpen,
  ] = useState<boolean>(false);

  const unreadNotifications = useAppSelector(unreadNotificationsSelector);

  const onNotificationDropdownOpenToggle = useCallback(
    () => {
      setIsNotificationsDropdownOpen(state => !state);
    }, [setIsNotificationsDropdownOpen]
  );

  const onClickOutside = useCallback(
    () => {
      setIsNotificationsDropdownOpen(false);
    }, [setIsNotificationsDropdownOpen]
  );

  const { ref } = useOnClickOutside(
    isNotificationsDropdownOpen,
    onClickOutside,
  );

  const unreadNotificationsExist = unreadNotifications && unreadNotifications.find(
    unreadNotification => !unreadNotification.isRead,
  );

  useEffect(
    () => {
      if (!isNotificationsDropdownOpen) {
        dispatch(actionRemoveReadNotificationsFromUnread());
        dispatch(actionSetReadNotifications(null));
      }
    },
    [isNotificationsDropdownOpen, dispatch]
  );

  return (
    <div className="profile_notifications" ref={ref}>
      <div
        className={clsx('profile_notifications__icon', { open: isNotificationsDropdownOpen })}
        onClick={onNotificationDropdownOpenToggle}
      >
        {unreadNotificationsExist ? (
          <NotificationsIconUnread />
        ) : (
          <NotificationsIconRead />
        )}
      </div>

      {isNotificationsDropdownOpen && (
        <div className="profile_notifications__popup">
          <NotificationsContainer />
        </div>
      )}
    </div>
  );
};

export default memo(NotificationsComponent);
