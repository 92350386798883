import React, { memo } from 'react';


interface CongratulationsIconProps {
  className?: string;
  color?: string;
}
const CongratulationsIcon: React.FC<CongratulationsIconProps> = ({ className, color }) => (
  <svg className={className} width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 19.5L17 23.5L25 15.5M37 19.5C37 21.8638 36.5344 24.2044 35.6298 26.3883C34.7252 28.5722 33.3994 30.5565 31.7279 32.2279C30.0565 33.8994 28.0722 35.2252 25.8883 36.1298C23.7044 37.0344 21.3638 37.5 19 37.5C16.6362 37.5 14.2956 37.0344 12.1117 36.1298C9.92784 35.2252 7.94353 33.8994 6.27208 32.2279C4.60062 30.5565 3.27475 28.5722 2.37017 26.3883C1.46558 24.2044 1 21.8638 1 19.5C1 14.7261 2.89642 10.1477 6.27208 6.77208C9.64773 3.39642 14.2261 1.5 19 1.5C23.7739 1.5 28.3523 3.39642 31.7279 6.77208C35.1036 10.1477 37 14.7261 37 19.5Z"
      stroke={color || '#009999'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(CongratulationsIcon);
