import React, { Suspense, memo } from 'react';
import { Routes, Route } from 'react-router-dom';

import GeneralPageWrapper from 'app/pages/utils/Wrappers/GeneralPageWrapper';
import Loader from 'app/components/Loader';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';

import TenantSettingsLoaderWrapper from './utils/TenantSettingsLoaderWrapper';
import ZendeskInitializerWrapper from './utils/ZendeskInitializerWrapper';
import AuthRequiredRoutesWrapper from './utils/AuthRequiredRoutesWrapper';
import AuthenticatedUserStartupRedirectsWrapper from './utils/AuthenticatedUserStartupRedirectsWrapper';
import UserDataLoaderWrapper from './utils/UserDataLoaderWrapper';
import TenantSpecificStartupChecksWrapper from './utils/TenantSpecificStartupChecksWrapper';


const HomePage = React.lazy(() => import('app/pages/HomePage'));
const NotFoundPage = React.lazy(() => import('app/pages/NotFoundPage'));
const TenantLegalDocumentPage = React.lazy(() => import('app/pages/TenantLegalDocumentPage'));
const GlossaryPage = React.lazy(() => import('app/pages/GlossaryPage'));
const AboutUsPage = React.lazy(() => import('app/pages/AboutUsPage'));
const OnboardingRouter = React.lazy(() => import('./Onboarding'));
const MyAccountRouter = React.lazy(() => import('./MyAccount'));
const AdvisorsRouter = React.lazy(() => import('./Advisors'));
const FundingsRouter = React.lazy(() => import('./Fundings'));
const ClientsRouter = React.lazy(() => import('./Clients'));
const CoursesRouter = React.lazy(() => import('./Courses'));
const OrganizationsRouter = React.lazy(() => import('./Organizations'));
const AuthenticationRouter = React.lazy(() => import('./Authentication/AuthenticationRouter'));
const AuthenticationRouterForAuthUser = React.lazy(() => import('./Authentication/AuthenticationRouterForAuthUser'));

const ProjectRoutes: React.FC = () => {
  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<TenantSettingsLoaderWrapper />}>
          <Route element={<TenantSpecificStartupChecksWrapper />}>
            <Route element={<ZendeskInitializerWrapper />}>
              {tenantFeatureFlags?.users && <Route path="/auth/*" element={<AuthenticationRouter />} />}

              <Route element={<UserDataLoaderWrapper />}>
                <Route element={<GeneralPageWrapper withOutlet />}>
                  <Route path="/legal-documents/:type" element={<TenantLegalDocumentPage />} />
                  {tenantFeatureFlags?.aboutUs && <Route path="/about-us" element={<AboutUsPage />} />}
                  <Route path="/glossary" element={<GlossaryPage />} />
                </Route>

                <Route element={<AuthenticatedUserStartupRedirectsWrapper />}>
                  <Route index element={<HomePage />} />

                  {tenantFeatureFlags?.users && (
                    <Route element={<AuthRequiredRoutesWrapper />}>
                      <Route path="/authenticated/*" element={<AuthenticationRouterForAuthUser />} />
                      <Route path="/onboarding/*" element={<OnboardingRouter />} />
                      <Route path="/my-account/*" element={<MyAccountRouter />} />
                      <Route path="/advisors/*" element={<AdvisorsRouter />} />
                      <Route path="/organizations/*" element={<OrganizationsRouter />} />
                      {tenantFeatureFlags.fundings && <Route path="/fundings/*" element={<FundingsRouter />} />}
                      {tenantFeatureFlags.sessions && <Route path="/clients/*" element={<ClientsRouter />} />}
                      {tenantFeatureFlags.courses && <Route path="/courses/*" element={<CoursesRouter />} />}
                    </Route>
                  )}
                </Route>
              </Route>

              <Route element={<GeneralPageWrapper withOutlet />}>
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default memo(ProjectRoutes);
