import React, { memo, useCallback, useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { phoneConstant } from '_constants/otherConstants';

import { TenantLegalDocumentTypeEnum } from '_types/tenant-settings.interface';

import CookieIcon from 'app/components/Icons/CookieIcon';
import TenantLegalDocumentLink from 'app/components/TenantLegalDocumentLink';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { isProfileInfoPresentSelector, profileInfoIsUserCookieConsentSelector } from 'redux/selectors/profile';
import { fetchSetUserCookieConsent } from 'redux/actions/profile';

import { setLocalStorageValue, useValueFromLocalStorage } from 'utils/hooks/useValueFromLocalStorage';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import CookieConsentButton from './components/CookieConsentButton';


import './style.scss';


const CookieConsentModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTenantTranslation();
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneConstant}px)` });

  const cookieConsent = useValueFromLocalStorage('nextStreetCookiePolicy');
  const cookieConsentTimeStamp = useValueFromLocalStorage('nextStreetCookiePolicyTimestamp');

  const isProfileInfoPresent = useAppSelector(isProfileInfoPresentSelector);
  const profileInfoIsUserCookieConsent = useAppSelector(profileInfoIsUserCookieConsentSelector);

  const handleSaveUserChoice = useCallback(
    async (isAccepted: boolean) => {
      const timeStamp = new Date().toISOString();

      setLocalStorageValue('nextStreetCookiePolicy', isAccepted ? 'Accepted' : 'Declined');
      setLocalStorageValue('nextStreetCookiePolicyTimestamp', timeStamp);

      if (isProfileInfoPresent) {
        await dispatch(fetchSetUserCookieConsent({
          decisionAt: timeStamp,
          accepted: isAccepted,
        }));
      }

      if (!isAccepted) {
        window.location.reload();
      }
    }, [isProfileInfoPresent]
  );

  const handleAccept = useCallback(() => handleSaveUserChoice(true), [handleSaveUserChoice]);
  const handleDecline = useCallback(() => handleSaveUserChoice(false), [handleSaveUserChoice]);

  useEffect(
    () => {
      (async () => {
        const shouldSetOnBackend = profileInfoIsUserCookieConsent === null && cookieConsent;

        if (shouldSetOnBackend) {
          await dispatch(fetchSetUserCookieConsent({
            decisionAt: cookieConsentTimeStamp!,
            accepted: cookieConsent === 'Accepted',
          }));
        }
      })();
    }, [cookieConsentTimeStamp, cookieConsent, profileInfoIsUserCookieConsent]
  );

  return (
    <CookieConsent
      location="bottom"
      cookieName="nextStreetCookiePolicy"
      expires={150}
      containerClasses="cookie_container"
      contentClasses="cookie_content"
      disableStyles
      buttonWrapperClasses="cookie_btns button_wrapper small"
      enableDeclineButton
      buttonText={t('misc.accept_all')}
      declineButtonText={t('misc.decline_all')}
      ButtonComponent={CookieConsentButton}
      onAccept={handleAccept}
      onDecline={handleDecline}
    >
      {isPhone && <hr className="modal-phone-upper-line" />}
      <CookieIcon className="cookie_icon" />
      <div>
        <Trans
          components={[
            <TenantLegalDocumentLink
              key="privacy-policy"
              documentType={TenantLegalDocumentTypeEnum.PRIVACY_POLICY}
              openInNewTab
            />,
          ]}
        >
          {t('misc.cookie_consent')}
        </Trans>
      </div>
    </CookieConsent>
  );
};

export default memo(CookieConsentModal);
