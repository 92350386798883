import React, {
  ReactNode, memo, useLayoutEffect, useMemo
} from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { HeaderTypes } from '_constants/headerConstants';
import { FooterTypes } from '_constants/footerConstants';

import GeneralFooter from 'app/components/Footers/GeneralFooter';
import EmptyFooter from 'app/components/Footers/EmptyFooter';
import Header from 'app/components/Header';

import './style.scss';


interface CommonPageWrapperProps {
  title?: string;
  withOutlet: boolean;
  children?: ReactNode;
  className?: string;
  footerType?: FooterTypes;
  headerType?: HeaderTypes;
}

const CommonPageWrapper: React.FC<CommonPageWrapperProps> = ({
  title,
  withOutlet,
  children,
  footerType = FooterTypes.general,
  headerType = HeaderTypes.general,
  className = '',
}) => {
  const FooterComponent: React.FC = useMemo(
    () => {
      switch (footerType) {
        case FooterTypes.general:
          return GeneralFooter;
        default:
          return EmptyFooter;
      }
    }, [footerType]
  );

  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        <meta name="description" content="Nextstreet advisory platform homepage" />
      </Helmet>

      <main className="app_wrapper">
        <Header type={headerType} />

        <div className={clsx('common_page_wrapper', className)}>
          {children}
          {withOutlet && <Outlet />}
        </div>

        <FooterComponent />
      </main>
    </>
  );
};

export default memo(CommonPageWrapper);
