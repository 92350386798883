import React, { ReactNode, memo } from 'react';
import clsx from 'clsx';
import { isArray } from 'lodash';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import Tooltip from '../Tooltip';

import { getWordDefinitionTooltipDescriptionByPredefinedKey } from './utils';
import { TooltipPredefinedDescriptionKeys } from './types';

import './style.scss';


type WordDefinitionTooltipProps = {
  className?: string;
  hover?: boolean;
} & (
    { predefinedDescriptionKey: TooltipPredefinedDescriptionKeys; }
    | { description: ReactNode; }
  )

const WordDefinitionTooltip: React.FC<WordDefinitionTooltipProps> = (
  props,
) => {
  const { t } = useTenantTranslation();

  const description = 'description' in props
    ? props.description
    : getWordDefinitionTooltipDescriptionByPredefinedKey(
      props.predefinedDescriptionKey,
      t,
    );

  return (
    <Tooltip className={clsx('word_definition_tooltip', props.className)} hover={!!props.hover}>
      {isArray(description) ? description.join(', ') : description}
    </Tooltip>
  );
};

export default memo(WordDefinitionTooltip);
