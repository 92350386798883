import React, { memo, useCallback, useState } from 'react';

import SelectableBlock from 'app/components/SelectableBlock';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


interface StepTimeSelectorProps {
  onSelect: (value: string) => void;
}

const StepTimeSelectorComponent: React.FC<StepTimeSelectorProps> = ({ onSelect }) => {
  const { t } = useTenantTranslation();

  const [selected, setSelected] = useState<string>();

  const selectTime = useCallback(
    (value: string) => {
      setSelected(value);
      onSelect(value);
    }, [onSelect]
  );

  return (
    <>
      <h2 className="modal_body_title">{t('misc.how_much_time_Need')}</h2>
      <SelectableBlock
        selected={selected === 'link15'}
        onChange={selectTime}
        title={t('misc.quick_check_in')}
        subtitle={t('misc.value_minutes', { value: 15 })}
        value="link15"
        type="select"
        className="select"
      />
      <SelectableBlock
        selected={selected === 'link30'}
        onChange={selectTime}
        title={t('misc.standard_session')}
        subtitle={t('misc.value_minutes', { value: 30 })}
        value="link30"
        type="select"
        className="select"
      />
      {/* <SelectableBlock
        selected={selected === 'link60'}
        onChange={selectTime}
        title={t('misc.deep_dive_session')}
        subtitle={t('misc.value_hour', { value: 1 })}
        value="link60"
        type="select"
        className="select"
      /> */}
    </>
  );
};

export default memo(StepTimeSelectorComponent);
