import { createAsyncThunk } from '@reduxjs/toolkit';

import { Pagination } from '_types';

import SessionsService from '_services/sessionsService';


export type FetchSessionsRequestData = Pagination & { sboInfoId: string };

export const fetchGetUpcomingSessions = createAsyncThunk(
  'sessions/fetchGetUpcomingSessions',
  async (requestData: FetchSessionsRequestData, { rejectWithValue }) => {
    try {
      return await SessionsService.getUpcomingSessions(
        requestData.offset,
        requestData.limit,
        requestData.sboInfoId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchGetPastSessions = createAsyncThunk(
  'sessions/fetchGetPastSessions',
  async (pagination: FetchSessionsRequestData, { rejectWithValue }) => {
    try {
      return await SessionsService.getPastSessions(
        pagination.offset,
        pagination.limit,
        pagination.sboInfoId,
      );
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchSessionById = createAsyncThunk('sessions/fetchSessionById', async (id: number, { rejectWithValue }) => {
  try {
    return await SessionsService.getSessionById(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const fetchGroupAllSessions = createAsyncThunk('sessions/fetchGroupAllSessions', async (pagination: Pagination, { rejectWithValue }) => {
  try {
    const currentDate = (new Date()).toISOString();
    return await SessionsService.getGroupAllSessions({ ...pagination, date: currentDate });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const fetchGroupOpenSessions = createAsyncThunk('sessions/fetchGroupOpenSessions', async (pagination: Pagination, { rejectWithValue }) => {
  try {
    const currentDate = (new Date()).toISOString();
    return await SessionsService.getGroupOpenSessions({ ...pagination, date: currentDate });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const fetchClientsCounts = createAsyncThunk('sessions/fetchClientsCounts', async (_, { rejectWithValue }) => {
  try {
    return await SessionsService.getClientsCounts();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
