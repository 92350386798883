import React, { memo, useCallback, useMemo } from 'react';

import { headerTabsConstantsFn } from 'app/components/HomePageTabs/utils';
import HomePageTabs from 'app/components/HomePageTabs/HomePageTabs';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantFeatureFlagsSelector } from 'redux/selectors/tenant-settings';
import { profileRoleSelector } from 'redux/selectors/profile';
import { actionHandleShowMenuModal } from 'redux/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import BaseModal from '../BaseModal';

import './style.scss';


const MenuModal: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const classNames = {
    modalContainer: 'custom_modal_container_menu',
    modal: 'custom_modal',
    modalPhoneUpperLine: 'custom_upper_line',
    modalInnerContainer: 'custom_inner_container',
  };

  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);
  const userRole = useAppSelector(profileRoleSelector);

  const headerTabsConstants = useMemo(
    () => headerTabsConstantsFn(userRole, tenantFeatureFlags, t),
    [userRole, tenantFeatureFlags, t]
  );

  const closeModal = useCallback(
    () => {
      dispatch(actionHandleShowMenuModal(false));
    }, [dispatch]
  );

  if (!headerTabsConstants?.length) {
    return null;
  }

  return (
    <BaseModal
      classNames={classNames}
      onModalClose={closeModal}
      isCloseIconVisible
      closeOnBackgroundClick
    >
      <HomePageTabs />
    </BaseModal>
  );
};

export default memo(MenuModal);
