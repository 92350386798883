import { TenantFilterTypeEnum } from '_types';
import { PaginatedAdvisors, FullAdvisorData, AdvisorFilterSettings } from '_types/advisor.interface';

import axiosApiInstance from './axiosInstance';


const baseUrl = process.env.REACT_APP_BACKEND_DOMAIN;

const advisorService = {
  getUnauthAdvisorList: () => axiosApiInstance
    .get(`${baseUrl}/api/v1/advisor/home-page/unauth?offset=0&limit=4`)
    .then(({ data }) => data),
  getPaginatedAdvisorsForAuthHomePage: (offset = 0, limit = 20) => axiosApiInstance
    .get(`${baseUrl}/api/v1/advisor/home-page/auth?offset=${offset}&limit=${limit}`)
    .then(({ data }) => data),
  getPaginatedAdvisorsForAdvisorsPage: (body, offset = 0, limit = 20) => axiosApiInstance
    .post<PaginatedAdvisors>(`${baseUrl}/api/v1/advisor/advisor-page?offset=${offset}&limit=${limit}`, body)
    .then(({ data }) => data),
  getAdvisor: (id, timeZone) => axiosApiInstance
    .get<FullAdvisorData>(`${baseUrl}/api/v1/advisor/${id}?timezone=${timeZone}`)
    .then(({ data }) => data),
  getAdvisorListByOrgId: (advisoryOrgId: number, offset: number = 0, limit: number = 20) => axiosApiInstance
    .get<PaginatedAdvisors>(`${baseUrl}/api/v1/advisor/advisor-by-org/${advisoryOrgId}?offset=${offset}&limit=${limit}`)
    .then(({ data }) => data),
  getOrgNameList: () => axiosApiInstance
    .get(`${baseUrl}/api/v1/advisory-org/org-names`)
    .then(({ data }) => data),
  getAdvisorFilterSettings: () => axiosApiInstance
    // eslint-disable-next-line max-len
    .get<AdvisorFilterSettings>(`${baseUrl}/api/v1/tenant-settings/tenant-filter-settings/${TenantFilterTypeEnum.ADVISOR_FILTER}`)
    .then(({ data }) => data),
};
export default advisorService;
