import React, {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MultiFactorResolver, PhoneAuthProvider } from '@firebase/auth';


import { LoginFormType } from '_types/modals.interface';

import ssoService from '_services/sso';

import { processMFAPhoneNumberRequestError } from 'app/pages/Authorization/MFASetup/utils';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { auth } from 'utils/firebase/firebase';
import useGetRecaptchaVerifier from 'utils/contexts/RecaptchaContainerContext/useGetRecaptchaVerifier';

import EmailPasswordForm from './EmailPasswordForm';
import MFACodeVerification from './MFACodeVerification';
import ReauthenticateByPassword from './ReauthenticateByPassword';
import { LoginGoToNextStepFnArg } from './types';

import './style.scss';


enum LoginSteps {
  EmailAndPasswordForm,
  MFACodeVerification,
}

type LoginFormProps = {
  formType: LoginFormType;
  nextPageAfterLoginEncoded?: string | null;
  onBeforePathChange?: () => Promise<void> | void,
  submitSAMLResponse?: (samlResponse: string) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  formType = LoginFormType.login,
  nextPageAfterLoginEncoded,
  onBeforePathChange,
  submitSAMLResponse,
}) => {
  const { t } = useTenantTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const getRecaptchaVerifier = useGetRecaptchaVerifier();

  const [
    currentLoginStep,
    setCurrentLoginStep,
  ] = useState<LoginSteps>(LoginSteps.EmailAndPasswordForm);
  const [multifactorResolver, setMultifactorResolver] = useState<MultiFactorResolver | null>(null);
  const [verificationId, setVerificationId] = useState<string>('');

  const nextPageQPString = nextPageAfterLoginEncoded ? `?next=${nextPageAfterLoginEncoded}` : '';
  const nextPageAfterLoginDecoded = nextPageAfterLoginEncoded ? decodeURIComponent(nextPageAfterLoginEncoded) : '';

  const actionOnBeforePathChanges = useCallback(
    async () => {
      if (onBeforePathChange) {
        await onBeforePathChange();
      }
    }, [onBeforePathChange]
  );

  const finishLoginProcess = useCallback(
    async () => {
      await actionOnBeforePathChanges();

      if (formType !== LoginFormType.reauthenticate && formType !== LoginFormType.loginToAbsorb) {
        const redirectTo = nextPageAfterLoginDecoded || '/';
        if (pathname !== redirectTo) {
          navigate(redirectTo);
        }
      }
      if (formType === LoginFormType.loginToAbsorb) {
        const response = await ssoService.getSamlResponse();
        if (submitSAMLResponse) {
          submitSAMLResponse(response.samlResponse);
        }
      }
    }, [nextPageAfterLoginDecoded, pathname, navigate]
  );

  const sendVerificationCode = useCallback(
    async (multifactorResolverToUse: MultiFactorResolver) => {
      const recaptchaVerifier = getRecaptchaVerifier();

      const phoneInfoOptions = {
        multiFactorHint: multifactorResolverToUse.hints[0],
        session: multifactorResolverToUse.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );

      setVerificationId(newVerificationId);
    }, [setVerificationId, getRecaptchaVerifier]
  );

  const onResendCode = useCallback(
    async () => {
      if (!multifactorResolver) {
        // eslint-disable-next-line no-alert
        alert(t('misc.error_occurred_contact_administrator'));
        // eslint-disable-next-line no-console
        console.error('onResendCode called when multifactor resolver is still not defined');
        return;
      }

      await sendVerificationCode(multifactorResolver!);
    },
    [t, multifactorResolver, sendVerificationCode]
  );

  const goToNextStepEmailAndPassword = useCallback(
    async (data: LoginGoToNextStepFnArg) => {
      if (data.isMfaRequired) {
        try {
          await sendVerificationCode(data.multifactorResolver);
          setMultifactorResolver(data.multifactorResolver);
          setCurrentLoginStep(LoginSteps.MFACodeVerification);
        } catch (e: any) {
          const message = processMFAPhoneNumberRequestError(e, t);
          // eslint-disable-next-line no-alert
          alert(message);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        await finishLoginProcess();
      }
    }, [t, sendVerificationCode, setMultifactorResolver, setCurrentLoginStep, finishLoginProcess]
  );

  const onMFACodeGoStepBack = useCallback(
    () => {
      setCurrentLoginStep(LoginSteps.EmailAndPasswordForm);
    }, [setCurrentLoginStep]
  );

  const formDisplayed = useMemo(
    () => {
      switch (currentLoginStep) {
        case LoginSteps.EmailAndPasswordForm:
          return formType !== LoginFormType.reauthenticate ? (
            <EmailPasswordForm
              formType={formType}
              nextPageQPString={nextPageQPString}
              onBeforePathChange={actionOnBeforePathChanges}
              onEmailPasswordVerificationDone={goToNextStepEmailAndPassword}
            />
          ) : (
            <ReauthenticateByPassword
              onPasswordVerificationDone={goToNextStepEmailAndPassword}
            />
          );
        case LoginSteps.MFACodeVerification:
          return (
            <MFACodeVerification
              multifactorResolver={multifactorResolver!}
              verificationId={verificationId}
              goToPreviousPageStage={onMFACodeGoStepBack}
              onCodeVerifiedSuccessfully={finishLoginProcess}
              onResendCode={onResendCode}
            />
          );
        default:
          return null;
      }
    },
    [
      currentLoginStep, formType, nextPageQPString, multifactorResolver, verificationId,
      finishLoginProcess, actionOnBeforePathChanges, goToNextStepEmailAndPassword,
      onResendCode, onMFACodeGoStepBack,
    ],
  );

  return formDisplayed;
};

export default memo(LoginForm);
