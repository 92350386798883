import React, {
  useEffect, memo, useCallback,
} from 'react';

import { GeneralModalType } from '_types/modals.interface';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { showGeneralModalSelector } from 'redux/selectors/modal';
import { actionHandleShowGeneralModal } from 'redux/reducers/modal';

import BaseModal, { ModalAcceptButtonProps, ModalDeclineButtonProps } from '../BaseModal';

import './style.scss';


const GeneralModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const info: GeneralModalType | null = useAppSelector(showGeneralModalSelector);

  const onModalClose = useCallback(
    async () => {
      if (info?.onModalClose) {
        await info?.onModalClose();
      }

      dispatch(actionHandleShowGeneralModal(null));
    }, [dispatch, info?.onModalClose]
  );

  const onAcceptButtonClick = useCallback(
    async () => {
      if (info?.withAcceptButton && info?.onAcceptButtonClick) {
        await info?.onAcceptButtonClick();
      }

      dispatch(actionHandleShowGeneralModal(null));
    }, [dispatch, info?.withAcceptButton && info?.onAcceptButtonClick]
  );

  const onDeclineButtonClick = useCallback(
    async () => {
      if (info?.withDeclineButton && info?.onDeclineButtonClick) {
        await info?.onDeclineButtonClick();
      }

      dispatch(actionHandleShowGeneralModal(null));
    }, [dispatch, info?.withDeclineButton && info?.onDeclineButtonClick]
  );

  useEffect(() => {
    document.body.style.overflow = info ? 'hidden' : 'auto';
  }, [info]);

  if (!info) {
    return null;
  }

  const onAcceptOrDeclineActions: ModalAcceptButtonProps & ModalDeclineButtonProps = {
    withAcceptButton: info.withAcceptButton,
    withDeclineButton: info.withDeclineButton,
    onAcceptButtonClick,
    onDeclineButtonClick,
  };

  return (
    <BaseModal
      {...info}
      {...onAcceptOrDeclineActions}
      onModalClose={onModalClose}
    >
      {info.content}
    </BaseModal>
  );
};

export default memo(GeneralModal);
