import { BackendNotification } from '_types/notifications.interface';


export const filterAlreadyExistingNotifications = (
  notificationsToFilter: BackendNotification[],
  notificationsToExclude: BackendNotification[],
) => {
  const idsToExclude = new Set(notificationsToExclude.map(({ id }) => id));
  return notificationsToFilter.filter(
    ({ id }) => !idsToExclude.has(id),
  );
};

export const findPlaceWhereToPutUnreadNotification = (
  notificationToPut: BackendNotification,
  notificationsList: BackendNotification[],
): number => {
  const notificationToPutUnixTime = notificationToPut.createdAtUnix;

  const indexToPutNotification = notificationsList.findIndex(
    notification => notificationToPutUnixTime > notification.createdAtUnix,
  );

  return indexToPutNotification === -1 ? notificationsList.length : indexToPutNotification;
};

export const mergeReadNotifications = (
  notificationsToMergeIn: BackendNotification[],
  notificationsToMerge: BackendNotification[],
): BackendNotification[] => {
  // Delete from already existing notifications duplicate notifications, then merge.
  // Useful in case we've added seen notification by ourselves,
  // then it appears from request to get read notifications

  return [
    ...notificationsToMergeIn.filter(
      n => !notificationsToMerge.find(({ id }) => id === n.id),
    ),
    ...notificationsToMerge,
  ];
};
