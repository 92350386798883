import React, { memo, useEffect } from 'react';
import { ZendeskAPI } from 'react-zendesk';


import { HeaderTypes } from '_constants/headerConstants';

import Header from 'app/components/Header';
import PageHelmet from 'app/components/PageHelmet';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import shutdownPageUpdate from 'assets/img/shutdownPageUpdate.png';
import shutdownPageBackground1 from 'assets/img/shutdownPageBackground1.png';
import shutdownPageBackground2 from 'assets/img/shutdownPageBackground2.png';

import './style.scss';


const ShutdownPage: React.FC = () => {
  const { t } = useTenantTranslation();

  useEffect(
    () => {
      try {
        ZendeskAPI('webWidget', 'hide');
      } catch (error) { /* empty */ }
    }, []
  );

  return (
    <>
      <PageHelmet pageTitle={t('maintenance')} />

      <Header type={HeaderTypes.shutdown} />

      <div className="app_wrapper">
        <div id="shutdown" className="shutdown">
          <div className="shutdown_wrapper">
            <div className="content">
              <img src={shutdownPageUpdate} className="content__img" alt="update" />

              <h1 className="content__title">
                {t('misc.shutdown_page.title')}
              </h1>

              <span className="content__description">
                {t('misc.shutdown_page.description')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <img src={shutdownPageBackground1} className="background-img-1" alt="background-img-1" />
      <img src={shutdownPageBackground2} className="background-img-2" alt="background-img-2" />
    </>
  );
};
export default memo(ShutdownPage);
