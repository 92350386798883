import { TFunction } from 'i18next';


export enum MFACodeUsedInFormEnum {
  login_form = 'login_form',
  mfa_setup_form = 'mfa_setup_form',
}

export const processCodeVerificationRequestError = (
  e: any,
  t: TFunction,
  mfaCodeUsedInFormType: MFACodeUsedInFormEnum,
): string => {
  if (e?.code === 'auth/invalid-verification-code') {
    return t('auth.mfa_code_invalid');
  }

  if (e?.code === 'auth/code-expired') {
    return t(`auth.mfa_code_expired.${mfaCodeUsedInFormType}`);
  }

  return t('misc.error_occurred_contact_administrator');
};
