import React, {
  memo, useMemo
} from 'react';
import orderBy from 'lodash/orderBy';

import { getAvailableIndustriesList } from '_constants/onboardingConstants';

import { ProfileFieldErrorType } from '_types/profile.interface';

import CheckList from 'app/components/CheckList/CheckList';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';

import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';


type AdvisorIndustriesErrorsObject = (
  ProfileFieldErrorType
  | Partial<Record<string, ProfileFieldErrorType>>
);

interface AdvisorIndustriesSelectComponentProps {
  onChange: (value?: string[]) => void,
  errors?: AdvisorIndustriesErrorsObject;
  value?: string[];
  titleClassName?: string;
}

const AdvisorIndustriesSelectComponent: React.FC<AdvisorIndustriesSelectComponentProps> = ({
  onChange,
  errors,
  value,
  titleClassName,
}) => {
  const { t } = useTenantTranslation();
  const fieldId = useGoToFirstErrorId(['advisorData.industries']);

  const availableIndustriesList = useMemo(
    () => orderBy(getAvailableIndustriesList(t), 'name', 'asc'), [t],
  );

  return (
    <ProfileFieldWrapper
      title={t('onboarding.advisor_industry_expertice_title')}
      addAsteriskToTitle
      titleClassName={titleClassName}
      id={fieldId}
    >
      <CheckList
        multiple
        allowEmptyList={false}
        availableCheckValues={availableIndustriesList}
        initiallyChosen={value}
        onChange={onChange}
        errors={errors}
      />
    </ProfileFieldWrapper>
  );
};

export default memo(AdvisorIndustriesSelectComponent);
