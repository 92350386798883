import { RootState } from 'store/configureStore';


export const advisorListSelector = (state: RootState) => state.advisor.advisorList;
export const advisorDetailsSelector = (state: RootState) => state.advisor.advisorDetails;
export const isAdvisorDetailsLoadingSelector = (state: RootState) => state.advisor.isAdvisorDetailsLoading;
export const advisorSelector = (state: RootState) => state.advisor.currentAdvisor;

export const advisoryOrganizationsListSelector = (state: RootState) => state.advisor.advisoryOrganizationsList;

export const advisorFilterSettingsSelector = (state: RootState) => state.advisor.advisorFilterDetails;

export const advisorFilterEnabledSelector = (state: RootState) => state.advisor.advisorFilterDetails?.isEnabled;

export const isAdvisorFilterDetailsLoadingSelector = (state: RootState) => state.advisor.isAdvisorFilterDetailsLoading;
