import React, { ReactNode, memo } from 'react';

import { ProfileFieldErrorType } from '_types/profile.interface';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


export type ErrorsTextErrorTypeSupported = ProfileFieldErrorType | ReactNode | ReactNode[]

interface ErrorsTextTextLineProps {
  error?: ErrorsTextErrorTypeSupported;
}

const ErrorsTextTextLine: React.FC<ErrorsTextTextLineProps> = ({
  error,
}) => {
  const { t } = useTenantTranslation();

  const errorMessage = typeof error === 'string' ? t(error) : error;

  return (
    <p className="errors_text__bullet_list__text">{errorMessage}</p>
  );
};

export default memo(ErrorsTextTextLine);
