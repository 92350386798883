import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ShowProfileSuccessPopupType } from '_types/modals.interface';


interface PopupsState {
  showProfileSuccessPopup: ShowProfileSuccessPopupType | null;
}

const initialPopupsState: PopupsState = {
  showProfileSuccessPopup: null,
};

const popupsSlice = createSlice({
  name: 'popups',
  initialState: initialPopupsState,
  reducers: {
    resetState: () => initialPopupsState,
    actionHandleShowProfilePopup(state, action: PayloadAction<ShowProfileSuccessPopupType | null>) {
      state.showProfileSuccessPopup = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  actionHandleShowProfilePopup,
} = popupsSlice.actions;

export default popupsSlice;
