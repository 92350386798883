import { createContext } from 'react';

import { SocketContextInterface } from '_types/socket.interface';


const SocketContext = createContext<SocketContextInterface>({
  socket: null,
  saveMessageOnSocketNotConnected: () => {},
  setSocketData: () => {},
});

export default SocketContext;
