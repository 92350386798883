import React, {
  memo, useCallback, useMemo
} from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';


import { HeaderTabs } from '_constants/headerConstants';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { profileRoleSelector } from 'redux/selectors/profile';
import {
  tenantSettingsTenantFeatureFlagsSelector,
  // tenantSettingsTenantStylingSelector,
} from 'redux/selectors/tenant-settings';
import { actionHandleShowMenuModal } from 'redux/reducers/modal';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import { headerTabsConstantsFn } from './utils';

import './style.scss';


const HomePageTabs: React.FC = () => {
  const { t } = useTenantTranslation();
  const dispatch = useAppDispatch();

  const tenantFeatureFlags = useAppSelector(tenantSettingsTenantFeatureFlagsSelector);
  // const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const userRole = useAppSelector(profileRoleSelector);

  const headerTabsConstants = useMemo(
    () => headerTabsConstantsFn(userRole, tenantFeatureFlags, t),
    [userRole, tenantFeatureFlags, t]
  );

  const closeModal = useCallback(() => {
    dispatch(actionHandleShowMenuModal(false));
  }, [dispatch]);

  if (!headerTabsConstants?.length) {
    return null;
  }

  return (
    <div className="home_tabs">
      {headerTabsConstants?.map(
        (elem: HeaderTabs) => (
          <NavLink
            to={elem.path}
            className={({ isActive }) => clsx('home_tabs_item', { active: isActive })}
            key={elem.name}
            onClick={closeModal}
          >
            {/* <div className="home_tabs_item__icon">{elem.getIcon(tenantStyling?.shades08)}</div> */}
            <div className="home_tabs_item__text">{elem.name}</div>
          </NavLink>
        )
      )}
    </div>
  );
};

export default memo(HomePageTabs);
