import { Socket } from 'socket.io-client';

import { notificationSocketEventHandlers } from './eventHandlers/notifications';
import { MessagesToHandlersType } from './socketTypes';


const allEventHandlers: MessagesToHandlersType = {
  ...notificationSocketEventHandlers,
};

export const addSocketMessages = (socket: Socket, dispatch: Function) => {
  socket.on('connect', () => {
    // eslint-disable-next-line no-console
    console.debug('Socket connected');
  });

  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.debug('Socket disconnected');
  });

  Object.entries(allEventHandlers).forEach(([message, fn]) => {
    socket.on(message, (...args) => fn(dispatch, ...args));
  });
};

export const removeSocketMessages = (socket: Socket) => {
  Object.keys(allEventHandlers).forEach(message => {
    socket.off(message);
  });
  socket.off('connect');
  socket.off('disconnect');
};
