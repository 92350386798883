import React, { memo } from 'react';


interface BusinessIconProps {
  className?: string;
}
const BusinessIcon: React.FC<BusinessIconProps> = ({ className }) => (
  <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.932 25.21C43.3621 27.1998 40.9256 28.4786 38.2043 28.4786C35.4769 28.4786 33.0462 27.1998 31.4736 25.21C29.9024 27.1998 27.472 28.4786 24.744 28.4786C22.0229 28.4786 19.5862 27.1998 18.017 25.21C16.4492 27.1998 14.0171 28.4786 11.2906 28.4786C10.4936 28.4786 9.72136 28.3674 8.98438 28.1619V54.9356C8.98438 55.4879 9.43209 55.9356 9.98438 55.9356H52.9643C53.5166 55.9356 53.9643 55.4879 53.9643 54.9356V28.1619C53.227 28.3675 52.4548 28.4786 51.6583 28.4786C48.9308 28.4786 46.4998 27.1998 44.932 25.21ZM48.4376 50.5363C48.4376 50.8125 48.2138 51.0363 47.9376 51.0363H37.0981C36.822 51.0363 36.5981 50.8125 36.5981 50.5363V33.4701C36.5981 33.194 36.822 32.9701 37.0981 32.9701H47.9376C48.2138 32.9701 48.4376 33.194 48.4376 33.4701V50.5363Z"
      fill="#053B66"
    />
    <path
      d="M54.709 10.0113C54.666 9.77324 54.4588 9.6001 54.2169 9.6001H8.7314C8.48952 9.6001 8.28232 9.77325 8.23936 10.0113L6.40039 20.2004C6.48177 22.8268 8.6426 24.9376 11.2906 24.9376C13.8155 24.9376 15.9039 23.0103 16.1552 20.5484C16.1832 20.2737 16.4044 20.0492 16.6805 20.0492H19.3561C19.6322 20.0492 19.8534 20.2737 19.8814 20.5484C20.1324 23.0104 22.2187 24.9376 24.744 24.9376C27.2753 24.9376 29.3584 23.0103 29.609 20.5484C29.6369 20.2737 29.8581 20.0492 30.1342 20.0492H32.8147C33.0908 20.0492 33.312 20.2737 33.3399 20.5484C33.5904 23.0104 35.6732 24.9376 38.2043 24.9376C40.73 24.9376 42.8149 23.0103 43.0657 20.5484C43.0937 20.2737 43.3148 20.0492 43.591 20.0492H46.2678C46.5439 20.0492 46.7651 20.2737 46.7931 20.5484C47.0444 23.0104 49.1331 24.9376 51.6583 24.9376C54.3057 24.9376 56.4675 22.8268 56.5485 20.2004L54.709 10.0113Z"
      fill="#053B66"
    />
  </svg>
);

export default memo(BusinessIcon);
