/* eslint-disable react/jsx-closing-tag-location */
import React, {
  memo, useCallback, useMemo, useState
} from 'react';
import { TFunction } from 'i18next';
import { NumericFormat } from 'react-number-format';
import { Loan } from 'loanjs';
import { IoIosArrowDown, IoIosArrowUp, IoMdCheckmark } from 'react-icons/io';

import useTenantColors from 'utils/hooks/useTenantColors';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';

import Button from '../Button';

import getPaymentCalculatorCustomStyles from './customStyles';

import './style.scss';


interface Loaner {
  amount: number,
  capitalSum: number,
  sum: number,
  interestSum: number,
  installments: { capital: number, installment: number, interest: number, interestSum: number, remain: number }[]
}

interface CalculatorValues {
  principal: string;
  totalMonths: string;
  interestRate: string;
  paymentMethod: 'endOfPeriod' | 'startOfPeriod';
}

interface LoanPropType {
  styling?: {
    backgroundColor: string,
    color: string,
    fontFamily: string,
    fontSize: string,
    width: string,
    clearButton: {
      text: string,
      backgroundColor: string,
      color: string,
      border: string,

    },
    calculateButton: {
      text: string,
      backgroundColor: string,
      color: string,
      border: string,
    }

  }
}

const defaultCalculatorValues: CalculatorValues = {
  principal: '',
  totalMonths: '',
  interestRate: '',
  paymentMethod: 'endOfPeriod',
};

const getPaymentMethodName = (t: TFunction) => ({
  endOfPeriod: t('funding.loan_repayment_calculator.end_of_period'),
  startOfPeriod: t('funding.loan_repayment_calculator.start_of_period'),
});

const convertToNumber = (value: string): number => {
  const numericValue = value.replace(/\D/g, '');
  return parseFloat(numericValue);
};

const convertPercentStringToDecimal = (value: string): number => {
  const match = value.match(/(\d+)%/);
  if (match) {
    return parseFloat(match[0]);
  }
  throw new Error('Invalid value: no percent number found');
};

const hasEmptyValues = (calculatorValues: CalculatorValues) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in calculatorValues) {
    if (calculatorValues[key] === '') {
      return true;
    }
  }
  return false;
};

const PaymentCalculator: React.FC<LoanPropType> = () => {
  const { t } = useTenantTranslation();
  const { colorVariables } = useTenantColors();

  const [calculatorValues, setCalculatorValues] = useState<CalculatorValues>(defaultCalculatorValues);
  const [monthlyPayment, setMonthlyPayment] = useState<string>('');
  const [totalInterest, setTotalInterest] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<string>('');
  const [
    isPaymentMethodDropdownOpen,
    setIsPaymentMethodDropdownOpen,
  ] = useState<boolean>(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);

  const {
    clearBtnStyle,
    submitBtnStyle,
    dropdownContainerStyle,
  } = useMemo(
    () => getPaymentCalculatorCustomStyles(isPaymentMethodDropdownOpen, colorVariables),
    [isPaymentMethodDropdownOpen, colorVariables],
  );

  const paymentMethodNames = useMemo(
    () => getPaymentMethodName(t), [t],
  );

  const handleChange = useCallback((name: keyof CalculatorValues, value: any) => {
    let isSubmitButtonDisabled: boolean;

    setCalculatorValues(prev => {
      const newCalculatorValues = { ...prev, [name]: value };
      isSubmitButtonDisabled = hasEmptyValues(newCalculatorValues);
      return newCalculatorValues;
    });

    setIsSubmitButtonDisabled(isSubmitButtonDisabled!);
  }, [setCalculatorValues]);

  const calculate = useCallback(
    () => {
      // eslint-disable-next-line radix
      const principal = convertToNumber(calculatorValues.principal);
      const totalMonths = parseInt(calculatorValues.totalMonths, 10);
      const interestRate = convertPercentStringToDecimal(calculatorValues.interestRate);

      const loan = Loan(
        principal,
        totalMonths,
        interestRate,
        calculatorValues.paymentMethod === 'startOfPeriod' ? 'annuityDue' : 'annuity'
      );

      setMonthlyPayment(loan.installments[0].installment.toFixed(2));
      setTotalInterest(loan.interestSum.toFixed(2));
      setTotalAmount(loan.sum.toFixed(2));
    }, [calculatorValues]
  );

  const onClearBtnClick = useCallback(
    () => {
      setCalculatorValues(defaultCalculatorValues);
      setMonthlyPayment('');
      setTotalAmount('');
      setTotalInterest('');
    }, []
  );

  return (
    <div className="payment-calculator-main">
      <div className="payment-calculator-sub">
        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.loan_amount')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix="$"
            placeholder="$0.00"
            value={calculatorValues.principal}
            className="custom-input"
            onChange={(e: any) => handleChange('principal', e.target.value)}
          />
        </div>

        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.number_of_months')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            placeholder="0"
            value={calculatorValues.totalMonths}
            className="custom-input"
            onChange={(e: any) => handleChange('totalMonths', e.target.value)}
          />
        </div>

        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.annual_interest_rate')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            suffix="%"
            placeholder="0.0%"
            value={calculatorValues.interestRate}
            className="custom-input"
            onChange={(e: any) => handleChange('interestRate', e.target.value)}
          />
        </div>

        <div className="payment-calculator-values-drop">
          <span className="payment-calculator-values-label">
            {t('funding.loan_repayment_calculator.fields.payment_method')}
          </span>

          <a
            className="payment-calculator-payment-drop-div"
            onClick={() => setIsPaymentMethodDropdownOpen(!isPaymentMethodDropdownOpen)}
            style={dropdownContainerStyle}
          >
            <span className="payment-calculator-payment-drop-div-value">
              {paymentMethodNames[calculatorValues.paymentMethod]}
            </span>

            <span
              className={
                calculatorValues.paymentMethod === null
                  ? 'payment-calculator-payment-drop-ml-105'
                  : 'payment-calculator-payment-drop-ml-0'
              }
            >
              {isPaymentMethodDropdownOpen
                ? <IoIosArrowUp size={24} color={colorVariables?.icons.icon_dropdown} />
                : <IoIosArrowDown size={24} color={colorVariables?.icons.icon_dropdown} />}
            </span>

            {isPaymentMethodDropdownOpen && (
              <div className="payment-calculator-payment-dropdown">
                <div
                  onClick={() => handleChange('paymentMethod', 'endOfPeriod')}
                  className="payment-calculator-payment-dropdown-value"
                >
                  <span>{paymentMethodNames.endOfPeriod}</span>

                  {calculatorValues.paymentMethod === 'endOfPeriod' && (
                    <IoMdCheckmark size={24} color={colorVariables?.icons.icon_dropdown} />
                  )}
                </div>

                <div
                  onClick={() => handleChange('paymentMethod', 'startOfPeriod')}
                  className="payment-calculator-payment-dropdown-value"
                >
                  <span>{paymentMethodNames.startOfPeriod}</span>

                  {calculatorValues.paymentMethod === 'startOfPeriod' && (
                    <IoMdCheckmark size={24} color={colorVariables?.icons.icon_dropdown} />
                  )}
                </div>
              </div>
            )}
          </a>
        </div>

        <div className="payment-calculator-horiz-main">
          <div className="payment-calculator-horiz" />
        </div>
      </div>

      <div className="payment-calculator-sub">
        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.monthly_payment')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix="$"
            placeholder="$0.00"
            value={monthlyPayment}
            className="custom-input"
            readOnly
            onChange={(e: any) => setMonthlyPayment(e.target.value)}
          />
        </div>

        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.total_interest')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix="$"
            placeholder="$0.00"
            value={totalInterest}
            className="custom-input"
            readOnly
            onChange={(e: any) => setTotalInterest(e.target.value)}
          />
        </div>

        <div className="payment-calculator-values">
          <span>{t('funding.loan_repayment_calculator.fields.total_principal_and_interest')}</span>

          <NumericFormat
            displayType="input"
            inputMode="numeric"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix="$"
            placeholder="$0.00"
            value={totalAmount}
            className="custom-input"
            readOnly
            onChange={(e: any) => setTotalAmount(e.target.value)}
          />
        </div>
      </div>

      <div className="payment-calculator-buttons">
        <Button
          type="btn"
          btnLook="text"
          size="small"
          value={t('funding.loan_repayment_calculator.clear_btn')}
          onClick={onClearBtnClick}
          style={clearBtnStyle}
          className="clear_btn"
        />

        <Button
          type="btn"
          btnLook="text"
          size="medium"
          value={t('funding.loan_repayment_calculator.submit_btn')}
          onClick={calculate}
          style={submitBtnStyle}
          disabled={isSubmitButtonDisabled}
        />
      </div>
    </div>
  );
};

export default memo(PaymentCalculator);
