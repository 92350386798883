import React from 'react';

import PartyPopperIcon from 'app/components/Icons/PartyPopperIcon';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';


import './style.scss';


const UnreadNotificationsEmptyComponent: React.FC = () => {
  const { t } = useTenantTranslation();

  return (
    <div className="profile_notification__empty">
      <PartyPopperIcon className="profile_notification__empty__icon" />
      <p className="text">{t('notifications.nothing_new_for_you')}</p>
    </div>
  );
};

export default UnreadNotificationsEmptyComponent;
