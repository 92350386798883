import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TenantSettings } from '_types/tenant-settings.interface';

import { fetchGetTenantSettings } from 'redux/actions/tenant-settings';


interface TenantSettingsState {
  tenantSettings: TenantSettings | null;
  loader: boolean;
}

const tenantSettingsInitialState: TenantSettingsState = {
  tenantSettings: null,
  loader: true,
};


const tenantSettingsSlice = createSlice({
  name: 'tenant-settings',
  initialState: tenantSettingsInitialState,
  reducers: {
    resetState: () => tenantSettingsInitialState,
    changeTenantSettings(state, action: PayloadAction<TenantSettings>) {
      state.tenantSettings = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchGetTenantSettings.pending, state => {
      state.loader = true;
    });
    builder.addCase(fetchGetTenantSettings.fulfilled, (state, action) => {
      state.tenantSettings = action.payload;
      state.loader = false;
    });
    builder.addCase(fetchGetTenantSettings.rejected, (state, _action) => {
      state.loader = false;
    });
  },
});

export const { changeTenantSettings } = tenantSettingsSlice.actions;

export default tenantSettingsSlice;
