import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import './style.scss';


interface LinkTextProps {
  value: string;
  width?: string;
  error?: boolean;
  to?: string;
  disabled?: boolean;
  onClick?: Function;
}
const LinkText: React.FC<LinkTextProps> = ({
  to, value, width, error, onClick, disabled,
}) => (
  <div
    className="link_wrapper"
    onClick={() => {
      if (onClick && !disabled) {
        onClick();
      }
    }}
  >
    <Link className={clsx({ disabled })} to={to || ''}>{value}</Link>
  </div>
);

export default memo(LinkText);
