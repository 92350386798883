/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { Dispatch, combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from '../../utils/types/injector-typings';

import modalSlice from './modal';
import notificationSlice from './notification';
import onboardingSlice from './onboarding';
import profileSlice from './profile';
import tenantSettingsSlice from './tenant-settings';
import advisorSlice from './advisor';
import sessionsSlice from './sessions';
import fundingSlice from './funding';
import clientSlice from './client';
import popupsSlice from './popups';
import coursesSlice from './courses';
import advisoryOrgSlice from './advisory-org';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error

  return combineReducers({
    ...injectedReducers,
    onboarding: onboardingSlice.reducer,
    profile: profileSlice.reducer,
    tenantSettings: tenantSettingsSlice.reducer,
    modals: modalSlice.reducer,
    notification: notificationSlice.reducer,
    advisor: advisorSlice.reducer,
    advisoryOrg: advisoryOrgSlice.reducer,
    sessions: sessionsSlice.reducer,
    funding: fundingSlice.reducer,
    client: clientSlice.reducer,
    popups: popupsSlice.reducer,
    courses: coursesSlice.reducer,
  });
}

export const onUserDataClean = (dispatch: Dispatch) => {
  dispatch(onboardingSlice.actions.resetState());
  dispatch(profileSlice.actions.resetState());
  dispatch(modalSlice.actions.resetState());
  dispatch(notificationSlice.actions.resetState());
  dispatch(advisoryOrgSlice.actions.resetState());
  dispatch(advisorSlice.actions.resetState());
  dispatch(sessionsSlice.actions.resetState());
  dispatch(fundingSlice.actions.resetState());
  dispatch(clientSlice.actions.resetState());
  dispatch(popupsSlice.actions.resetState());
  dispatch(coursesSlice.actions.resetState());
};
