import { ZendeskAPI } from 'react-zendesk';

import type { PlatformLanguages } from '_types';

import { changeMomentLang } from 'utils/moment';
import {
  getLocalStorageValue,
  setLocalStorageValue,
  useValueFromLocalStorage,
} from 'utils/hooks/useValueFromLocalStorage';

import type { i18n } from 'i18next';


export const getCurrentPlatformLanguage = (): PlatformLanguages => {
  return getLocalStorageValue('i18nextLng') as PlatformLanguages;
};

export const useCurrentPlatformLanguage = (): PlatformLanguages => {
  return useValueFromLocalStorage('i18nextLng') as PlatformLanguages;
};

const saveCurrentPlatformLanguage = (lang: PlatformLanguages): void => {
  setLocalStorageValue('i18nextLng', lang);
};

export const applyPlatformLanguage = async (
  langToSet: PlatformLanguages,
  i18n: i18n,
  withSave: boolean = true,
): Promise<void> => {
  if (withSave) {
    saveCurrentPlatformLanguage(langToSet);
  }

  await i18n.changeLanguage(langToSet);
  changeMomentLang(langToSet);
  try {
    ZendeskAPI('webWidget', 'setLocale', langToSet);
  } catch (error) { /* empty */ }
};
