import React, { memo } from 'react';

import { useAppSelector } from 'store/configureStore';

import { tenantSettingsTenantStylingSelector } from 'redux/selectors/tenant-settings';

import LogoDarkIcon from '../Icons/LogoDarkIcon';

import './style.scss';


interface LogoProps {
  className?: string;
  onClick?: Function;
}
const Logo: React.FC<LogoProps> = ({ className, onClick }) => {
  const tenantStyling: any = useAppSelector(tenantSettingsTenantStylingSelector);

  return (
    <div className="logo_wrapper" onClick={() => onClick && onClick()}>
      {
        tenantStyling?.logo
          ? <img src={tenantStyling.logo} className={className} alt="logo" />
          : <LogoDarkIcon className={className} />
      }
    </div>
  );
};

export default memo(Logo);
