import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdvisorFilterSettings, PaginatedAdvisors, FullAdvisorData } from '_types/advisor.interface';

import { fetchAdvisorDetails, fetchAdvisorFilterSettings, fetchGetAdvisoryOrganizationsList } from 'redux/actions/advisor';


interface AdvisorState {
  advisorList: PaginatedAdvisors | null,
  advisorDetails: FullAdvisorData | null;
  isAdvisorDetailsLoading: boolean;
  currentAdvisor: FullAdvisorData | null,
  advisorFilterDetails: AdvisorFilterSettings;
  isLoading: boolean,
  isAdvisorFilterDetailsLoading: boolean,
  advisoryOrganizationsList: string[];
}

const advisorInitialState: AdvisorState = {
  advisorList: null,
  advisorDetails: null,
  isAdvisorDetailsLoading: true,
  currentAdvisor: null,
  isLoading: true,
  advisorFilterDetails: {} as AdvisorFilterSettings,
  isAdvisorFilterDetailsLoading: false,
  advisoryOrganizationsList: [],
};

const advisorSlice = createSlice({
  name: 'advisor',
  initialState: advisorInitialState,
  reducers: {
    resetState: () => advisorInitialState,
    actionHandleChangeAdvisorList(state, action: PayloadAction<PaginatedAdvisors | null>) {
      state.advisorList = action.payload;
    },
    actionSetAdvisorDetails(state, action: PayloadAction<FullAdvisorData | null>) {
      state.advisorDetails = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAdvisorDetails.pending, state => {
      state.isAdvisorDetailsLoading = true;
    });
    builder.addCase(fetchAdvisorDetails.fulfilled, (state, action) => {
      state.isAdvisorDetailsLoading = false;
      state.advisorDetails = action.payload;
    });
    builder.addCase(fetchAdvisorDetails.rejected, (state, _action) => {
      state.isAdvisorDetailsLoading = false;
    });
    // builder.addCase(fetchAdvisor.rejected, (state, _action) => {
    //   state.isLoading = false;
    // });
    builder.addCase(fetchAdvisorFilterSettings.pending, state => {
      state.isAdvisorFilterDetailsLoading = true;
    });
    builder.addCase(fetchAdvisorFilterSettings.fulfilled, (state, action) => {
      state.isAdvisorFilterDetailsLoading = false;
      state.advisorFilterDetails = action.payload;
    });
    builder.addCase(fetchAdvisorFilterSettings.rejected, (state, _action) => {
      state.isAdvisorFilterDetailsLoading = false;
    });
    builder.addCase(fetchGetAdvisoryOrganizationsList.fulfilled, (state, action) => {
      state.advisoryOrganizationsList = [...action.payload, 'N/A'];
    });
  },
});

export const {
  actionHandleChangeAdvisorList,
  actionSetAdvisorDetails,
} = advisorSlice.actions;

export default advisorSlice;
