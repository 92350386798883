import React, { memo } from 'react';


interface CheckMarkIconProps {
  className?: string;
  isThin?: boolean;
  color?: string;
}
const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ className, color = '#009999', isThin = false }) => {
  return isThin ? (
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M13 19.5L17 23.5L25 15.5M37 19.5C37 21.8638 36.5344 24.2044 35.6298 26.3883C34.7252 28.5722 33.3994 30.5565 31.7279 32.2279C30.0565 33.8994 28.0722 35.2252 25.8883 36.1298C23.7044 37.0344 21.3638 37.5 19 37.5C16.6362 37.5 14.2956 37.0344 12.1117 36.1298C9.92784 35.2252 7.94353 33.8994 6.27208 32.2279C4.60062 30.5565 3.27475 28.5722 2.37017 26.3883C1.46558 24.2044 1 21.8638 1 19.5C1 14.7261 2.89642 10.1477 6.27208 6.77208C9.64773 3.39642 14.2261 1.5 19 1.5C23.7739 1.5 28.3523 3.39642 31.7279 6.77208C35.1036 10.1477 37 14.7261 37 19.5Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ) : (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.6644 6.75259C15.0771 7.11951 15.1143 7.75158 14.7474 8.1644L9.4141 14.1644C9.2243 14.3779 8.9523 14.5 8.6667 14.5C8.381 14.5 8.109 14.3779 7.91926 14.1644L5.25259 11.1644C4.88567 10.7516 4.92285 10.1195 5.33564 9.7526C5.74842 9.3857 6.38049 9.4229 6.74741 9.8356L8.6667 11.9948L13.2526 6.83564C13.6195 6.42285 14.2516 6.38567 14.6644 6.75259Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(CheckMarkIcon);


