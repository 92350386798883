import React, { memo, useMemo } from 'react';

import { HeaderTypes } from '_constants/headerConstants';

import GeneralHeader from './GeneralHeader';
import OnboardingHeader from './OnboardingHeader';
import LogoOnlyHeader from './LogoOnlyHeader';
import ShutdownPageHeader from './ShutdownPageHeader';

import './style.scss';


type HeaderProps = {
  type: Exclude<HeaderTypes, HeaderTypes.onboarding>;
} | {
  type: HeaderTypes.onboarding;
  progressInPercents?: number;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { type } = props;

  const header = useMemo(
    () => {
      switch (type) {
        case HeaderTypes.onboarding:
          return <OnboardingHeader progressInPercents={props.progressInPercents} />;
        case HeaderTypes.auth:
          return <LogoOnlyHeader />;
        case HeaderTypes.general:
          return <GeneralHeader />;
        case HeaderTypes.shutdown:
          return <ShutdownPageHeader />;
        default:
          return <LogoOnlyHeader />;
      }
    }, [type]
  );

  return (
    <header className="header" id="global-header">{header}</header>
  );
};

export default memo(Header);
