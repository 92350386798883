import React, { ReactNode, memo } from 'react';
import clsx from 'clsx';
import { DefaultTFuncReturn } from 'i18next';

import CheckedIcon from '../Icons/CheckedIcon';
import SelectButtonHint from '../SelectButtonHint';

import './style.scss';


type SelectableBlockProps = {
  type: 'select' | 'checkbox';
  value: string;
  onChange: (value: string) => void;
  rightDownIcon?: JSX.Element;
  title?: DefaultTFuncReturn | ReactNode;
  subtitle: DefaultTFuncReturn | ReactNode;
  selected?: boolean;
  className?: string;
  hint?: ReactNode | DefaultTFuncReturn;
};

const SelectableBlock: React.FC<SelectableBlockProps> = ({
  type,
  value,
  onChange,
  rightDownIcon,
  title,
  subtitle,
  selected,
  className,
  hint,
}) => (
  <div
    className={clsx('selectable_block', className, { active: selected })}
    onClick={() => onChange(value)}
  >
    {title && <div className="selectable_block__title">{title}</div>}

    <div className="selectable_block__text">{subtitle}</div>

    {
      type === 'select' ? (
        <div className={clsx('selectable_block__circle', { active: selected })} />
      ) : type === 'checkbox' ? (
        <div className={clsx('selectable_block__box', { active: selected })}>{selected && <CheckedIcon />}</div>
      ) : null
    }

    {rightDownIcon && <div className="selectable_block__icon">{rightDownIcon}</div>}

    {hint && <SelectButtonHint hint={hint} />}
  </div>
);

export default memo(SelectableBlock);
