import React, { memo } from 'react';


interface CrossIconModalProps {
  className?: string;
}

const CrossIconModal: React.FC<CrossIconModalProps> = ({ className }) => (
  <svg className={className} width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5 8L8.5 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.5 8L24.5 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default memo(CrossIconModal);
