import { specifyAnotherField } from '_constants/onboardingConstants';

import { TranslateValueType } from '_types';


export const checkListFindEditableValue = (
  availableValuesSet: Set<string>,
  initiallyChosen: string[] | string | null | undefined,
  multiple: boolean,
) => {
  if (multiple) {
    return (initiallyChosen as string[])?.find(value => !availableValuesSet.has(value)) || '';
  }
  const chosenValue = initiallyChosen as string;
  return !availableValuesSet.has(chosenValue) ? chosenValue : '';
};

export const checkListGetDefaultIdToData = (
  availableCheckValues: TranslateValueType[],
  initiallyChosen: string[] | string | null | undefined,
  multiple: boolean,
) => {
  const availableCheckValuesSet = new Set(availableCheckValues.map(o => o.value));

  // if an editable value is in currently selected list it is going be different from the default editable value
  // so we should find it and if it is present replace the default value in the initialValueToSelected with current one
  const initialEditableValue = checkListFindEditableValue(
    availableCheckValuesSet,
    initiallyChosen,
    multiple,
  );

  return {
    initialEditableValue,
    initialValueToSelected: availableCheckValues.reduce(
      (obj, { value }) => {
        if (initialEditableValue && value === specifyAnotherField) {
          obj[initialEditableValue] = true;
          return obj;
        }

        obj[value] = !!initiallyChosen && (
          multiple
            ? initiallyChosen.includes(value)
            : initiallyChosen === value
        );
        return obj;
      },
      {}
    ),
  };
};
