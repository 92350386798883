
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAuth } from '@firebase/auth';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

if (!process.env.REACT_APP_FIREBASE_APP_CHECK_SITEKEY) {
  throw new Error('REACT_APP_FIREBASE_APP_CHECK_SITEKEY env var is not set!');
}

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_APP_CHECK_SITEKEY),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);

export const remoteConfig = getRemoteConfig();
remoteConfig.settings = {
  ...remoteConfig.settings,
  minimumFetchIntervalMillis: 0,
};
