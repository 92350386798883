import { Pagination, TenantFilterTypeEnum } from '.';

import type { PartialPlatformLanguagesMap } from './utils.interface';


export enum ProductLocationType {
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  REGIONAL = 'REGIONAL',
  NATIONAL = 'NATIONAL',
}

export enum LenderTypeEnum {
  BANK = 'Bank',
  COMMUNITY_DEVELOPMENT_FINANCIAL_INSTITUTION = 'Community Development Financial Institution',
  MINORITY_DEPOSITORY_INSTITUTION = 'Minority Depository Institution',
  CREDIT_UNION = 'Credit Union',
  ALTERNATIVE_LENDER = 'Alternative Lender',
  CROWDFUNDER = 'Crowdfunder',
  OTHERS = 'Other',
}

export enum LoanCommunitiesServedEnum {
  PEOPLECOLOR = 'Loan for People of Color',
  WOMEN = 'Loan for Women',
  LGBTQ = 'Loan for LGBTQ+',
  VETERAN = 'Loan for Veteran',
  LOWINCOME = 'Loan for Low Income',
  ALL = 'All',
}

export enum GrantCommunitiesServedEnum {
  PEOPLECOLOR = 'Grant for People of Color',
  WOMEN = 'Grant for Women',
  LGBTQ = 'Grant for LGBTQ+',
  VETERAN = 'Grant for Veteran',
  LOWINCOME = 'Grant for Low Income',
  ALL = 'All',
}

export interface LoanItemType {
  id: number;
  lenderName: string;
  loanName: PartialPlatformLanguagesMap<string>;
  tenantLocation: string[];
  minTerm: number;
  maxTerm: number;
  minSize: number;
  maxSize: number;
  minCreditScore: number | null;
  maxCreditScore: number | null;
  minAPR: number | null;
  maxAPR: number | null;
  mainColor: string;
  textColor: string;
  description: PartialPlatformLanguagesMap<string>;
  tenantDomain: string;
  fundingProviderUrl: string;
  phone: string;
  email: string;
  zipCodes: number[] | null;
  state: string[] | null;
  productLocation: ProductLocationType;
  minIntRate: number | null;
  maxIntRate: number | null;
  fees: string | null;
  minTimeFunding: number | null;
  maxTimeFunding: number | null;
  guarantor: PartialPlatformLanguagesMap<string>;
  collateral: PartialPlatformLanguagesMap<string>;
  deferment: PartialPlatformLanguagesMap<string>;
  capInjection: PartialPlatformLanguagesMap<string>;
  maxRevenue: number | null;
  minRevenue: number | null;
  targetAudience: PartialPlatformLanguagesMap<string>;
  keyDifferentiator: PartialPlatformLanguagesMap<string[]>;
  nonProfit: boolean;
  referralMethod: string;
  communityServed: LoanCommunitiesServedEnum[];
  minYearsInBusiness: number | null;
  fundingProviderIcon: string | null;
  productType: PartialPlatformLanguagesMap<string>;
  websiteURL: string | null;
}

export interface GrantItemType {
  id: number;
  grantName: PartialPlatformLanguagesMap<string>;
  grantorName: string;
  maxGrantSize: number | null;
  eligibilityBusinessType: PartialPlatformLanguagesMap<string>;
  eligibilityBusinessSize: number;
  eligibilityRevenueMin: number;
  eligibilityRevenueMax: number;
  eligibilityStartDate: Date;
  eligibilityGeography: PartialPlatformLanguagesMap<string[]>;
  useOfGrant: PartialPlatformLanguagesMap<string>;
  startApplication: Date;
  applicationDeadline: Date | null;
  fundingProviderUrl: string;
  tenantDomain: string;
  minGrantSize: number | null;
  minYearsInBusiness: number | null;
  minBusinessEmployees: number | null;
  maxBusinessEmployees: number | null;
  applicationFee: number | null;
  sector: PartialPlatformLanguagesMap<string>;
  eligibility: PartialPlatformLanguagesMap<string[]>;
  referralMethod: string;
  communityServed: GrantCommunitiesServedEnum[];
  mainColor: string;
  textColor: string;
  description: PartialPlatformLanguagesMap<string>;
  email: string;
  fundingProviderIcon: string | null;
  websiteURL: string | null;
  productLocation: string;
  state: string[] | null;
  zipCodes: number[] | null;
  tenantLocation: string[];

}

export interface LoansFilterRange {
  min: number | null;
  max: number | null;
}

type PaginatedFundingsResponseType<T = LoanItemType | GrantItemType> = {
  count: number;
  limit: number;
  offset: number;
  list: T[];
}

export type PaginatedLoansResponseType = PaginatedFundingsResponseType<LoanItemType>;
export type PaginatedGrantsResponseType = PaginatedFundingsResponseType<GrantItemType>;

export interface FundingsProductTypes {
  productTypes: PartialPlatformLanguagesMap<string[]>;
}

export interface LoansFilterType {
  productType?: string[];
  loanAmount?: LoansFilterRange;
  creditScore?: LoansFilterRange;
  yearsOfBusiness?: LoansFilterRange;
  communitiesServed?: LoanCommunitiesServedEnum[]
  lenderType?: string[]
  state?: string[];
}

export interface GrantFilterType {
  grantAmount?: LoansFilterRange;
  grantorType?: string;
  communitiesServed?: GrantCommunitiesServedEnum;
  state?: string[];
}

interface FundingsSearchType extends Pagination {
  shouldSkipFilters: boolean;
  shouldRandomize: boolean;
}

export interface LoansSearchType extends FundingsSearchType {
  loansFilter?: LoansFilterType;
}

export interface GrantsSearchType extends FundingsSearchType {
  grantsFilter?: GrantFilterType;
}

export type AvailableLoanFilterOptionType = 'loanAmount' | 'productType' | 'creditScore' | 'yearsOfBusiness' | 'communitiesServed' | 'lenderType' | 'state';


export type FilterOptionListType = {
  availableValues: Array<string | { value: any, name: string }>;
  anyText: string;
  description?: string;
  title: string;
  phoneText?: string;
  buttonText: string;
  isMultiple: boolean;
}
export type LoanFilterOptions = Record<AvailableLoanFilterOptionType, FilterOptionListType>;

export interface LoanFilterSettings {
  id: number;
  tenantId: number;
  filterType: TenantFilterTypeEnum.LOAN_FILTER;
  filterSetting: LoanFilterOptions;
  isEnabled: boolean;
  filterText: string;
}

export type AvailableGrantFilterOptionType = 'grantAmount' | 'grantorType' | 'communitiesServed' | 'state';


export type GrantFilterOptions = Record<AvailableGrantFilterOptionType, FilterOptionListType>;

export interface GrantFilterSettings {
  id: number;
  tenantId: number;
  filterType: TenantFilterTypeEnum.GRANT_FILTER;
  filterSetting: GrantFilterOptions;
  isEnabled: boolean;
  filterText: string;
}
