import React, {
  memo, useCallback, useMemo
} from 'react';


import { boroughOnboardingListFn, getShouldShowNYCBorough } from '_constants/onboardingConstants';

import { ProfileFieldErrorType } from '_types/profile.interface';

import DropDownList from 'app/components/DropDownList/DropDownList';


import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import useGoToFirstErrorId from 'utils/contexts/ProfileFieldIdsContext/useGoToFirstErrorId';

import ProfileFieldWrapper from '../../common/ProfileFieldWrapper';


export type NYCBoroughComponentValue = {
  NYCBorough?: string | null;
  businessState?: string;
}

type SboNYCBoroughErrorsObject = Partial<Record<
  keyof Omit<NYCBoroughComponentValue, 'businessState'>, ProfileFieldErrorType
>>;

interface SboNYCBoroughSelectComponentProps {
  onChange: (value: NYCBoroughComponentValue) => void;
  errors?: SboNYCBoroughErrorsObject;
  value?: NYCBoroughComponentValue;
}

const SboNYCBoroughSelectComponent: React.FC<SboNYCBoroughSelectComponentProps> = ({
  onChange,
  errors,
  value,
}) => {
  const { t } = useTenantTranslation();
  const fieldId = useGoToFirstErrorId(['sboData.NYCBorough']);

  const availableNYCBoroughsList = useMemo(() => boroughOnboardingListFn(t), [t]);

  const onChangeNYCBorough = useCallback(
    (value: string) => onChange({ NYCBorough: value }), [onChange],
  );

  const shouldShowNYCBorough = useMemo(
    () => getShouldShowNYCBorough({ businessState: value?.businessState }),
    [value?.businessState]
  );

  if (!shouldShowNYCBorough) {
    return null;
  }

  return (
    <ProfileFieldWrapper
      title={t('onboarding.nyc_borough_title')}
      addAsteriskToTitle
      subTitle=""
      id={fieldId}
    >
      <DropDownList
        placeholder={t('misc.please_select_one')}
        value={value?.NYCBorough || ''}
        onChange={onChangeNYCBorough}
        errors={errors?.NYCBorough}
        availableValuesList={availableNYCBoroughsList}
        isArrow
      />
    </ProfileFieldWrapper>
  );
};

export default memo(SboNYCBoroughSelectComponent);
