import React, {
  ReactNode, useCallback, useMemo, useState
} from 'react';

import type { DottedProfileFieldIdsPath, ProfileFieldIdsContextInterface, ProfileFieldIdsType } from '_types/profile.interface';

import ProfileFieldIdsContext from '.';


interface ProfileFieldIdsContextProviderInterface {
  children: ReactNode;
}

const ProfileFieldIdsContextProvider: React.FC<
  ProfileFieldIdsContextProviderInterface
> = ({ children }) => {
  const [profileFieldIds, setProfileFieldIds] = useState<ProfileFieldIdsType>({});

  const addProfileFieldId = useCallback(
    (path: DottedProfileFieldIdsPath, idToAdd: string) => {
      setProfileFieldIds((prevRefs) => ({
        ...prevRefs,
        [path]: idToAdd,
      }));
    }, [setProfileFieldIds]
  );

  const clearProfileFieldIds = useCallback(() => {
    setProfileFieldIds({});
  }, [setProfileFieldIds]);

  const contextData: ProfileFieldIdsContextInterface = useMemo(
    () => ({
      profileFieldIds,
      addProfileFieldId,
      clearProfileFieldIds,
    }), [profileFieldIds, addProfileFieldId, clearProfileFieldIds]
  );

  return (
    <ProfileFieldIdsContext.Provider value={contextData}>
      {children}
    </ProfileFieldIdsContext.Provider>
  );
};

export default React.memo(ProfileFieldIdsContextProvider);
