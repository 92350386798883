import React, { memo } from 'react';

import GoogleTagManagerScript from './GoogleTagManagerScript';
import ZendeskScript from './ZendeskScript';


const ExternalScriptsImports: React.FC = () => (
  <>
    <GoogleTagManagerScript />
    <ZendeskScript />
  </>
);

export default memo(ExternalScriptsImports);
