import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';


import { defaultTenantName } from '_constants/settingsConstants';

import { LoginFormType } from '_types/modals.interface';


import Button from 'app/components/Button';
import ArrowRightCircleIcon from 'app/components/Icons/ArrowRightCircleIcon';
import Input from 'app/components/InputComponents/Input';
import LinkText from 'app/components/LinkText/LinkText';
import ErrorsText from 'app/components/ErrorsText';

import { useAppSelector } from 'store/configureStore';

import { profileErrorSelector } from 'redux/selectors/profile';
import { tenantSettingsTenantNameSelector } from 'redux/selectors/tenant-settings';

import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { auth } from 'utils/firebase/firebase';
import { getMultiFactorResolver, signInWithEmailAndPassword } from 'utils/firebase/signin';

import { processNonMFALoginError } from '../utils';

import '../style.scss';

import type { LoginGoToNextStepFnArg } from '../types';


type LoginData = {
  email: string;
  password: string;
}

export interface EmailPasswordFormProps {
  nextPageQPString: string;
  formType: LoginFormType;
  onBeforePathChange: () => void;
  onEmailPasswordVerificationDone: (arg: LoginGoToNextStepFnArg) => Promise<void>;
}

const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  nextPageQPString,
  formType,
  onBeforePathChange,
  onEmailPasswordVerificationDone,
}) => {
  const { t } = useTenantTranslation();

  const errorLoginRequest = useAppSelector(profileErrorSelector);

  const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
  const [loginError, setLoginError] = useState<ReactNode>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tenantName = useAppSelector(tenantSettingsTenantNameSelector) || defaultTenantName;

  const onEmailChange = useCallback(
    (value: string | '') => {
      setLoginData(prevState => ({ ...prevState, email: value }));
      setLoginError('');
    }, [setLoginData, setLoginError]
  );

  const onPasswordChange = useCallback(
    (value: string | '') => {
      setLoginData(prevState => ({ ...prevState, password: value }));
      setLoginError('');
    }, [setLoginData, setLoginError]
  );

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();

      setIsLoading(true);

      try {
        await signInWithEmailAndPassword(auth, loginData.email, loginData.password);

        await onEmailPasswordVerificationDone({ isMfaRequired: false });
      } catch (error: any) {
        if (error.code === 'auth/multi-factor-auth-required') {
          // The user is a multi-factor user. Second factor challenge is required.
          const resolver = getMultiFactorResolver(auth, error);
          await onEmailPasswordVerificationDone({
            isMfaRequired: true,
            multifactorResolver: resolver,
          });
        } else {
          const errorMessage = processNonMFALoginError(t, error);
          setLoginError(errorMessage);
        }
      }
      setIsLoading(false);
    }, [t, loginData, setLoginError]
  );

  const areAllFieldsValid = useMemo(
    () => !!loginData.email && !!loginData.password,
    [loginData.email, loginData.password]
  );

  useEffect(() => {
    if (errorLoginRequest?.response?.status === 422) {
      setLoginError('auth.need_login_continue');
    }
  }, [errorLoginRequest]);

  const pageTitle = useMemo(
    () => {
      switch (formType) {
        case LoginFormType.loginToContinue:
          return <p>{t('auth.need_login_continue')}</p>;
        case LoginFormType.loginToAbsorb:
          return (
            <>
              <p>{t('auth.welcome_to_next_street_scale')}</p>
              <p>{t('auth.account_login')}</p>
            </>
          );
        case LoginFormType.welcomeBack:
          return (
            <>
              <p>{t('auth.welcome_back')}</p>
              <p>{t('auth.login_access_account')}</p>
            </>
          );
        case LoginFormType.login:
        default:
          return (
            <>
              <p>{t('misc.welcome_login_form', { tenantName })} !</p>
              <p>{t('auth.account_log_in')}</p>
            </>
          );
      }
    }, [formType, t]
  );

  return (
    <form className="login_page email_password_form" onSubmit={handleLogin}>
      <div className="title">
        {pageTitle}
      </div>

      <div className="entry">
        <div className="entry_item">
          <p className="title">{t('misc.email')}</p>

          <Input
            type="email"
            placeholder="john@example.com"
            value={loginData.email || ''}
            onChange={onEmailChange}
            isDisplayErrorOutline={!!loginError}
          />
        </div>

        <div className="entry_item">
          <p className="title">{t('misc.password')}</p>

          <Input
            type="password"
            placeholder={`${t('misc.password')}...`}
            value={loginData.password || ''}
            onChange={onPasswordChange}
            isDisplayErrorOutline={!!loginError}
          />
        </div>

        <ErrorsText
          errorsListOrErrorText={loginError}
          className="code-input-error"
        />
      </div>

      <div className="login_page__btn">
        <LinkText
          value={t('auth.forgot_password')}
          onClick={onBeforePathChange}
          to={`/auth/reset-password${nextPageQPString}`}
        />

        <Button
          type="btn"
          btnType="submit"
          rightIcon={<ArrowRightCircleIcon />}
          btnLook="filled"
          to="/auth"
          value={t('auth.login')}
          disabled={!areAllFieldsValid}
          size="large"
          isLoading={isLoading}
        />

        <div className="auth_text">
          <LinkText
            value={t('auth.dont_account_yet')}
            onClick={onBeforePathChange}
            to={`/auth/sbo${nextPageQPString}`}
          />
        </div>
      </div>
    </form>
  );
};

export default memo(EmailPasswordForm);
