import React, { memo, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PlatformLanguages } from '_types';

import Loader from 'app/components/Loader';

import { useAppDispatch, useAppSelector } from 'store/configureStore';

import { fetchGetTenantSettings } from 'redux/actions/tenant-settings';
import { fetchGetOnboardingQuestion } from 'redux/actions/onboarding';
import {
  tenantSettingsTenantStylingSelector,
  tenantSettingsTenantNameSelector,
  tenantSettingsLoaderSelector,
  tenantSettingsLanguagesAvailableSelector,
  tenantSettingsPresentSelector,
} from 'redux/selectors/tenant-settings';

import { changeColorSettings } from 'utils/colorSettings';
import { changeFontSettings } from 'utils/fontSettings';
import useTenantTranslation from 'utils/hooks/useTenantTranslation';
import { applyPlatformLanguage } from 'utils/translations';


const TenantSettingsLoaderWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTenantTranslation();

  const isTenantSettingsPresent = useAppSelector(tenantSettingsPresentSelector);
  const tenantStyling = useAppSelector(tenantSettingsTenantStylingSelector);
  const tenantName = useAppSelector(tenantSettingsTenantNameSelector);
  const settingsLoader = useAppSelector(tenantSettingsLoaderSelector);
  const languagesAvailable = useAppSelector(tenantSettingsLanguagesAvailableSelector);

  useEffect(() => {
    dispatch(fetchGetTenantSettings());
    dispatch(fetchGetOnboardingQuestion());
  }, [dispatch]);

  useEffect(() => {
    if (tenantStyling) {
      changeColorSettings(tenantStyling);
      changeFontSettings(tenantStyling);
    }
  }, [tenantStyling]);

  useEffect(() => {
    if (!isTenantSettingsPresent) {
      return;
    }

    if (languagesAvailable?.length) {
      const isCurrentLanguageAllowed = languagesAvailable.includes(i18n.language as PlatformLanguages);

      if (!isCurrentLanguageAllowed) {
        applyPlatformLanguage(languagesAvailable[0], i18n);
      }
    } else if (i18n.language !== PlatformLanguages.ENGLISH) {
      applyPlatformLanguage(PlatformLanguages.ENGLISH, i18n);
    }
  }, [isTenantSettingsPresent, languagesAvailable]);

  if (settingsLoader) {
    return <Loader withText={false} />;
  }

  return (
    <>
      <Helmet defaultTitle="" titleTemplate={`%s | ${tenantName}`} />

      <Outlet />
    </>
  );
};

export default memo(TenantSettingsLoaderWrapper);
