import { array, object } from 'yup';


// This schema is used MyProfile-page-widely to transform values on profile update
// request to the backend and is concatenated with a page related validation schema.
// It is used for all roles, so make sure to set .nullable() where required to not
// block other users with other roles from sending profile update requests
export const transformValuesProfileValidationSchema = object({
  advisoryOrgData: object({
    advisorInvitations: array().nullable().transform(advisors => advisors.filter(elem => !elem?.id)),
  }).nullable(),
});
